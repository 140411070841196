const queryString = window.location.search;
//var _REQUEST = new URLSearchParams(queryString);
var OPTIONS_COLOR = {
    Green: "Green",
    Orange: "Orange",
    Purple: "Purple",
    Blue: "Blue",
  };
var _DEFAULT_VALUES = {
    reads: 100,
    scale: 'realtime',
    historical: 'Green',
    layout: 'show_legend',
    compare: 0,
    pc_value_select: 'gl',
    pc_converted_select: 'gl',
    pc_currency: 'USD',
    kwh_currency: 'USD',
    meter:{
        deviceType: 'meter',
        total_field: 'kwh_tot',
        bar_graph_field: 'kwh_tot',
        compare: 0,        
    },
    iostack: {
        deviceType: 'iostack',
        io_total_field: 'pulse_cnt_1',
        io_bar_graph_field: 'pulse_cnt_1',
        analog_list: 'custom',
        analog_min: 0,
        analog_max: 4096,

    }
}

var _REQUEST
var chart;
var time_diff = 0;
var baseURL = 'https://api.ekmpush.com/';
var summaryURL = 'https://summary.ekmpush.com/summary/api/v2/';
//var summaryURL = 'http://10.100.2.13:5022/summary/api/v2/';
var tableFields = -1;
var headerArray = -1;
var graphFields = -1;
var lineGraphFields = -1;
var barGraphField = -1;
var layout = -1;
var isOnLoad = true;
var graphFieldsForURL = -1;
var legendFields = -1;
var graphFieldsForSummaryURL = -1;
var isSummary = 0;
var _ALL_SUMMARY_FIELDS;
var totalMinMax = {}
var tmpValue = 0
var actualWireSelected

var _V3_METER_DEFAULT_FIELDS = ['kwh_tot','kwh_tariff_1','kwh_tariff_2','kwh_tariff_3','kwh_tariff_4','rev_kwh_tot','rev_kwh_tariff_1','rev_kwh_tariff_2','rev_kwh_tariff_3','rev_kwh_tariff_4','fwd_kwh_tot', 'fwd_kwh_tariff_1', 'fwd_kwh_tariff_2', 'fwd_kwh_tariff_3', 'fwd_kwh_tariff_4', 'net_kwh_tot', 'net_kwh_tariff_1', 'net_kwh_tariff_2', 'net_kwh_tariff_3', 'net_kwh_tariff_4','rms_volts_ln_1','rms_volts_ln_2','rms_volts_ln_3','amps_ln_1','amps_ln_2','amps_ln_3','rms_watts_ln_1','rms_watts_ln_2','rms_watts_ln_3','rms_watts_tot','power_factor_ln_1','power_factor_ln_2','power_factor_ln_3','rms_watts_max_demand','ct_ratio'];

var _V4_GRAPH_FIELDS = ['pc_raw_1', 'pc_raw_2', 'pc_raw_3', 'pulse_cnt_1','pulse_cnt_2','pulse_cnt_3','converted_pulse_cnt_1','converted_pulse_cnt_2','converted_pulse_cnt_3'];

var ALL_DEVICES = {
    "meter": [],
    "iostack": [],
}

var scale;
var compare=0;
var isEnableAreaGraph =0;
var isNetKWHField=0;
var hide_lines = [];
var defaultTotalField;
var defaultFields;

var _ALL_FIELDS = {
    'kwh_tot': 'Total kWh',
    'kwh_tariff_1': 'kWh Tariff 1',
    'kwh_tariff_2': 'kWh Tariff 2',
    'kwh_tariff_3': 'kWh Tariff 3',
    'kwh_tariff_4': 'kWh Tariff 4',
    'rev_kwh_tot': 'Reverse kWh',
    'rev_kwh_tariff_1': 'Reverse kWh Tariff 1',
    'rev_kwh_tariff_2': 'Reverse kWh Tariff 2',
    'rev_kwh_tariff_3': 'Reverse kWh Tariff 3',
    'rev_kwh_tariff_4': 'Reverse kWh Tariff 4',
    'fwd_kwh_tot': 'Forward kWh',
    'fwd_kwh_tariff_1': 'Forward kWh Tariff 1',
    'fwd_kwh_tariff_2': 'Forward kWh Tariff 2',
    'fwd_kwh_tariff_3': 'Forward kWh Tariff 3',
    'fwd_kwh_tariff_4': 'Forward kWh Tariff 4',
    'net_kwh_tot': 'Net kWh',
    'net_kwh_tariff_1': 'Net kWh Tariff 1',
    'net_kwh_tariff_2': 'Net kWh Tariff 2',
    'net_kwh_tariff_3': 'Net kWh Tariff 3',
    'net_kwh_tariff_4': 'Net kWh Tariff 4',
    'rms_volts_ln_1': 'Volts Line 1',
    'rms_volts_ln_2': 'Volts Line 2',
    'rms_volts_ln_3': 'Volts Line 3',
    'amps_ln_1': 'Amps Line 1',
    'amps_ln_2': 'Amps Line 2',
    'amps_ln_3': 'Amps Line 3',
    'rms_watts_ln_1': 'Watts Line 1',
    'rms_watts_ln_2': 'Watts Line 2',
    'rms_watts_ln_3': 'Watts Line 3',
    'rms_watts_tot': 'Total RMS Watts',
    'power_factor_ln_1': 'Power Factor Line 1',
    'power_factor_ln_2': 'Power Factor Line 2',
    'power_factor_ln_3': 'Power Factor Line 3',
    'rms_watts_max_demand': 'RMS Watts Max Demand',
    'max_demand_period': 'Max Demand Period',
    'ct_ratio': 'CT Ratio',
    'pc_raw_1': 'Pulse Count 1',
    'pc_raw_2': 'Pulse Count 2',
    'pc_raw_3': 'Pulse Count 3',
    'pulse_cnt_1': 'PC 1 Value',
    'pulse_cnt_2': 'PC 2 Value',
    'pulse_cnt_3': 'PC 3 Value',
    'converted_pulse_cnt_1': 'PC 1 Value Converted',
    'converted_pulse_cnt_2': 'PC 2 Value Converted',
    'converted_pulse_cnt_3': 'PC 3 Value Converted',
    'pulse_ratio_1': 'Pulse Ratio 1',
    'pulse_ratio_2': 'Pulse Ratio 2',
    'pulse_ratio_3': 'Pulse Ratio 3',
    'state_inputs_1': 'Pulse Input State 1',
    'state_inputs_2': 'Pulse Input State 2',
    'state_inputs_3': 'Pulse Input State 3',
    'state_out_1': 'Control Output State 1',
    'state_out_2': 'Control Output State 2',
    'reactive_energy_tot': 'Total Reactive Energy',
    'kwh_rst': 'kWh Rst',
    'rev_kwh_rst': 'Rev kWh Rst',
    'reactive_pwr_ln_1': 'Reactive Power Line 1',
    'reactive_pwr_ln_2': 'Reactive Power Line 2',
    'reactive_pwr_ln_3': 'Reactive Power Line 3',
    'reactive_pwr_tot': 'Total Reactive Power',
    'kwh_scale': 'kWh Scale',
    'line_freq': 'Line Freq',
    'state_watts_dir': 'State Watts Dir',
    'kwh_ln_1': 'kWh Line 1',
    'kwh_ln_2': 'kWh Line 2',
    'kwh_ln_3': 'kWh Line 3',
    'rev_kwh_ln_1': 'Rev kWh Line 1',
    'rev_kwh_ln_2': 'Rev kWh Line 2',
    'rev_kwh_ln_3': 'Rev kWh Line 3',
    'max_demand_rst': 'Max Demand Rst',
    'cf_ratio': 'CF Ratio',
    'meter_status_code': 'Meter Status Code',
    'net_calc_watts_ln_1': 'Net Watts Line 1',
    'net_calc_watts_ln_2': 'Net Watts Line 2',
    'net_calc_watts_ln_3': 'Net Watts Line 3',
    'net_calc_watts_tot': 'Net Watts Total'
}

var _ALL_FIELDS_IOSTACK = {
    'analog_in_1': 'Analog Input 1',
    'analog_in_2': 'Analog Input 2',
    'analog_in_3': 'Analog Input 3',
    'analog_in_4': 'Analog Input 4',
    'converted_analog_in_1': 'Converted AI 1',
    'converted_analog_in_2': 'Converted AI 2',
    'converted_analog_in_3': 'Converted AI 3',
    'converted_analog_in_4': 'Converted AI 4',
    'state_inputs_1': 'Pulse Input State 1',
    'state_inputs_2': 'Pulse Input State 2',
    'state_inputs_3': 'Pulse Input State 3',
    'state_inputs_4': 'Pulse Input State 4',
    'state_out_1': 'Control Output State 1',
    'state_out_2': 'Control Output State 2',
    'state_out_3': 'Control Output State 3',
    'state_out_4': 'Control Output State 4',
    'pulse_cnt_1': 'Pulse Count 1',
    'pulse_cnt_2': 'Pulse Count 2',
    'pulse_cnt_3': 'Pulse Count 3',
    'pulse_cnt_4': 'Pulse Count 4',
    'converted_io_pulse_cnt_1': 'PC 1 Converted',
    'converted_io_pulse_cnt_2': 'PC 2 Converted',
    'converted_io_pulse_cnt_3': 'PC 3 Converted',
    'converted_io_pulse_cnt_4': 'PC 4 Converted',
    'pulse_cnt_rst_1': 'Resettable Pulse Count 1',
    'pulse_cnt_rst_2': 'Resettable Pulse Count 2',
    'pulse_cnt_rst_3': 'Resettable Pulse Count 3',
    'pulse_cnt_rst_4': 'Resettable Pulse Count 4',
    'pulse_hold_ms_1': 'Pulse Hold (ms) 1',
    'pulse_hold_ms_2': 'Pulse Hold (ms) 2',
    'pulse_hold_ms_3': 'Pulse Hold (ms) 3',
    'pulse_hold_ms_4': 'Pulse Hold (ms) 4',
    'pulse_hi_prev_ms_1': 'Pulse High Previous (ms) 1',
    'pulse_hi_prev_ms_2': 'Pulse High Previous (ms) 2',
    'pulse_hi_prev_ms_3': 'Pulse High Previous (ms) 3',
    'pulse_hi_prev_ms_4': 'Pulse High Previous (ms) 4',
    'pulse_lo_prev_ms_1': 'Pulse Low Previous (ms) 1',
    'pulse_lo_prev_ms_2': 'Pulse Low Previous (ms) 2',
    'pulse_lo_prev_ms_3': 'Pulse Low Previous (ms) 3',
    'pulse_lo_prev_ms_4': 'Pulse Low Previous (ms) 4',
    'pulse_hi_total_sec_1': 'Pulse High Total (sec) 1',
    'pulse_hi_total_sec_2': 'Pulse High Total (sec) 2',
    'pulse_hi_total_sec_3': 'Pulse High Total (sec) 3',
    'pulse_hi_total_sec_4': 'Pulse High Total (sec) 4',
    'pulse_lo_total_sec_1': 'Pulse Low Total (sec) 1',
    'pulse_lo_total_sec_2': 'Pulse Low Total (sec) 2',
    'pulse_lo_total_sec_3': 'Pulse Low Total (sec) 3',
    'pulse_lo_total_sec_4': 'Pulse Low Total (sec) 4',
    'ow_1_1_degc': '1-Wire 1_1 Degree C',
    'ow_1_2_degc': '1-Wire 1_2 Degree C',
    'ow_1_3_degc': '1-Wire 1_3 Degree C',
    'ow_1_4_degc': '1-Wire 1_4 Degree C',
    'ow_2_1_degc': '1-Wire 2_1 Degree C',
    'ow_2_2_degc': '1-Wire 2_2 Degree C',
    'ow_2_3_degc': '1-Wire 2_3 Degree C',
    'ow_2_4_degc': '1-Wire 2_4 Degree C',
    'ow_3_1_degc': '1-Wire 3_1 Degree C',
    'ow_3_2_degc': '1-Wire 3_2 Degree C',
    'ow_3_3_degc': '1-Wire 3_3 Degree C',
    'ow_3_4_degc': '1-Wire 3_4 Degree C',
    'ow_4_1_degc': '1-Wire 4_1 Degree C',
    'ow_4_2_degc': '1-Wire 4_2 Degree C',
    'ow_4_3_degc': '1-Wire 4_3 Degree C',
    'ow_4_4_degc': '1-Wire 4_4 Degree C',
    'ow_1_1_humidity': '1-Wire 1_1 Humidity',
    'ow_1_2_humidity': '1-Wire 1_2 Humidity',
    'ow_1_3_humidity': '1-Wire 1_3 Humidity',
    'ow_1_4_humidity': '1-Wire 1_4 Humidity',
    'ow_2_1_humidity': '1-Wire 2_1 Humidity',
    'ow_2_2_humidity': '1-Wire 2_2 Humidity',
    'ow_2_3_humidity': '1-Wire 2_3 Humidity',
    'ow_2_4_humidity': '1-Wire 2_4 Humidity',
    'ow_3_1_humidity': '1-Wire 2_1 Humidity',
    'ow_3_2_humidity': '1-Wire 3_2 Humidity',
    'ow_3_3_humidity': '1-Wire 3_3 Humidity',
    'ow_3_4_humidity': '1-Wire 3_4 Humidity',
    'ow_4_1_humidity': '1-Wire 4_1 Humidity',
    'ow_4_2_humidity': '1-Wire 4_2 Humidity',
    'ow_4_3_humidity': '1-Wire 4_3 Humidity',
    'ow_4_4_humidity': '1-Wire 4_4 Humidity',
    'ow_1_1_lux': '1-Wire 1_1 Lux',
    'ow_1_2_lux': '1-Wire 1_2 Lux',
    'ow_1_3_lux': '1-Wire 1_3 Lux',
    'ow_1_4_lux': '1-Wire 1_4 Lux',
    'ow_2_1_lux': '1-Wire 2_1 Lux',
    'ow_2_2_lux': '1-Wire 2_2 Lux',
    'ow_2_3_lux': '1-Wire 2_3 Lux',
    'ow_2_4_lux': '1-Wire 2_4 Lux',
    'ow_3_1_lux': '1-Wire 3_1 Lux',
    'ow_3_2_lux': '1-Wire 3_2 Lux',
    'ow_3_3_lux': '1-Wire 3_3 Lux',
    'ow_3_4_lux': '1-Wire 3_4 Lux',
    'ow_4_1_lux': '1-Wire 4_1 Lux',
    'ow_4_2_lux': '1-Wire 4_2 Lux',
    'ow_4_3_lux': '1-Wire 4_3 Lux',
    'ow_4_4_lux': '1-Wire 4_4 Lux',
    'converted_ow_1_1_degc': 'Converted 1-Wire 1_1 Degree C',
    'converted_ow_1_2_degc': 'Converted 1-Wire 1_2 Degree C',
    'converted_ow_1_3_degc': 'Converted 1-Wire 1_3 Degree C',
    'converted_ow_1_4_degc': 'Converted 1-Wire 1_4 Degree C',
    'converted_ow_2_1_degc': 'Converted 1-Wire 2_1 Degree C',
    'converted_ow_2_2_degc': 'Converted 1-Wire 2_2 Degree C',
    'converted_ow_2_3_degc': 'Converted 1-Wire 2_3 Degree C',
    'converted_ow_2_4_degc': 'Converted 1-Wire 2_4 Degree C',
    'converted_ow_3_1_degc': 'Converted 1-Wire 3_1 Degree C',
    'converted_ow_3_2_degc': 'Converted 1-Wire 3_2 Degree C',
    'converted_ow_3_3_degc': 'Converted 1-Wire 3_3 Degree C',
    'converted_ow_3_4_degc': 'Converted 1-Wire 3_4 Degree C',
    'converted_ow_4_1_degc': 'Converted 1-Wire 4_1 Degree C',
    'converted_ow_4_2_degc': 'Converted 1-Wire 4_2 Degree C',
    'converted_ow_4_3_degc': 'Converted 1-Wire 4_3 Degree C',
    'converted_ow_4_4_degc': 'Converted 1-Wire 4_4 Degree C',
    'converted_ow_1_1_humidity': 'Converted 1-Wire 1_1 Humidity',
    'converted_ow_1_2_humidity': 'Converted 1-Wire 1_2 Humidity',
    'converted_ow_1_3_humidity': 'Converted 1-Wire 1_3 Humidity',
    'converted_ow_1_4_humidity': 'Converted 1-Wire 1_4 Humidity',
    'converted_ow_2_1_humidity': 'Converted 1-Wire 2_1 Humidity',
    'converted_ow_2_2_humidity': 'Converted 1-Wire 2_2 Humidity',
    'converted_ow_2_3_humidity': 'Converted 1-Wire 2_3 Humidity',
    'converted_ow_2_4_humidity': 'Converted 1-Wire 2_4 Humidity',
    'converted_ow_3_1_humidity': 'Converted 1-Wire 3_1 Humidity',
    'converted_ow_3_2_humidity': 'Converted 1-Wire 3_2 Humidity',
    'converted_ow_3_3_humidity': 'Converted 1-Wire 3_3 Humidity',
    'converted_ow_3_4_humidity': 'Converted 1-Wire 3_4 Humidity',
    'converted_ow_4_1_humidity': 'Converted 1-Wire 4_1 Humidity',
    'converted_ow_4_2_humidity': 'Converted 1-Wire 4_2 Humidity',
    'converted_ow_4_3_humidity': 'Converted 1-Wire 4_3 Humidity',
    'converted_ow_4_4_humidity': 'Converted 1-Wire 4_4 Humidity',
    'converted_ow_1_1_lux': 'Converted 1-Wire 1_1 Lux',
    'converted_ow_1_2_lux': 'Converted 1-Wire 1_2 Lux',
    'converted_ow_1_3_lux': 'Converted 1-Wire 1_3 Lux',
    'converted_ow_1_4_lux': 'Converted 1-Wire 1_4 Lux',
    'converted_ow_2_1_lux': 'Converted 1-Wire 2_1 Lux',
    'converted_ow_2_2_lux': 'Converted 1-Wire 2_2 Lux',
    'converted_ow_2_3_lux': 'Converted 1-Wire 2_3 Lux',
    'converted_ow_2_4_lux': 'Converted 1-Wire 2_4 Lux',
    'converted_ow_3_1_lux': 'Converted 1-Wire 3_1 Lux',
    'converted_ow_3_2_lux': 'Converted 1-Wire 3_2 Lux',
    'converted_ow_3_3_lux': 'Converted 1-Wire 3_3 Lux',
    'converted_ow_3_4_lux': 'Converted 1-Wire 3_4 Lux',
    'converted_ow_4_1_lux': 'Converted 1-Wire 4_1 Lux',
    'converted_ow_4_2_lux': 'Converted 1-Wire 4_2 Lux',
    'converted_ow_4_3_lux': 'Converted 1-Wire 4_3 Lux',
    'converted_ow_4_4_lux': 'Converted 1-Wire 4_4 Lux',
}

var _OPTIONS_PULSE_VALUE = {
    'gl': 'US Gallon',
    'qt': 'US Quart',
    'pt': 'US Pint',
    'l': 'Liter',
    'ml': 'Milliliter',
    'm3': 'Cubic Meter',
    'ft3': 'Cubic Foot',
    'in3': 'Cubic Inch',
    'CCF': 'CCF',
    'kWh': 'Kilowatt Hour (kWh)'
}
var _OPTIONS_PULSE_VALUE_PLURAL = {
    'gl': 'US Gallons',
    'qt': 'US Quarts',
    'pt': 'US Pints',
    'l': 'Liters',
    'ml': 'Mililiters',
    'm3': 'Cubic Meters',
    'ft3': 'Cubic Feet',
    'in3': 'Cubic Inches',
    'CCF': 'CCFs',
    'kWh': 'kWh'
}
var _SUMMARY_HEADER_FIELDS = {
    'Start_Time_Stamp_UTC_ms': 'Date',
    'kWh_Tot_Last': 'kWh_Tot',
    'kWh_Tariff_1_Last': 'kWh_Tariff_1',
    'kWh_Tariff_2_Last': 'kWh_Tariff_2',
    'kWh_Tariff_3_Last': 'kWh_Tariff_3',
    'kWh_Tariff_4_Last': 'kWh_Tariff_4',
    'Rev_kWh_Tot_Last': 'Rev_kWh_Tot',
    'Rev_kWh_Tariff_1_Last': 'Rev_kWh_Tariff_1',
    'Rev_kWh_Tariff_2_Last': 'Rev_kWh_Tariff_2',
    'Rev_kWh_Tariff_3_Last': 'Rev_kWh_Tariff_3',
    'Rev_kWh_Tariff_4_Last': 'Rev_kWh_Tariff_4',
    'RMS_Volts_Ln_1_Last': 'RMS_Volts_Ln_1',
    'RMS_Volts_Ln_2_Last': 'RMS_Volts_Ln_2',
    'RMS_Volts_Ln_3_Last': 'RMS_Volts_Ln_3',
    'Amps_Ln_1_Last': 'Amps_Ln_1',
    'Amps_Ln_2_Last': 'Amps_Ln_2',
    'Amps_Ln_3_Last': 'Amps_Ln_3',
    'RMS_Watts_Ln_1_Last': 'RMS_Watts_Ln_1',
    'RMS_Watts_Ln_2_Last': 'RMS_Watts_Ln_2',
    'RMS_Watts_Ln_3_Last': 'RMS_Watts_Ln_3',
    'RMS_Watts_Tot_Last': 'RMS_Watts_Tot',
    'Power_Factor_Ln_1_Last': 'Power_Factor_Ln_1',
    'Power_Factor_Ln_2_Last': 'Power_Factor_Ln_2',
    'Power_Factor_Ln_3_Last': 'Power_Factor_Ln_3',
    'RMS_Watts_Max_Demand_Last': 'RMS_Watts_Max_Demand',
    'CT_Ratio_Last': 'CT_Ratio',
    'Pulse_Cnt_1_Last': 'Pulse_Cnt_1',
    'Pulse_Cnt_2_Last': 'Pulse_Cnt_2',
    'Pulse_Cnt_3_Last': 'Pulse_Cnt_3',
    'Pulse_Ratio_1_Last': 'Pulse_Ratio_1',
    'Pulse_Ratio_2_Last': 'Pulse_Ratio_2',
    'Pulse_Ratio_3_Last': 'Pulse_Ratio_3',
    'Reactive_Energy_Tot_Last': 'Reactive_Energy_Tot',
    'kWh_Rst_Last': 'kWh_Rst',
    'Rev_kWh_Rst_Last': 'Rev_kWh_Rst',
    'Reactive_Pwr_Ln_1_Last': 'Reactive_Pwr_Ln_1',
    'Reactive_Pwr_Ln_2_Last': 'Reactive_Pwr_Ln_2',
    'Reactive_Pwr_Ln_3_Last': 'Reactive_Pwr_Ln_3',
    'Reactive_Pwr_Tot_Last': 'Reactive_Pwr_Tot',
    'Line_Freq_Last': 'Line_Freq',
    'kWh_Ln_1_Last': 'kWh_Ln_1',
    'kWh_Ln_2_Last': 'kWh_Ln_2',
    'kWh_Ln_3_Last': 'kWh_Ln_3',
    'Rev_kWh_Ln_1_Last': 'Rev_kWh_Ln_1',
    'Rev_kWh_Ln_2_Last': 'Rev_kWh_Ln_2',
    'Rev_kWh_Ln_3_Last': 'Rev_kWh_Ln_3',
    'Max_Demand_Rst_Last': 'Max_Demand_Rst',
    'CF_Ratio_Last': 'CF_Ratio'
}
var _SUMMARY_HEADER_FIELDS_IOSTACK = {
    'Start_Time_Stamp_UTC_ms': 'Date',
    'Analog_In_1': 'Analog Input 1',
    'Analog_In_2': 'Analog Input 2',
    'Analog_In_3': 'Analog Input 3',
    'Analog_In_4': 'Analog Input 4',
    'converted_analog_in_1': 'AI 1 Converted',
    'converted_analog_in_2': 'AI 2 Converted',
    'converted_analog_in_3': 'AI 3 Converted',
    'converted_analog_in_4': 'AI 4 Converted',
    'Pulse_Cnt_1': 'Pulse Count 1',
    'Pulse_Cnt_2': 'Pulse Count 2',
    'Pulse_Cnt_3': 'Pulse Count 3',
    'Pulse_Cnt_4': 'Pulse Count 4',
    'converted_io_pulse_cnt_1': 'PC 1 Converted',
    'converted_io_pulse_cnt_2': 'PC 2 Converted',
    'converted_io_pulse_cnt_3': 'PC 3 Converted',
    'converted_io_pulse_cnt_4': 'PC 4 Converted',
    'Pulse_Hi_Total_sec_1': 'Pulse High Total (sec) 1',
    'Pulse_Hi_Total_sec_2': 'Pulse High Total (sec) 2',
    'Pulse_Hi_Total_sec_3': 'Pulse High Total (sec) 3',
    'Pulse_Hi_Total_sec_4': 'Pulse High Total (sec) 4',
    'Pulse_Lo_Total_sec_1': 'Pulse Low Total (sec) 1',
    'Pulse_Lo_Total_sec_2': 'Pulse Low Total (sec) 2',
    'Pulse_Lo_Total_sec_3': 'Pulse Low Total (sec) 3',
    'Pulse_Lo_Total_sec_4': 'Pulse Low Total (sec) 4',
    'OW_1_1_degC': '1-Wire 1_1 Degree C',
    'OW_1_2_degC': '1-Wire 1_2 Degree C',
    'OW_1_3_degC': '1-Wire 1_3 Degree C',
    'OW_1_4_degC': '1-Wire 1_4 Degree C',
    'OW_2_1_degC': '1-Wire 2_1 Degree C',
    'OW_2_2_degC': '1-Wire 2_2 Degree C',
    'OW_2_3_degC': '1-Wire 2_3 Degree C',
    'OW_2_4_degC': '1-Wire 2_4 Degree C',
    'OW_3_1_degC': '1-Wire 3_1 Degree C',
    'OW_3_2_degC': '1-Wire 3_2 Degree C',
    'OW_3_3_degC': '1-Wire 3_3 Degree C',
    'OW_3_4_degC': '1-Wire 3_4 Degree C',
    'OW_4_1_degC': '1-Wire 4_1 Degree C',
    'OW_4_2_degC': '1-Wire 4_2 Degree C',
    'OW_4_3_degC': '1-Wire 4_3 Degree C',
    'OW_4_4_degC': '1-Wire 4_4 Degree C',
    'OW_1_1_Humidity': '1-Wire 1_1 Humidity',
    'OW_1_2_Humidity': '1-Wire 1_2 Humidity',
    'OW_1_3_Humidity': '1-Wire 1_3 Humidity',
    'OW_1_4_Humidity': '1-Wire 1_4 Humidity',
    'OW_2_1_Humidity': '1-Wire 2_1 Humidity',
    'OW_2_2_Humidity': '1-Wire 2_2 Humidity',
    'OW_2_3_Humidity': '1-Wire 2_3 Humidity',
    'OW_2_4_Humidity': '1-Wire 2_4 Humidity',
    'OW_3_1_Humidity': '1-Wire 3_1 humidity',
    'OW_3_2_Humidity': '1-Wire 3_2 Humidity',
    'OW_3_3_Humidity': '1-Wire 3_3 Humidity',
    'OW_3_4_Humidity': '1-Wire 3_4 Humidity',
    'OW_4_1_Humidity': '1-Wire 4_1 Humidity',
    'OW_4_2_Humidity': '1-Wire 4_2 Humidity',
    'OW_4_3_Humidity': '1-Wire 4_3 Humidity',
    'OW_4_4_Humidity': '1-Wire 4_4 Humidity',
    'OW_1_1_Lux': '1-Wire 1_1 Lux',
    'OW_1_2_Lux': '1-Wire 1_2 Lux',
    'OW_1_3_Lux': '1-Wire 1_3 Lux',
    'OW_1_4_Lux': '1-Wire 1_4 Lux',
    'OW_2_1_Lux': '1-Wire 2_1 Lux',
    'OW_2_2_Lux': '1-Wire 2_2 Lux',
    'OW_2_3_Lux': '1-Wire 2_3 Lux',
    'OW_2_4_Lux': '1-Wire 2_4 Lux',
    'OW_3_1_Lux': '1-Wire 3_1 Lux',
    'OW_3_2_Lux': '1-Wire 3_2 Lux',
    'OW_3_3_Lux': '1-Wire 3_3 Lux',
    'OW_3_4_Lux': '1-Wire 3_4 Lux',
    'OW_4_1_Lux': '1-Wire 4_1 Lux',
    'OW_4_2_Lux': '1-Wire 4_2 Lux',
    'OW_4_3_Lux': '1-Wire 4_3 Lux',
    'OW_4_4_Lux': '1-Wire 4_4 Lux',
    'converted_ow_1_1_degc': 'Converted 1-Wire 1_1 Degree C',
    'converted_ow_1_2_degc': 'Converted 1-Wire 1_2 Degree C',
    'converted_ow_1_3_degc': 'Converted 1-Wire 1_3 Degree C',
    'converted_ow_1_4_degc': 'Converted 1-Wire 1_4 Degree C',
    'converted_ow_2_1_degc': 'Converted 1-Wire 2_1 Degree C',
    'converted_ow_2_2_degc': 'Converted 1-Wire 2_2 Degree C',
    'converted_ow_2_3_degc': 'Converted 1-Wire 2_3 Degree C',
    'converted_ow_2_4_degc': 'Converted 1-Wire 2_4 Degree C',
    'converted_ow_3_1_degc': 'Converted 1-Wire 3_1 Degree C',
    'converted_ow_3_2_degc': 'Converted 1-Wire 3_2 Degree C',
    'converted_ow_3_3_degc': 'Converted 1-Wire 3_3 Degree C',
    'converted_ow_3_4_degc': 'Converted 1-Wire 3_4 Degree C',
    'converted_ow_4_1_degc': 'Converted 1-Wire 4_1 Degree C',
    'converted_ow_4_2_degc': 'Converted 1-Wire 4_2 Degree C',
    'converted_ow_4_3_degc': 'Converted 1-Wire 4_3 Degree C',
    'converted_ow_4_4_degc': 'Converted 1-Wire 4_4 Degree C',
    'converted_ow_1_1_humidity': 'Converted 1-Wire 1_1 Humidity',
    'converted_ow_1_2_humidity': 'Converted 1-Wire 1_2 Humidity',
    'converted_ow_1_3_humidity': 'Converted 1-Wire 1_3 Humidity',
    'converted_ow_1_4_humidity': 'Converted 1-Wire 1_4 Humidity',
    'converted_ow_2_1_humidity': 'Converted 1-Wire 2_1 Humidity',
    'converted_ow_2_2_humidity': 'Converted 1-Wire 2_2 Humidity',
    'converted_ow_2_3_humidity': 'Converted 1-Wire 2_3 Humidity',
    'converted_ow_2_4_humidity': 'Converted 1-Wire 2_4 Humidity',
    'converted_ow_3_1_humidity': 'Converted 1-Wire 3_1 humidity',
    'converted_ow_3_2_humidity': 'Converted 1-Wire 3_2 Humidity',
    'converted_ow_3_3_humidity': 'Converted 1-Wire 3_3 Humidity',
    'converted_ow_3_4_humidity': 'Converted 1-Wire 3_4 Humidity',
    'converted_ow_4_1_humidity': 'Converted 1-Wire 4_1 Humidity',
    'converted_ow_4_2_humidity': 'Converted 1-Wire 4_2 Humidity',
    'converted_ow_4_3_humidity': 'Converted 1-Wire 4_3 Humidity',
    'converted_ow_4_4_humidity': 'Converted 1-Wire 4_4 Humidity',
    'converted_ow_1_1_lux': 'Converted 1-Wire 1_1 Lux',
    'converted_ow_1_2_lux': 'Converted 1-Wire 1_2 Lux',
    'converted_ow_1_3_lux': 'Converted 1-Wire 1_3 Lux',
    'converted_ow_1_4_lux': 'Converted 1-Wire 1_4 Lux',
    'converted_ow_2_1_lux': 'Converted 1-Wire 2_1 Lux',
    'converted_ow_2_2_lux': 'Converted 1-Wire 2_2 Lux',
    'converted_ow_2_3_lux': 'Converted 1-Wire 2_3 Lux',
    'converted_ow_2_4_lux': 'Converted 1-Wire 2_4 Lux',
    'converted_ow_3_1_lux': 'Converted 1-Wire 3_1 Lux',
    'converted_ow_3_2_lux': 'Converted 1-Wire 3_2 Lux',
    'converted_ow_3_3_lux': 'Converted 1-Wire 3_3 Lux',
    'converted_ow_3_4_lux': 'Converted 1-Wire 3_4 Lux',
    'converted_ow_4_1_lux': 'Converted 1-Wire 4_1 Lux',
    'converted_ow_4_2_lux': 'Converted 1-Wire 4_2 Lux',
    'converted_ow_4_3_lux': 'Converted 1-Wire 4_3 Lux',
    'converted_ow_4_4_lux': 'Converted 1-Wire 4_4 Lux',

}

var _SUPPORTED_SCALES = {
    '15': {'interval': 900000, 'freeze_time': 3600000, 'desc': '15 Min'},
    'hr': {'interval': 3600000, 'freeze_time': 86400000, 'desc': 'Hour'},
    'dy': {'interval': 86400000, 'freeze_time': 604800000, 'desc': 'Day'},
    'wk': {'interval': 604800000, 'freeze_time': 2592000000, 'desc': 'Week'},
    'mo': {'interval': 604800000, 'freeze_time': 31536000000, 'desc': 'Month'} // interval changed to 604800000 because setTimeout does not works with large number
}
var _SUPPORTED_LAYOUTS = {
    'show_legend': 'Show Legend',
    'show_range': 'Show Range',
    'show_table': 'Show Table',
    'show_buttons': 'Show Buttons',
    'hide_all': 'Hide All'
}
var _SUPPORTED_DEFAULTED_TOT_FIELDS = {
    'kwh_tot': { 'summary-bar': 'kWh_Tot_Diff~kWh_Tot_First~kWh_Tot_Last', 'realtime': 'kWh_Tot', 'name': 'Total kWh'},
    'kwh_tariff_1': { 'summary-bar': 'kWh_Tariff_1_Diff~kWh_Tariff_1_First~kWh_Tariff_1_Last', 'realtime': 'kWh_Tariff_1', 'name': 'kWh Tariff 1'},
    'kwh_tariff_2': { 'summary-bar': 'kWh_Tariff_2_Diff~kWh_Tariff_2_First~kWh_Tariff_2_Last', 'realtime': 'kWh_Tariff_2', 'name': 'kWh Tariff 2'},
    'kwh_tariff_3': { 'summary-bar': 'kWh_Tariff_3_Diff~kWh_Tariff_3_First~kWh_Tariff_3_Last', 'realtime': 'kWh_Tariff_3', 'name': 'kWh Tariff 3'},
    'kwh_tariff_4': { 'summary-bar': 'kWh_Tariff_4_Diff~kWh_Tariff_3_First~kWh_Tariff_4_Last', 'realtime': 'kWh_Tariff_4', 'name': 'kWh Tariff 4'},
    'rev_kwh_tot': { 'summary-bar': 'Rev_kWh_Tot_Diff~Rev_kWh_Tot_First~Rev_kWh_Tot_Last', 'realtime': 'Rev_kWh_Tot', 'name': 'Reverse kWh'},
    'rev_kwh_tariff_1': { 'summary-bar': 'Rev_kWh_Tariff_1_Diff~Rev_kWh_Tariff_1_First~Rev_kWh_Tariff_1_Last', 'realtime': 'Rev_kWh_Tariff_1', 'name': 'Reverse kWh Tariff 1'},
    'rev_kwh_tariff_2': { 'summary-bar': 'Rev_kWh_Tariff_2_Diff~Rev_kWh_Tariff_2_First~Rev_kWh_Tariff_2_Last', 'realtime': 'Rev_kWh_Tariff_2', 'name': 'Reverse kWh Tariff 2'},
    'rev_kwh_tariff_3': { 'summary-bar': 'Rev_kWh_Tariff_3_Diff~Rev_kWh_Tariff_3_First~Rev_kWh_Tariff_3_Last', 'realtime': 'Rev_kWh_Tariff_3', 'name': 'Reverse kWh Tariff 3'},
    'rev_kwh_tariff_4': { 'summary-bar': 'Rev_kWh_Tariff_4_Diff~Rev_kWh_Tariff_4_First~Rev_kWh_Tariff_4_Last', 'realtime': 'Rev_kWh_Tariff_4', 'name': 'Reverse kWh Tariff 4'},
    'fwd_kwh_tot': { 'summary-bar': 'kWh_Tot_Diff~Rev_kWh_Tot_Diff~kWh_Tot_First~Rev_kWh_Tot_First~kWh_Tot_Last~Rev_kWh_Tot_Last', 'realtime': 'kWh_Tot~Rev_kWh_Tot', 'name': 'Forward kWh'},
    'fwd_kwh_tariff_1': { 'summary-bar': 'kWh_Tariff_1_Diff~Rev_kWh_Tariff_1_Diff~kWh_Tariff_1_First~Rev_kWh_Tariff_1_First~kWh_Tariff_1_Last~Rev_kWh_Tariff_1_Last', 'realtime': 'kWh_Tariff_1~Rev_kWh_Tariff_1', 'name': 'Forward kWh Tariff 1'},
    'fwd_kwh_tariff_2': { 'summary-bar': 'kWh_Tariff_2_Diff~Rev_kWh_Tariff_2_Diff~kWh_Tariff_2_First~Rev_kWh_Tariff_2_First~kWh_Tariff_2_Last~Rev_kWh_Tariff_2_Last', 'realtime': 'kWh_Tariff_2~Rev_kWh_Tariff_2', 'name': 'Forward kWh Tariff 2'},
    'fwd_kwh_tariff_3': { 'summary-bar': 'kWh_Tariff_3_Diff~Rev_kWh_Tariff_3_Diff~kWh_Tariff_3_First~Rev_kWh_Tariff_3_First~kWh_Tariff_3_Last~Rev_kWh_Tariff_3_Last', 'realtime': 'kWh_Tariff_3~Rev_kWh_Tariff_3', 'name': 'Forward kWh Tariff 3'},
    'fwd_kwh_tariff_4': { 'summary-bar': 'kWh_Tariff_4_Diff~Rev_kWh_Tariff_4_Diff~kWh_Tariff_4_First~Rev_kWh_Tariff_4_First~kWh_Tariff_4_Last~Rev_kWh_Tariff_4_Last', 'realtime': 'kWh_Tariff_4~Rev_kWh_Tariff_4', 'name': 'Forward kWh Tariff 4'},
    'net_kwh_tot': { 'summary-bar': 'kWh_Tot_Diff~Rev_kWh_Tot_Diff~kWh_Tot_First~Rev_kWh_Tot_First~kWh_Tot_Last~Rev_kWh_Tot_Last', 'realtime': 'kWh_Tot~Rev_kWh_Tot', 'name': 'Net kWh'},
    'net_kwh_tariff_1': { 'summary-bar': 'kWh_Tariff_1_Diff~Rev_kWh_Tariff_1_Diff~kWh_Tariff_1_First~Rev_kWh_Tariff_1_First~kWh_Tariff_1_Last~Rev_kWh_Tariff_1_Last', 'realtime': 'kWh_Tariff_1~Rev_kWh_Tariff_1', 'name': 'Net kWh Tariff 1'},
    'net_kwh_tariff_2': { 'summary-bar': 'kWh_Tariff_2_Diff~Rev_kWh_Tariff_2_Diff~kWh_Tariff_2_First~Rev_kWh_Tariff_2_First~kWh_Tariff_2_Last~Rev_kWh_Tariff_2_Last', 'realtime': 'kWh_Tariff_2~Rev_kWh_Tariff_2', 'name': 'Net kWh Tariff 2'},
    'net_kwh_tariff_3': { 'summary-bar': 'kWh_Tariff_3_Diff~Rev_kWh_Tariff_3_Diff~kWh_Tariff_3_First~Rev_kWh_Tariff_3_First~kWh_Tariff_3_Last~Rev_kWh_Tariff_3_Last', 'realtime': 'kWh_Tariff_3~Rev_kWh_Tariff_3', 'name': 'Net kWh Tariff 3'},
    'net_kwh_tariff_4': { 'summary-bar': 'kWh_Tariff_4_Diff~Rev_kWh_Tariff_4_Diff~kWh_Tariff_4_First~Rev_kWh_Tariff_4_First~kWh_Tariff_4_Last~Rev_kWh_Tariff_4_Last', 'realtime': 'kWh_Tariff_4~Rev_kWh_Tariff_4', 'name': 'Net kWh Tariff 4'},
    'pc_raw_1': { 'summary-bar': 'Pulse_Cnt_1_Diff~Pulse_Cnt_1_First~Pulse_Cnt_1_Last', 'realtime': 'Pulse_Cnt_1', 'name': 'Pulse Count 1'},
    'pc_raw_2': { 'summary-bar': 'Pulse_Cnt_2_Diff~Pulse_Cnt_2_First~Pulse_Cnt_2_Last', 'realtime': 'Pulse_Cnt_2', 'name': 'Pulse Count 2'},
    'pc_raw_3': { 'summary-bar': 'Pulse_Cnt_3_Diff~Pulse_Cnt_3_First~Pulse_Cnt_3_Last', 'realtime': 'Pulse_Cnt_3', 'name': 'Pulse Count 3'},

    'pulse_cnt_1': { 'summary-bar': 'Pulse_Cnt_1_Diff~Pulse_Ratio_1_Last~Pulse_Cnt_1_First~Pulse_Cnt_1_Last', 'realtime': 'Pulse_Cnt_1~Pulse_Ratio_1', 'name': 'PC 1 Value'},
    'pulse_cnt_2': { 'summary-bar': 'Pulse_Cnt_2_Diff~Pulse_Ratio_2_Last~Pulse_Cnt_2_First~Pulse_Cnt_2_Last', 'realtime': 'Pulse_Cnt_2~Pulse_Ratio_2', 'name': 'PC 2 Value'},
    'pulse_cnt_3': { 'summary-bar': 'Pulse_Cnt_3_Diff~Pulse_Ratio_3_Last~Pulse_Cnt_3_First~Pulse_Cnt_3_Last', 'realtime': 'Pulse_Cnt_3~Pulse_Ratio_3', 'name': 'PC 3 Value'},

    'converted_pulse_cnt_1': { 'summary-bar': 'Pulse_Cnt_1_Diff~Pulse_Ratio_1_Last~Pulse_Cnt_1_First~Pulse_Cnt_1_Last', 'realtime': 'Pulse_Cnt_1~Pulse_Ratio_1', 'name': 'PC 1 Value Converted'},
    'converted_pulse_cnt_2': { 'summary-bar': 'Pulse_Cnt_2_Diff~Pulse_Ratio_2_Last~Pulse_Cnt_2_First~Pulse_Cnt_2_Last', 'realtime': 'Pulse_Cnt_2~Pulse_Ratio_2', 'name': 'PC 2 Value Converted'},
    'converted_pulse_cnt_3': { 'summary-bar': 'Pulse_Cnt_3_Diff~Pulse_Ratio_3_Last~Pulse_Cnt_3_First~Pulse_Cnt_3_Last', 'realtime': 'Pulse_Cnt_3~Pulse_Ratio_3', 'name': 'PC 3 Value Converted'}
}

var _SUPPORTED_DEFAULTED_TOT_FIELDS_IOSTACK = {
    'pulse_cnt_1': { 'summary-bar': 'Pulse_Cnt_1_Diff~Pulse_Cnt_1_First~Pulse_Cnt_1_Last', 'realtime': 'Pulse_Cnt_1', 'name': 'Pulse Count 1'},
    'pulse_cnt_2': { 'summary-bar': 'Pulse_Cnt_2_Diff~Pulse_Cnt_2_First~Pulse_Cnt_2_Last', 'realtime': 'Pulse_Cnt_2', 'name': 'Pulse Count 2'},
    'pulse_cnt_3': { 'summary-bar': 'Pulse_Cnt_3_Diff~Pulse_Cnt_3_First~Pulse_Cnt_3_Last', 'realtime': 'Pulse_Cnt_3', 'name': 'Pulse Count 3'},
    'pulse_cnt_4': { 'summary-bar': 'Pulse_Cnt_4_Diff~Pulse_Cnt_4_First~Pulse_Cnt_4_Last', 'realtime': 'Pulse_Cnt_4', 'name': 'Pulse Count 4'},
    'converted_io_pulse_cnt_1': { 'summary-bar': 'Pulse_Cnt_1_Diff~Pulse_Cnt_1_First~Pulse_Cnt_1_Last', 'realtime': 'converted_io_pulse_cnt_1', 'name': 'PC 1 Converted'},
    'converted_io_pulse_cnt_2': { 'summary-bar': 'Pulse_Cnt_2_Diff~Pulse_Cnt_2_First~Pulse_Cnt_2_Last', 'realtime': 'converted_io_pulse_cnt_2', 'name': 'PC 2 Converted'},
    'converted_io_pulse_cnt_3': { 'summary-bar': 'Pulse_Cnt_3_Diff~Pulse_Cnt_3_First~Pulse_Cnt_3_Last', 'realtime': 'converted_io_pulse_cnt_3', 'name': 'PC 3 Converted'},
    'converted_io_pulse_cnt_4': { 'summary-bar': 'Pulse_Cnt_4_Diff~Pulse_Cnt_4_First~Pulse_Cnt_4_Last', 'realtime': 'converted_io_pulse_cnt_4', 'name': 'PC 4 Converted'},
}

var _ALL_SUMMARY_FIELDS_DIFF = {
    'kwh_tot': { 'key': 'kWh_Tot', 'value': 'kWh_Tot_Diff' },
    'kwh_tariff_1': { 'key': 'kWh_Tariff_1', 'value': 'kWh_Tariff_1_Diff' },
    'kwh_tariff_2': { 'key': 'kWh_Tariff_2', 'value': 'kWh_Tariff_2_Diff' },
    'kwh_tariff_3': { 'key': 'kWh_Tariff_3', 'value': 'kWh_Tariff_3_Diff' },
    'kwh_tariff_4': { 'key': 'kWh_Tariff_4', 'value': 'kWh_Tariff_4_Diff' },
    'rev_kwh_tot': { 'key': 'Rev_kWh_Tot', 'value': 'Rev_kWh_Tot_Diff' },
    'rev_kwh_tariff_1': { 'key': 'Rev_kWh_Tariff_1', 'value': 'Rev_kWh_Tariff_1_Diff' },
    'rev_kwh_tariff_2': { 'key': 'Rev_kWh_Tariff_2', 'value': 'Rev_kWh_Tariff_2_Diff' },
    'rev_kwh_tariff_3': { 'key': 'Rev_kWh_Tariff_3', 'value': 'Rev_kWh_Tariff_3_Diff' },
    'rev_kwh_tariff_4': { 'key': 'Rev_kWh_Tariff_4', 'value': 'Rev_kWh_Tariff_4_Diff' },
    'fwd_kwh_tot': { 'key': 'kWh_Tot~Rev_kWh_Tot', 'value': 'kWh_Tot_Diff~Rev_kWh_Tot_Diff' },
    'fwd_kwh_tariff_1': {'key': 'kWh_Tariff_1~Rev_kWh_Tariff_1', 'value': 'kWh_Tariff_1_Diff~Rev_kWh_Tariff_1_Diff'},
    'fwd_kwh_tariff_2': {'key': 'kWh_Tariff_2~Rev_kWh_Tariff_2', 'value': 'kWh_Tariff_2_Diff~Rev_kWh_Tariff_2_Diff'},
    'fwd_kwh_tariff_3': {'key': 'kWh_Tariff_3~Rev_kWh_Tariff_3', 'value': 'kWh_Tariff_3_Diff~Rev_kWh_Tariff_3_Diff'},
    'fwd_kwh_tariff_4': {'key': 'kWh_Tariff_4~Rev_kWh_Tariff_4', 'value': 'kWh_Tariff_4_Diff~Rev_kWh_Tariff_4_Diff'},
    'net_kwh_tot': { 'key': 'kWh_Tot~Rev_kWh_Tot', 'value': 'kWh_Tot_Diff~Rev_kWh_Tot_Diff' },
    'net_kwh_tariff_1': {'key': 'kWh_Tariff_1~Rev_kWh_Tariff_1', 'value': 'kWh_Tariff_1_Diff~Rev_kWh_Tariff_1_Diff'},
    'net_kwh_tariff_2': {'key': 'kWh_Tariff_2~Rev_kWh_Tariff_2', 'value': 'kWh_Tariff_2_Diff~Rev_kWh_Tariff_2_Diff'},
    'net_kwh_tariff_3': {'key': 'kWh_Tariff_3~Rev_kWh_Tariff_3', 'value': 'kWh_Tariff_3_Diff~Rev_kWh_Tariff_3_Diff'},
    'net_kwh_tariff_4': {'key': 'kWh_Tariff_4~Rev_kWh_Tariff_4', 'value': 'kWh_Tariff_4_Diff~Rev_kWh_Tariff_4_Diff'},
    'rms_volts_ln_1': { 'key': 'RMS_Volts_Ln_1', 'value': 'RMS_Volts_Ln_1_Last' },
    'rms_volts_ln_2': { 'key': 'RMS_Volts_Ln_2', 'value': 'RMS_Volts_Ln_2_Last' },
    'rms_volts_ln_3': { 'key': 'RMS_Volts_Ln_3', 'value': 'RMS_Volts_Ln_3_Last' },
    'amps_ln_1': { 'key': 'Amps_Ln_1', 'value': 'Amps_Ln_1_Last' },
    'amps_ln_2': { 'key': 'Amps_Ln_2', 'value': 'Amps_Ln_2_Last' },
    'amps_ln_3': { 'key': 'Amps_Ln_3', 'value': 'Amps_Ln_3_Last' },
    'rms_watts_ln_1': { 'key': 'RMS_Watts_Ln_1', 'value': 'RMS_Watts_Ln_1_Last' },
    'rms_watts_ln_2': { 'key': 'RMS_Watts_Ln_2', 'value': 'RMS_Watts_Ln_2_Last' },
    'rms_watts_ln_3': { 'key': 'RMS_Watts_Ln_3', 'value': 'RMS_Watts_Ln_3_Last' },
    'rms_watts_tot': { 'key': 'RMS_Watts_Tot', 'value': 'RMS_Watts_Tot_Last' },
    'power_factor_ln_1': { 'key': 'Power_Factor_Ln_1', 'value': 'Power_Factor_Ln_1_Last' },
    'power_factor_ln_2': { 'key': 'Power_Factor_Ln_2', 'value': 'Power_Factor_Ln_2_Last' },
    'power_factor_ln_3': { 'key': 'Power_Factor_Ln_3', 'value': 'Power_Factor_Ln_3_Last' },
    'rms_watts_max_demand': { 'key': 'RMS_Watts_Max_Demand', 'value': 'RMS_Watts_Max_Demand_Last' },
    'max_demand_period': { 'key': 'Max_Demand_Period', 'value': 'NA' },
    'ct_ratio': { 'key': 'CT_Ratio', 'value': 'CT_Ratio_Diff' },
    'pc_raw_1': { 'key': 'Pulse_Cnt_1', 'value': 'Pulse_Cnt_1_Diff' },
    'pc_raw_2': { 'key': 'Pulse_Cnt_2', 'value': 'Pulse_Cnt_2_Diff' },
    'pc_raw_3': { 'key': 'Pulse_Cnt_3', 'value': 'Pulse_Cnt_3_Diff' },
    'pulse_cnt_1': { 'key': 'Pulse_Cnt_1~Pulse_Ratio_1', 'value': 'Pulse_Cnt_1_Diff~Pulse_Ratio_1_Last' },
    'pulse_cnt_2': { 'key': 'Pulse_Cnt_2~Pulse_Ratio_2', 'value': 'Pulse_Cnt_2_Diff~Pulse_Ratio_2_Last' },
    'pulse_cnt_3': { 'key': 'Pulse_Cnt_3~Pulse_Ratio_3', 'value': 'Pulse_Cnt_3_Diff~Pulse_Ratio_3_Last' },
    'converted_pulse_cnt_1': {'key': 'Pulse_Cnt_1~Pulse_Ratio_1', 'value': 'Pulse_Cnt_1_Diff~Pulse_Ratio_1_Last'},
    'converted_pulse_cnt_2': {'key': 'Pulse_Cnt_2~Pulse_Ratio_2', 'value': 'Pulse_Cnt_2_Diff~Pulse_Ratio_2_Last'},
    'converted_pulse_cnt_3': {'key': 'Pulse_Cnt_3~Pulse_Ratio_3', 'value': 'Pulse_Cnt_3_Diff~Pulse_Ratio_3_Last'},
    'pulse_ratio_1': { 'key': 'Pulse_Ratio_1', 'value': 'Pulse_Ratio_1_Diff' },
    'pulse_ratio_2': { 'key': 'Pulse_Ratio_2', 'value': 'Pulse_Ratio_2_Diff' },
    'pulse_ratio_3': { 'key': 'Pulse_Ratio_3', 'value': 'Pulse_Ratio_3_Diff' },
    'state_inputs_1': { 'key': 'State_Inputs', 'value': 'State_Inputs_Last' },
    'state_inputs_2': { 'key': 'State_Inputs', 'value': 'State_Inputs_Last' },
    'state_inputs_3': { 'key': 'State_Inputs', 'value': 'State_Inputs_Last' },
    'state_out_1': { 'key': 'State_Out', 'value': 'State_Out_Last' },
    'state_out_2': { 'key': 'State_Out', 'value': 'State_Out_Last' },
    'reactive_energy_tot': { 'key': 'Reactive_Energy_Tot', 'value': 'Reactive_Energy_Tot_Diff' },
    'kwh_rst': { 'key': 'kWh_Rst', 'value': 'kWh_Rst_Diff' },
    'rev_kwh_rst': { 'key': 'Rev_kWh_Rst', 'value': 'Rev_kWh_Rst_Diff' },
    'reactive_pwr_ln_1': { 'key': 'Reactive_Pwr_Ln_1', 'value': 'Reactive_Pwr_Ln_1_Last' },
    'reactive_pwr_ln_2': { 'key': 'Reactive_Pwr_Ln_2', 'value': 'Reactive_Pwr_Ln_2_Last' },
    'reactive_pwr_ln_3': { 'key': 'Reactive_Pwr_Ln_3', 'value': 'Reactive_Pwr_Ln_3_Last' },
    'reactive_pwr_tot': { 'key': 'Reactive_Pwr_Tot', 'value': 'Reactive_Pwr_Tot_Last' },
    'kwh_scale': { 'key': 'kWh_Scale', 'value': 'NA' },
    'line_freq': { 'key': 'Line_Freq', 'value': 'Line_Freq_Last' },
    'state_watts_dir': { 'key': 'State_Watts_Dir', 'value': 'NA' },
    'kwh_ln_1': { 'key': 'kWh_Ln_1', 'value': 'kWh_Ln_1_Diff' },
    'kwh_ln_2': { 'key': 'kWh_Ln_2', 'value': 'kWh_Ln_2_Diff' },
    'kwh_ln_3': { 'key': 'kWh_Ln_3', 'value': 'kWh_Ln_3_Diff' },
    'rev_kwh_ln_1': { 'key': 'Rev_kWh_Ln_1', 'value': 'Rev_kWh_Ln_1_Diff' },
    'rev_kwh_ln_2': { 'key': 'Rev_kWh_Ln_2', 'value': 'Rev_kWh_Ln_2_Diff' },
    'rev_kwh_ln_3': { 'key': 'Rev_kWh_Ln_3', 'value': 'Rev_kWh_Ln_3_Diff' },
    'max_demand_rst': { 'key': 'Max_Demand_Rst', 'value': 'Max_Demand_Rst_Diff' },
    'cf_ratio': { 'key': 'CF_Ratio', 'value': 'CF_Ratio_Diff' },
    'meter_status_code': { 'key': 'Meter_Status_Code', 'value': 'NA' },
    'net_calc_watts_ln_1': { 'key': 'Net_Calc_Watts_Ln_1', 'value': 'Net_Calc_Watts_Ln_1_Last' },
    'net_calc_watts_ln_2': { 'key': 'Net_Calc_Watts_Ln_2', 'value': 'Net_Calc_Watts_Ln_2_Last' },
    'net_calc_watts_ln_3': { 'key': 'Net_Calc_Watts_Ln_3', 'value': 'Net_Calc_Watts_Ln_3_Last' },
    'net_calc_watts_tot': { 'key': 'Net_Calc_Watts_Tot', 'value': 'Net_Calc_Watts_Tot_Last'}
    }

_ALL_SUMMARY_FIELDS_DIFF_IOSTACK = {
    'analog_in_1': {'key': 'Analog_In_1', 'value': 'Analog_In_1_Last'},
    'analog_in_2': {'key': 'Analog_In_2', 'value': 'Analog_In_2_Last'},
    'analog_in_3': {'key': 'Analog_In_3', 'value': 'Analog_In_3_Last'},
    'analog_in_4': {'key': 'Analog_In_4', 'value': 'Analog_In_4_Last'},
    'converted_analog_in_1' : {'key': 'Analog_In_1', 'value': 'Analog_In_1_Last'},
    'converted_analog_in_2': {'key': 'Analog_In_2', 'value': 'Analog_In_2_Last'},
    'converted_analog_in_3': {'key': 'Analog_In_3', 'value': 'Analog_In_3_Last'},
    'converted_analog_in_4': {'key': 'Analog_In_4', 'value': 'Analog_In_4_Last'},
    'state_inputs_1': {'key': 'State_Inputs', 'value': 'State_Inputs_Last'},
    'state_inputs_2': {'key': 'State_Inputs', 'value': 'State_Inputs_Last'},
    'state_inputs_3': {'key': 'State_Inputs', 'value': 'State_Inputs_Last'},
    'state_inputs_4': {'key': 'State_Inputs', 'value': 'State_Inputs_Last'},
    'state_out_1': {'key': 'State_Out', 'value': 'State_Out_Last'},
    'state_out_2': {'key': 'State_Out', 'value': 'State_Out_Last'},
    'state_out_3': {'key': 'State_Out', 'value': 'State_Out_Last'},
    'state_out_4': {'key': 'State_Out', 'value': 'State_Out_Last'},
    'pulse_cnt_1': {'key': 'Pulse_Cnt_1', 'value': 'Pulse_Cnt_1_Diff'},
    'pulse_cnt_2': {'key': 'Pulse_Cnt_2', 'value': 'Pulse_Cnt_2_Diff'},
    'pulse_cnt_3': {'key': 'Pulse_Cnt_3', 'value': 'Pulse_Cnt_3_Diff'},
    'pulse_cnt_4': {'key': 'Pulse_Cnt_4', 'value': 'Pulse_Cnt_4_Diff'},
    'converted_io_pulse_cnt_1': {'key': 'Pulse_Cnt_1', 'value': 'Pulse_Cnt_1'},
    'converted_io_pulse_cnt_2': {'key': 'Pulse_Cnt_2', 'value': 'Pulse_Cnt_2'},
    'converted_io_pulse_cnt_3': {'key': 'Pulse_Cnt_3', 'value': 'Pulse_Cnt_3'},
    'converted_io_pulse_cnt_4': {'key': 'Pulse_Cnt_4', 'value': 'Pulse_Cnt_4'},
    'pulse_cnt_rst_1': {'key': 'Pulse_Cnt_Rst_1', 'value': 'Pulse_Cnt_Rst_1_Last'},
    'pulse_cnt_rst_2': {'key': 'Pulse_Cnt_Rst_2', 'value': 'Pulse_Cnt_Rst_2_Last'},
    'pulse_cnt_rst_3': {'key': 'Pulse_Cnt_Rst_3', 'value': 'Pulse_Cnt_Rst_3_Last'},
    'pulse_cnt_rst_4': {'key': 'Pulse_Cnt_Rst_4', 'value': 'Pulse_Cnt_Rst_4_Last'},
    'pulse_hold_ms_1': {'key': 'Pulse_Hold_ms_1', 'value': 'Pulse_Hold_ms_1_Last'},
    'pulse_hold_ms_2': {'key': 'Pulse_Hold_ms_2', 'value': 'Pulse_Hold_ms_2_Last'},
    'pulse_hold_ms_3': {'key': 'Pulse_Hold_ms_3', 'value': 'Pulse_Hold_ms_3_Last'},
    'pulse_hold_ms_4': {'key': 'Pulse_Hold_ms_4', 'value': 'Pulse_Hold_ms_4_Last'},
    'pulse_hi_prev_ms_1': {'key': 'Pulse_Hi_Prev_ms_1', 'value': 'Pulse_Hi_Prev_ms_1_Last'},
    'pulse_hi_prev_ms_2': {'key': 'Pulse_Hi_Prev_ms_2', 'value': 'Pulse_Hi_Prev_ms_2_Last'},
    'pulse_hi_prev_ms_3': {'key': 'Pulse_Hi_Prev_ms_3', 'value': 'Pulse_Hi_Prev_ms_3_Last'},
    'pulse_hi_prev_ms_4': {'key': 'Pulse_Hi_Prev_ms_4', 'value': 'Pulse_Hi_Prev_ms_4_Last'},
    'pulse_lo_prev_ms_1': {'key': 'Pulse_Lo_Prev_ms_1', 'value': 'Pulse_Lo_Prev_ms_1_Last'},
    'pulse_lo_prev_ms_2': {'key': 'Pulse_Lo_Prev_ms_2', 'value': 'Pulse_Lo_Prev_ms_2_Last'},
    'pulse_lo_prev_ms_3': {'key': 'Pulse_Lo_Prev_ms_3', 'value': 'Pulse_Lo_Prev_ms_3_Last'},
    'pulse_lo_prev_ms_4': {'key': 'Pulse_Lo_Prev_ms_4', 'value': 'Pulse_Lo_Prev_ms_4_Last'},
    'pulse_hi_total_sec_1': {'key': 'Pulse_Hi_Total_sec_1', 'value': 'Pulse_Hi_Total_sec_1_Diff'},
    'pulse_hi_total_sec_2': {'key': 'Pulse_Hi_Total_sec_2', 'value': 'Pulse_Hi_Total_sec_2_Diff'},
    'pulse_hi_total_sec_3': {'key': 'Pulse_Hi_Total_sec_3', 'value': 'Pulse_Hi_Total_sec_3_Diff'},
    'pulse_hi_total_sec_4': {'key': 'Pulse_Hi_Total_sec_4', 'value': 'Pulse_Hi_Total_sec_4_Diff'},
    'pulse_lo_total_sec_1': {'key': 'Pulse_Lo_Total_sec_1', 'value': 'Pulse_Lo_Total_sec_1_Diff'},
    'pulse_lo_total_sec_2': {'key': 'Pulse_Lo_Total_sec_2', 'value': 'Pulse_Lo_Total_sec_2_Diff'},
    'pulse_lo_total_sec_3': {'key': 'Pulse_Lo_Total_sec_3', 'value': 'Pulse_Lo_Total_sec_3_Diff'},
    'pulse_lo_total_sec_4': {'key': 'Pulse_Lo_Total_sec_4', 'value': 'Pulse_Lo_Total_sec_4_Diff'},
    'ow_1_1_degc': {'key': 'OW_1_1_degC', 'value': 'OW_1_1_degC_Last'},
    'ow_1_2_degc': {'key': 'OW_1_2_degC', 'value': 'OW_1_2_degC_Last'},
    'ow_1_3_degc': {'key': 'OW_1_3_degC', 'value': 'OW_1_3_degC_Last'},
    'ow_1_4_degc': {'key': 'OW_1_4_degC', 'value': 'OW_1_4_degC_Last'},
    'ow_2_1_degc': {'key': 'OW_2_1_degC', 'value': 'OW_2_1_degC_Last'},
    'ow_2_2_degc': {'key': 'OW_2_2_degC', 'value': 'OW_2_2_degC_Last'},
    'ow_2_3_degc': {'key': 'OW_2_3_degC', 'value': 'OW_2_3_degC_Last'},
    'ow_2_4_degc': {'key': 'OW_2_4_degC', 'value': 'OW_2_4_degC_Last'},
    'ow_3_1_degc': {'key': 'OW_3_1_degC', 'value': 'OW_3_1_degC_Last'},
    'ow_3_2_degc': {'key': 'OW_3_2_degC', 'value': 'OW_3_2_degC_Last'},
    'ow_3_3_degc': {'key': 'OW_3_3_degC', 'value': 'OW_3_3_degC_Last'},
    'ow_3_4_degc': {'key': 'OW_3_4_degC', 'value': 'OW_3_4_degC_Last'},
    'ow_4_1_degc': {'key': 'OW_4_1_degC', 'value': 'OW_4_1_degC_Last'},
    'ow_4_2_degc': {'key': 'OW_4_2_degC', 'value': 'OW_4_2_degC_Last'},
    'ow_4_3_degc': {'key': 'OW_4_3_degC', 'value': 'OW_4_3_degC_Last'},
    'ow_4_4_degc': {'key': 'OW_4_4_degC', 'value': 'OW_4_4_degC_Last'},

    'ow_1_1_humidity': {'key': 'OW_1_1_Humidity', 'value': 'OW_1_1_Humidity_Last'},
    'ow_1_2_humidity': {'key': 'OW_1_2_Humidity', 'value': 'OW_1_2_Humidity_Last'},
    'ow_1_3_humidity': {'key': 'OW_1_3_Humidity', 'value': 'OW_1_3_Humidity_Last'},
    'ow_1_4_humidity': {'key': 'OW_1_4_Humidity', 'value': 'OW_1_4_Humidity_Last'},
    'ow_2_1_humidity': {'key': 'OW_2_1_Humidity', 'value': 'OW_2_1_Humidity_Last'},
    'ow_2_2_humidity': {'key': 'OW_2_2_Humidity', 'value': 'OW_2_2_Humidity_Last'},
    'ow_2_3_humidity': {'key': 'OW_2_3_Humidity', 'value': 'OW_2_3_Humidity_Last'},
    'ow_2_4_humidity': {'key': 'OW_2_4_Humidity', 'value': 'OW_2_4_Humidity_Last'},
    'ow_3_1_humidity': {'key': 'OW_3_1_Humidity', 'value': 'OW_3_1_Humidity_Last'},
    'ow_3_2_humidity': {'key': 'OW_3_2_Humidity', 'value': 'OW_3_2_Humidity_Last'},
    'ow_3_3_humidity': {'key': 'OW_3_3_Humidity', 'value': 'OW_3_3_Humidity_Last'},
    'ow_3_4_humidity': {'key': 'OW_3_4_Humidity', 'value': 'OW_3_4_Humidity_Last'},
    'ow_4_1_humidity': {'key': 'OW_4_1_Humidity', 'value': 'OW_4_1_Humidity_Last'},
    'ow_4_2_humidity': {'key': 'OW_4_2_Humidity', 'value': 'OW_4_2_Humidity_Last'},
    'ow_4_3_humidity': {'key': 'OW_4_3_Humidity', 'value': 'OW_4_3_Humidity_Last'},
    'ow_4_4_humidity': {'key': 'OW_4_4_Humidity', 'value': 'OW_4_4_Humidity_Last'},
    
    'ow_1_1_lux': {'key': 'OW_1_1_Lux', 'value': 'OW_1_1_Lux_Last'},
    'ow_1_2_lux': {'key': 'OW_1_2_Lux', 'value': 'OW_1_2_Lux_Last'},
    'ow_1_3_lux': {'key': 'OW_1_3_Lux', 'value': 'OW_1_3_Lux_Last'},
    'ow_1_4_lux': {'key': 'OW_1_4_Lux', 'value': 'OW_1_4_Lux_Last'},
    'ow_2_1_lux': {'key': 'OW_2_1_Lux', 'value': 'OW_2_1_Lux_Last'},
    'ow_2_2_lux': {'key': 'OW_2_2_Lux', 'value': 'OW_2_2_Lux_Last'},
    'ow_2_3_lux': {'key': 'OW_2_3_Lux', 'value': 'OW_2_3_Lux_Last'},
    'ow_2_4_lux': {'key': 'OW_2_4_Lux', 'value': 'OW_2_4_Lux_Last'},
    'ow_3_1_lux': {'key': 'OW_3_1_Lux', 'value': 'OW_3_1_Lux_Last'},
    'ow_3_2_lux': {'key': 'OW_3_2_Lux', 'value': 'OW_3_2_Lux_Last'},
    'ow_3_3_lux': {'key': 'OW_3_3_Lux', 'value': 'OW_3_3_Lux_Last'},
    'ow_3_4_lux': {'key': 'OW_3_4_Lux', 'value': 'OW_3_4_Lux_Last'},
    'ow_4_1_lux': {'key': 'OW_4_1_Lux', 'value': 'OW_4_1_Lux_Last'},
    'ow_4_2_lux': {'key': 'OW_4_2_Lux', 'value': 'OW_4_2_Lux_Last'},
    'ow_4_3_lux': {'key': 'OW_4_3_Lux', 'value': 'OW_4_3_Lux_Last'},
    'ow_4_4_lux': {'key': 'OW_4_4_Lux', 'value': 'OW_4_4_Lux_Last'},

    'converted_ow_1_1_degc': {'key': 'OW_1_1_degC', 'value': 'OW_1_1_degC_Last'},
    'converted_ow_1_2_degc': {'key': 'OW_1_2_degC', 'value': 'OW_1_2_degC_Last'},
    'converted_ow_1_3_degc': {'key': 'OW_1_3_degC', 'value': 'OW_1_3_degC_Last'},
    'converted_ow_1_4_degc': {'key': 'OW_1_4_degC', 'value': 'OW_1_4_degC_Last'},
    'converted_ow_2_1_degc': {'key': 'OW_2_1_degC', 'value': 'OW_2_1_degC_Last'},
    'converted_ow_2_2_degc': {'key': 'OW_2_2_degC', 'value': 'OW_2_2_degC_Last'},
    'converted_ow_2_3_degc': {'key': 'OW_2_3_degC', 'value': 'OW_2_3_degC_Last'},
    'converted_ow_2_4_degc': {'key': 'OW_2_4_degC', 'value': 'OW_2_4_degC_Last'},
    'converted_ow_3_1_degc': {'key': 'OW_3_1_degC', 'value': 'OW_3_1_degC_Last'},
    'converted_ow_3_2_degc': {'key': 'OW_3_2_degC', 'value': 'OW_3_2_degC_Last'},
    'converted_ow_3_3_degc': {'key': 'OW_3_3_degC', 'value': 'OW_3_3_degC_Last'},
    'converted_ow_3_4_degc': {'key': 'OW_3_4_degC', 'value': 'OW_3_4_degC_Last'},
    'converted_ow_4_1_degc': {'key': 'OW_4_1_degC', 'value': 'OW_4_1_degC_Last'},
    'converted_ow_4_2_degc': {'key': 'OW_4_2_degC', 'value': 'OW_4_2_degC_Last'},
    'converted_ow_4_3_degc': {'key': 'OW_4_3_degC', 'value': 'OW_4_3_degC_Last'},
    'converted_ow_4_4_degc': {'key': 'OW_4_4_degC', 'value': 'OW_4_4_degC_Last'},

    'converted_ow_1_1_humidity': {'key': 'OW_1_1_Humidity', 'value': 'OW_1_1_Humidity_Last'},
    'converted_ow_1_2_humidity': {'key': 'OW_1_2_Humidity', 'value': 'OW_1_2_Humidity_Last'},
    'converted_ow_1_3_humidity': {'key': 'OW_1_3_Humidity', 'value': 'OW_1_3_Humidity_Last'},
    'converted_ow_1_4_humidity': {'key': 'OW_1_4_Humidity', 'value': 'OW_1_4_Humidity_Last'},
    'converted_ow_2_1_humidity': {'key': 'OW_2_1_Humidity', 'value': 'OW_2_1_Humidity_Last'},
    'converted_ow_2_2_humidity': {'key': 'OW_2_2_Humidity', 'value': 'OW_2_2_Humidity_Last'},
    'converted_ow_2_3_humidity': {'key': 'OW_2_3_Humidity', 'value': 'OW_2_3_Humidity_Last'},
    'converted_ow_2_4_humidity': {'key': 'OW_2_4_Humidity', 'value': 'OW_2_4_Humidity_Last'},
    'converted_ow_3_1_humidity': {'key': 'OW_3_1_Humidity', 'value': 'OW_3_1_Humidity_Last'},
    'converted_ow_3_2_humidity': {'key': 'OW_3_2_Humidity', 'value': 'OW_3_2_Humidity_Last'},
    'converted_ow_3_3_humidity': {'key': 'OW_3_3_Humidity', 'value': 'OW_3_3_Humidity_Last'},
    'converted_ow_3_4_humidity': {'key': 'OW_3_4_Humidity', 'value': 'OW_3_4_Humidity_Last'},
    'converted_ow_4_1_humidity': {'key': 'OW_4_1_Humidity', 'value': 'OW_4_1_Humidity_Last'},
    'converted_ow_4_2_humidity': {'key': 'OW_4_2_Humidity', 'value': 'OW_4_2_Humidity_Last'},
    'converted_ow_4_3_humidity': {'key': 'OW_4_3_Humidity', 'value': 'OW_4_3_Humidity_Last'},
    'converted_ow_4_4_humidity': {'key': 'OW_4_4_Humidity', 'value': 'OW_4_4_Humidity_Last'},

    'converted_ow_1_1_lux': {'key': 'OW_1_1_Lux', 'value': 'OW_1_1_Lux_Last'},
    'converted_ow_1_2_lux': {'key': 'OW_1_2_Lux', 'value': 'OW_1_2_Lux_Last'},
    'converted_ow_1_3_lux': {'key': 'OW_1_3_Lux', 'value': 'OW_1_3_Lux_Last'},
    'converted_ow_1_4_lux': {'key': 'OW_1_4_Lux', 'value': 'OW_1_4_Lux_Last'},
    'converted_ow_2_1_lux': {'key': 'OW_2_1_Lux', 'value': 'OW_2_1_Lux_Last'},
    'converted_ow_2_2_lux': {'key': 'OW_2_2_Lux', 'value': 'OW_2_2_Lux_Last'},
    'converted_ow_2_3_lux': {'key': 'OW_2_3_Lux', 'value': 'OW_2_3_Lux_Last'},
    'converted_ow_2_4_lux': {'key': 'OW_2_4_Lux', 'value': 'OW_2_4_Lux_Last'},
    'converted_ow_3_1_lux': {'key': 'OW_3_1_Lux', 'value': 'OW_3_1_Lux_Last'},
    'converted_ow_3_2_lux': {'key': 'OW_3_2_Lux', 'value': 'OW_3_2_Lux_Last'},
    'converted_ow_3_3_lux': {'key': 'OW_3_3_Lux', 'value': 'OW_3_3_Lux_Last'},
    'converted_ow_3_4_lux': {'key': 'OW_3_4_Lux', 'value': 'OW_3_4_Lux_Last'},
    'converted_ow_4_1_lux': {'key': 'OW_4_1_Lux', 'value': 'OW_4_1_Lux_Last'},
    'converted_ow_4_2_lux': {'key': 'OW_4_2_Lux', 'value': 'OW_4_2_Lux_Last'},
    'converted_ow_4_3_lux': {'key': 'OW_4_3_Lux', 'value': 'OW_4_3_Lux_Last'},
    'converted_ow_4_4_lux': {'key': 'OW_4_4_Lux', 'value': 'OW_4_4_Lux_Last'},
}

var _ALL_SUMMARY_FIELDS_MAX = {
    'kwh_tot': { 'key': 'kWh_Tot', 'value': 'kWh_Tot_Last' },
    'kwh_tariff_1': { 'key': 'kWh_Tariff_1', 'value': 'kWh_Tariff_1_Last' },
    'kwh_tariff_2': { 'key': 'kWh_Tariff_2', 'value': 'kWh_Tariff_2_Last' },
    'kwh_tariff_3': { 'key': 'kWh_Tariff_3', 'value': 'kWh_Tariff_3_Last' },
    'kwh_tariff_4': { 'key': 'kWh_Tariff_4', 'value': 'kWh_Tariff_4_Last' },
    'rev_kwh_tot': { 'key': 'Rev_kWh_Tot', 'value': 'Rev_kWh_Tot_Last' },
    'rev_kwh_tariff_1': { 'key': 'Rev_kWh_Tariff_1', 'value': 'Rev_kWh_Tariff_1_Last' },
    'rev_kwh_tariff_2': { 'key': 'Rev_kWh_Tariff_2', 'value': 'Rev_kWh_Tariff_2_Last' },
    'rev_kwh_tariff_3': { 'key': 'Rev_kWh_Tariff_3', 'value': 'Rev_kWh_Tariff_3_Last' },
    'rev_kwh_tariff_4': { 'key': 'Rev_kWh_Tariff_4', 'value': 'Rev_kWh_Tariff_4_Last' },
    'fwd_kwh_tot': { 'key': 'kWh_Tot~Rev_kWh_Tot', 'value': 'kWh_Tot_Last~Rev_kWh_Tot_Last' },
    'fwd_kwh_tariff_1': { 'key': 'kWh_Tariff_1~Rev_kWh_Tariff_1', 'value': 'kWh_Tariff_1_Last~Rev_kWh_Tariff_1_Last' },
    'fwd_kwh_tariff_2': { 'key': 'kWh_Tariff_2~Rev_kWh_Tariff_2', 'value': 'kWh_Tariff_2_Last~Rev_kWh_Tariff_2_Last' },
    'fwd_kwh_tariff_3': { 'key': 'kWh_Tariff_3~Rev_kWh_Tariff_3', 'value': 'kWh_Tariff_3_Last~Rev_kWh_Tariff_3_Last' },
    'fwd_kwh_tariff_4': { 'key': 'kWh_Tariff_4~Rev_kWh_Tariff_4', 'value': 'kWh_Tariff_4_Last~Rev_kWh_Tariff_4_Last' },
    'net_kwh_tot': { 'key': 'kWh_Tot~Rev_kWh_Tot', 'value': 'kWh_Tot_Last~Rev_kWh_Tot_Last' },
    'net_kwh_tariff_1': { 'key': 'kWh_Tariff_1~Rev_kWh_Tariff_1', 'value': 'kWh_Tariff_1_Last~Rev_kWh_Tariff_1_Last' },
    'net_kwh_tariff_2': { 'key': 'kWh_Tariff_2~Rev_kWh_Tariff_2', 'value': 'kWh_Tariff_2_Last~Rev_kWh_Tariff_2_Last' },
    'net_kwh_tariff_3': { 'key': 'kWh_Tariff_3~Rev_kWh_Tariff_3', 'value': 'kWh_Tariff_3_Last~Rev_kWh_Tariff_3_Last' },
    'net_kwh_tariff_4': { 'key': 'kWh_Tariff_4~Rev_kWh_Tariff_4', 'value': 'kWh_Tariff_4_Last~Rev_kWh_Tariff_4_Last' },
    'rms_volts_ln_1': { 'key': 'RMS_Volts_Ln_1', 'value': 'RMS_Volts_Ln_1_Last' },
    'rms_volts_ln_2': { 'key': 'RMS_Volts_Ln_2', 'value': 'RMS_Volts_Ln_2_Last' },
    'rms_volts_ln_3': { 'key': 'RMS_Volts_Ln_3', 'value': 'RMS_Volts_Ln_3_Last' },
    'amps_ln_1': { 'key': 'Amps_Ln_1', 'value': 'Amps_Ln_1_Last' },
    'amps_ln_2': { 'key': 'Amps_Ln_2', 'value': 'Amps_Ln_2_Last' },
    'amps_ln_3': { 'key': 'Amps_Ln_3', 'value': 'Amps_Ln_3_Last' },
    'rms_watts_ln_1': { 'key': 'RMS_Watts_Ln_1', 'value': 'RMS_Watts_Ln_1_Last' },
    'rms_watts_ln_2': { 'key': 'RMS_Watts_Ln_2', 'value': 'RMS_Watts_Ln_2_Last' },
    'rms_watts_ln_3': { 'key': 'RMS_Watts_Ln_3', 'value': 'RMS_Watts_Ln_3_Last' },
    'rms_watts_tot': { 'key': 'RMS_Watts_Tot', 'value': 'RMS_Watts_Tot_Last' },
    'power_factor_ln_1': { 'key': 'Power_Factor_Ln_1', 'value': 'Power_Factor_Ln_1_Last' },
    'power_factor_ln_2': { 'key': 'Power_Factor_Ln_2', 'value': 'Power_Factor_Ln_2_Last' },
    'power_factor_ln_3': { 'key': 'Power_Factor_Ln_3', 'value': 'Power_Factor_Ln_3_Last' },
    'rms_watts_max_demand': { 'key': 'RMS_Watts_Max_Demand', 'value': 'RMS_Watts_Max_Demand_Last' },
    'max_demand_period': { 'key': 'Max_Demand_Period', 'value': 'NA' },
    'ct_ratio': { 'key': 'CT_Ratio', 'value': 'CT_Ratio_Last' },
    'pc_raw_1': { 'key': 'Pulse_Cnt_1', 'value': 'Pulse_Cnt_1_Last' },
    'pc_raw_2': { 'key': 'Pulse_Cnt_2', 'value': 'Pulse_Cnt_2_Last' },
    'pc_raw_3': { 'key': 'Pulse_Cnt_3', 'value': 'Pulse_Cnt_3_Last' },
    'pulse_cnt_1': { 'key': 'Pulse_Cnt_1~Pulse_Ratio_1', 'value': 'Pulse_Cnt_1_Last~Pulse_Ratio_1_Last' },
    'pulse_cnt_2': { 'key': 'Pulse_Cnt_2~Pulse_Ratio_2', 'value': 'Pulse_Cnt_2_Last~Pulse_Ratio_2_Last' },
    'pulse_cnt_3': { 'key': 'Pulse_Cnt_3~Pulse_Ratio_3', 'value': 'Pulse_Cnt_3_Last~Pulse_Ratio_3_Last' },
    'converted_pulse_cnt_1': { 'key': 'Pulse_Cnt_1~Pulse_Ratio_1', 'value': 'Pulse_Cnt_1_Last~Pulse_Ratio_1_Last' },
    'converted_pulse_cnt_2': { 'key': 'Pulse_Cnt_2~Pulse_Ratio_2', 'value': 'Pulse_Cnt_2_Last~Pulse_Ratio_2_Last' },
    'converted_pulse_cnt_3': { 'key': 'Pulse_Cnt_3~Pulse_Ratio_3', 'value': 'Pulse_Cnt_3_Last~Pulse_Ratio_3_Last' },
    'pulse_ratio_1': { 'key': 'Pulse_Ratio_1', 'value': 'Pulse_Ratio_1_Last' },
    'pulse_ratio_2': { 'key': 'Pulse_Ratio_2', 'value': 'Pulse_Ratio_2_Last' },
    'pulse_ratio_3': { 'key': 'Pulse_Ratio_3', 'value': 'Pulse_Ratio_3_Last' },
    'state_inputs_1': { 'key': 'State_Inputs', 'value': 'State_Inputs_Max' },
    'state_inputs_2': { 'key': 'State_Inputs', 'value': 'State_Inputs_Max' },
    'state_inputs_3': { 'key': 'State_Inputs', 'value': 'State_Inputs_Max' },
    'state_out_1': { 'key': 'State_Out', 'value': 'State_Out_Max' },
    'state_out_2': { 'key': 'State_Out', 'value': 'State_Out_Max' },
    'reactive_energy_tot': { 'key': 'Reactive_Energy_Tot', 'value': 'Reactive_Energy_Tot_Last' },
    'kwh_rst': { 'key': 'kWh_Rst', 'value': 'kWh_Rst_Max' },
    'rev_kwh_rst': { 'key': 'Rev_kWh_Rst', 'value': 'Rev_kWh_Rst_Max' },
    'reactive_pwr_ln_1': { 'key': 'Reactive_Pwr_Ln_1', 'value': 'Reactive_Pwr_Ln_1_Last' },
    'reactive_pwr_ln_2': { 'key': 'Reactive_Pwr_Ln_2', 'value': 'Reactive_Pwr_Ln_2_Last' },
    'reactive_pwr_ln_3': { 'key': 'Reactive_Pwr_Ln_3', 'value': 'Reactive_Pwr_Ln_3_Last' },
    'reactive_pwr_tot': { 'key': 'Reactive_Pwr_Tot', 'value': 'Reactive_Pwr_Tot_Last' },
    'kwh_scale': { 'key': 'kWh_Scale', 'value': 'NA' },
    'line_freq': { 'key': 'Line_Freq', 'value': 'Line_Freq_Last' },
    'state_watts_dir': { 'key': 'State_Watts_Dir', 'value': 'NA' },
    'kwh_ln_1': { 'key': 'kWh_Ln_1', 'value': 'kWh_Ln_1_Max' },
    'kwh_ln_2': { 'key': 'kWh_Ln_2', 'value': 'kWh_Ln_2_Max' },
    'kwh_ln_3': { 'key': 'kWh_Ln_3', 'value': 'kWh_Ln_3_Max' },
    'rev_kwh_ln_1': { 'key': 'Rev_kWh_Ln_1', 'value': 'Rev_kWh_Ln_1_Max' },
    'rev_kwh_ln_2': { 'key': 'Rev_kWh_Ln_2', 'value': 'Rev_kWh_Ln_2_Max' },
    'rev_kwh_ln_3': { 'key': 'Rev_kWh_Ln_3', 'value': 'Rev_kWh_Ln_3_Max' },
    'max_demand_rst': { 'key': 'Max_Demand_Rst', 'value': 'Max_Demand_Rst_Last' },
    'cf_ratio': { 'key': 'CF_Ratio', 'value': 'CF_Ratio_Last' },
    'meter_status_code': { 'key': 'Meter_Status_Code', 'value': 'NA' },
    'net_calc_watts_ln_1': { 'key': 'Net_Calc_Watts_Ln_1', 'value': 'Net_Calc_Watts_Ln_1_Last' },
    'net_calc_watts_ln_2': { 'key': 'Net_Calc_Watts_Ln_2', 'value': 'Net_Calc_Watts_Ln_2_Last' },
    'net_calc_watts_ln_3': { 'key': 'Net_Calc_Watts_Ln_3', 'value': 'Net_Calc_Watts_Ln_3_Last' },
    'net_calc_watts_tot': { 'key': 'Net_Calc_Watts_Tot', 'value': 'Net_Calc_Watts_Tot_Last' }
}

_ALL_SUMMARY_FIELDS_MAX_IOSTACK = {
    'analog_in_1': {'key': 'Analog_In_1', 'value': 'Analog_In_1_Max'},
    'analog_in_2': {'key': 'Analog_In_2', 'value': 'Analog_In_2_Max'},
    'analog_in_3': {'key': 'Analog_In_3', 'value': 'Analog_In_3_Max'},
    'analog_in_4': {'key': 'Analog_In_4', 'value': 'Analog_In_4_Max'},
    'converted_analog_in_1' : {'key': 'Analog_In_1', 'value': 'Analog_In_1_Max'},
    'converted_analog_in_2': {'key': 'Analog_In_2', 'value': 'Analog_In_2_Max'},
    'converted_analog_in_3': {'key': 'Analog_In_3', 'value': 'Analog_In_3_Max'},
    'converted_analog_in_4': {'key': 'Analog_In_4', 'value': 'Analog_In_4_Max'},
    'state_inputs_1': {'key': 'State_Inputs', 'value': 'State_Inputs_Max'},
    'state_inputs_2': {'key': 'State_Inputs', 'value': 'State_Inputs_Max'},
    'state_inputs_3': {'key': 'State_Inputs', 'value': 'State_Inputs_Max'},
    'state_inputs_4': {'key': 'State_Inputs', 'value': 'State_Inputs_Max'},
    'state_out_1': {'key': 'State_Out', 'value': 'State_Out_Max'},
    'state_out_2': {'key': 'State_Out', 'value': 'State_Out_Max'},
    'state_out_3': {'key': 'State_Out', 'value': 'State_Out_Max'},
    'state_out_4': {'key': 'State_Out', 'value': 'State_Out_Max'},
    'pulse_cnt_1': {'key': 'Pulse_Cnt_1', 'value': 'Pulse_Cnt_1_Last'},
    'pulse_cnt_2': {'key': 'Pulse_Cnt_2', 'value': 'Pulse_Cnt_2_Last'},
    'pulse_cnt_3': {'key': 'Pulse_Cnt_3', 'value': 'Pulse_Cnt_3_Last'},
    'pulse_cnt_4': {'key': 'Pulse_Cnt_4', 'value': 'Pulse_Cnt_4_Last'},
    'converted_io_pulse_cnt_1': {'key': 'Pulse_Cnt_1', 'value': 'Pulse_Cnt_1_Last'},
    'converted_io_pulse_cnt_2': {'key': 'Pulse_Cnt_2', 'value': 'Pulse_Cnt_2_Last'},
    'converted_io_pulse_cnt_3': {'key': 'Pulse_Cnt_3', 'value': 'Pulse_Cnt_3_Last'},
    'converted_io_pulse_cnt_4': {'key': 'Pulse_Cnt_4', 'value': 'Pulse_Cnt_4_Last'},
    'pulse_cnt_rst_1': {'key': 'Pulse_Cnt_Rst_1', 'value': 'Pulse_Cnt_Rst_1_Max'},
    'pulse_cnt_rst_2': {'key': 'Pulse_Cnt_Rst_2', 'value': 'Pulse_Cnt_Rst_2_Max'},
    'pulse_cnt_rst_3': {'key': 'Pulse_Cnt_Rst_3', 'value': 'Pulse_Cnt_Rst_3_Max'},
    'pulse_cnt_rst_4': {'key': 'Pulse_Cnt_Rst_4', 'value': 'Pulse_Cnt_Rst_4_Max'},
    'pulse_hold_ms_1': {'key': 'Pulse_Hold_ms_1', 'value': 'Pulse_Hold_ms_1_Max'},
    'pulse_hold_ms_2': {'key': 'Pulse_Hold_ms_2', 'value': 'Pulse_Hold_ms_2_Max'},
    'pulse_hold_ms_3': {'key': 'Pulse_Hold_ms_3', 'value': 'Pulse_Hold_ms_3_Max'},
    'pulse_hold_ms_4': {'key': 'Pulse_Hold_ms_4', 'value': 'Pulse_Hold_ms_4_Max'},
    'pulse_hi_prev_ms_1': {'key': 'Pulse_Hi_Prev_ms_1', 'value': 'Pulse_Hi_Prev_ms_1_Max'},
    'pulse_hi_prev_ms_2': {'key': 'Pulse_Hi_Prev_ms_2', 'value': 'Pulse_Hi_Prev_ms_2_Max'},
    'pulse_hi_prev_ms_3': {'key': 'Pulse_Hi_Prev_ms_3', 'value': 'Pulse_Hi_Prev_ms_3_Max'},
    'pulse_hi_prev_ms_4': {'key': 'Pulse_Hi_Prev_ms_4', 'value': 'Pulse_Hi_Prev_ms_4_Max'},
    'pulse_lo_prev_ms_1': {'key': 'Pulse_Lo_Prev_ms_1', 'value': 'Pulse_Lo_Prev_ms_1_Max'},
    'pulse_lo_prev_ms_2': {'key': 'Pulse_Lo_Prev_ms_2', 'value': 'Pulse_Lo_Prev_ms_2_Max'},
    'pulse_lo_prev_ms_3': {'key': 'Pulse_Lo_Prev_ms_3', 'value': 'Pulse_Lo_Prev_ms_3_Max'},
    'pulse_lo_prev_ms_4': {'key': 'Pulse_Lo_Prev_ms_4', 'value': 'Pulse_Lo_Prev_ms_4_Max'},
    'pulse_hi_total_sec_1': {'key': 'Pulse_Hi_Total_sec_1', 'value': 'Pulse_Hi_Total_sec_1_Max'},
    'pulse_hi_total_sec_2': {'key': 'Pulse_Hi_Total_sec_2', 'value': 'Pulse_Hi_Total_sec_2_Max'},
    'pulse_hi_total_sec_3': {'key': 'Pulse_Hi_Total_sec_3', 'value': 'Pulse_Hi_Total_sec_3_Max'},
    'pulse_hi_total_sec_4': {'key': 'Pulse_Hi_Total_sec_4', 'value': 'Pulse_Hi_Total_sec_4_Max'},
    'pulse_lo_total_sec_1': {'key': 'Pulse_Lo_Total_sec_1', 'value': 'Pulse_Lo_Total_sec_1_Max'},
    'pulse_lo_total_sec_2': {'key': 'Pulse_Lo_Total_sec_2', 'value': 'Pulse_Lo_Total_sec_2_Max'},
    'pulse_lo_total_sec_3': {'key': 'Pulse_Lo_Total_sec_3', 'value': 'Pulse_Lo_Total_sec_3_Max'},
    'pulse_lo_total_sec_4': {'key': 'Pulse_Lo_Total_sec_4', 'value': 'Pulse_Lo_Total_sec_4_Max'},
    'ow_1_1_degc': {'key': 'OW_1_1_degC', 'value': 'OW_1_1_degC_Max'},
    'ow_1_2_degc': {'key': 'OW_1_2_degC', 'value': 'OW_1_2_degC_Max'},
    'ow_1_3_degc': {'key': 'OW_1_3_degC', 'value': 'OW_1_3_degC_Max'},
    'ow_1_4_degc': {'key': 'OW_1_4_degC', 'value': 'OW_1_4_degC_Max'},
    'ow_2_1_degc': {'key': 'OW_2_1_degC', 'value': 'OW_2_1_degC_Max'},
    'ow_2_2_degc': {'key': 'OW_2_2_degC', 'value': 'OW_2_2_degC_Max'},
    'ow_2_3_degc': {'key': 'OW_2_3_degC', 'value': 'OW_2_3_degC_Max'},
    'ow_2_4_degc': {'key': 'OW_2_4_degC', 'value': 'OW_2_4_degC_Max'},
    'ow_3_1_degc': {'key': 'OW_3_1_degC', 'value': 'OW_3_1_degC_Max'},
    'ow_3_2_degc': {'key': 'OW_3_2_degC', 'value': 'OW_3_2_degC_Max'},
    'ow_3_3_degc': {'key': 'OW_3_3_degC', 'value': 'OW_3_3_degC_Max'},
    'ow_3_4_degc': {'key': 'OW_3_4_degC', 'value': 'OW_3_4_degC_Max'},
    'ow_4_1_degc': {'key': 'OW_4_1_degC', 'value': 'OW_4_1_degC_Max'},
    'ow_4_2_degc': {'key': 'OW_4_2_degC', 'value': 'OW_4_2_degC_Max'},
    'ow_4_3_degc': {'key': 'OW_4_3_degC', 'value': 'OW_4_3_degC_Max'},
    'ow_4_4_degc': {'key': 'OW_4_4_degC', 'value': 'OW_4_4_degC_Max'},

    'ow_1_1_humidity': {'key': 'OW_1_1_Humidity', 'value': 'OW_1_1_Humidity_Max'},
    'ow_1_2_humidity': {'key': 'OW_1_2_Humidity', 'value': 'OW_1_2_Humidity_Max'},
    'ow_1_3_humidity': {'key': 'OW_1_3_Humidity', 'value': 'OW_1_3_Humidity_Max'},
    'ow_1_4_humidity': {'key': 'OW_1_4_Humidity', 'value': 'OW_1_4_Humidity_Max'},
    'ow_2_1_humidity': {'key': 'OW_2_1_Humidity', 'value': 'OW_2_1_Humidity_Max'},
    'ow_2_2_humidity': {'key': 'OW_2_2_Humidity', 'value': 'OW_2_2_Humidity_Max'},
    'ow_2_3_humidity': {'key': 'OW_2_3_Humidity', 'value': 'OW_2_3_Humidity_Max'},
    'ow_2_4_humidity': {'key': 'OW_2_4_Humidity', 'value': 'OW_2_4_Humidity_Max'},
    'ow_3_1_humidity': {'key': 'OW_3_1_Humidity', 'value': 'OW_3_1_Humidity_Max'},
    'ow_3_2_humidity': {'key': 'OW_3_2_Humidity', 'value': 'OW_3_2_Humidity_Max'},
    'ow_3_3_humidity': {'key': 'OW_3_3_Humidity', 'value': 'OW_3_3_Humidity_Max'},
    'ow_3_4_humidity': {'key': 'OW_3_4_Humidity', 'value': 'OW_3_4_Humidity_Max'},
    'ow_4_1_humidity': {'key': 'OW_4_1_Humidity', 'value': 'OW_4_1_Humidity_Max'},
    'ow_4_2_humidity': {'key': 'OW_4_2_Humidity', 'value': 'OW_4_2_Humidity_Max'},
    'ow_4_3_humidity': {'key': 'OW_4_3_Humidity', 'value': 'OW_4_3_Humidity_Max'},
    'ow_4_4_humidity': {'key': 'OW_4_4_Humidity', 'value': 'OW_4_4_Humidity_Max'},
    'ow_1_1_lux': {'key': 'OW_1_1_Lux', 'value': 'OW_1_1_Lux_Max'},
    'ow_1_2_lux': {'key': 'OW_1_2_Lux', 'value': 'OW_1_2_Lux_Max'},
    'ow_1_3_lux': {'key': 'OW_1_3_Lux', 'value': 'OW_1_3_Lux_Max'},
    'ow_1_4_lux': {'key': 'OW_1_4_Lux', 'value': 'OW_1_4_Lux_Max'},
    'ow_2_1_lux': {'key': 'OW_2_1_Lux', 'value': 'OW_2_1_Lux_Max'},
    'ow_2_2_lux': {'key': 'OW_2_2_Lux', 'value': 'OW_2_2_Lux_Max'},
    'ow_2_3_lux': {'key': 'OW_2_3_Lux', 'value': 'OW_2_3_Lux_Max'},
    'ow_2_4_lux': {'key': 'OW_2_4_Lux', 'value': 'OW_2_4_Lux_Max'},
    'ow_3_1_lux': {'key': 'OW_3_1_Lux', 'value': 'OW_3_1_Lux_Max'},
    'ow_3_2_lux': {'key': 'OW_3_2_Lux', 'value': 'OW_3_2_Lux_Max'},
    'ow_3_3_lux': {'key': 'OW_3_3_Lux', 'value': 'OW_3_3_Lux_Max'},
    'ow_3_4_lux': {'key': 'OW_3_4_Lux', 'value': 'OW_3_4_Lux_Max'},
    'ow_4_1_lux': {'key': 'OW_4_1_Lux', 'value': 'OW_4_1_Lux_Max'},
    'ow_4_2_lux': {'key': 'OW_4_2_Lux', 'value': 'OW_4_2_Lux_Max'},
    'ow_4_3_lux': {'key': 'OW_4_3_Lux', 'value': 'OW_4_3_Lux_Max'},
    'ow_4_4_lux': {'key': 'OW_4_4_Lux', 'value': 'OW_4_4_Lux_Max'},
    'converted_ow_1_1_degc': {'key': 'OW_1_1_degC', 'value': 'OW_1_1_degC_Max'},
    'converted_ow_1_2_degc': {'key': 'OW_1_2_degC', 'value': 'OW_1_2_degC_Max'},
    'converted_ow_1_3_degc': {'key': 'OW_1_3_degC', 'value': 'OW_1_3_degC_Max'},
    'converted_ow_1_4_degc': {'key': 'OW_1_4_degC', 'value': 'OW_1_4_degC_Max'},
    'converted_ow_2_1_degc': {'key': 'OW_2_1_degC', 'value': 'OW_2_1_degC_Max'},
    'converted_ow_2_2_degc': {'key': 'OW_2_2_degC', 'value': 'OW_2_2_degC_Max'},
    'converted_ow_2_3_degc': {'key': 'OW_2_3_degC', 'value': 'OW_2_3_degC_Max'},
    'converted_ow_2_4_degc': {'key': 'OW_2_4_degC', 'value': 'OW_2_4_degC_Max'},
    'converted_ow_3_1_degc': {'key': 'OW_3_1_degC', 'value': 'OW_3_1_degC_Max'},
    'converted_ow_3_2_degc': {'key': 'OW_3_2_degC', 'value': 'OW_3_2_degC_Max'},
    'converted_ow_3_3_degc': {'key': 'OW_3_3_degC', 'value': 'OW_3_3_degC_Max'},
    'converted_ow_3_4_degc': {'key': 'OW_3_4_degC', 'value': 'OW_3_4_degC_Max'},
    'converted_ow_4_1_degc': {'key': 'OW_4_1_degC', 'value': 'OW_4_1_degC_Max'},
    'converted_ow_4_2_degc': {'key': 'OW_4_2_degC', 'value': 'OW_4_2_degC_Max'},
    'converted_ow_4_3_degc': {'key': 'OW_4_3_degC', 'value': 'OW_4_3_degC_Max'},
    'converted_ow_4_4_degc': {'key': 'OW_4_4_degC', 'value': 'OW_4_4_degC_Max'},
    'converted_ow_1_1_humidity': {'key': 'OW_1_1_Humidity', 'value': 'OW_1_1_Humidity_Max'},
    'converted_ow_1_2_humidity': {'key': 'OW_1_2_Humidity', 'value': 'OW_1_2_Humidity_Max'},
    'converted_ow_1_3_humidity': {'key': 'OW_1_3_Humidity', 'value': 'OW_1_3_Humidity_Max'},
    'converted_ow_1_4_humidity': {'key': 'OW_1_4_Humidity', 'value': 'OW_1_4_Humidity_Max'},
    'converted_ow_2_1_humidity': {'key': 'OW_2_1_Humidity', 'value': 'OW_2_1_Humidity_Max'},
    'converted_ow_2_2_humidity': {'key': 'OW_2_2_Humidity', 'value': 'OW_2_2_Humidity_Max'},
    'converted_ow_2_3_humidity': {'key': 'OW_2_3_Humidity', 'value': 'OW_2_3_Humidity_Max'},
    'converted_ow_2_4_humidity': {'key': 'OW_2_4_Humidity', 'value': 'OW_2_4_Humidity_Max'},
    'converted_ow_3_1_humidity': {'key': 'OW_3_1_Humidity', 'value': 'OW_3_1_Humidity_Max'},
    'converted_ow_3_2_humidity': {'key': 'OW_3_2_Humidity', 'value': 'OW_3_2_Humidity_Max'},
    'converted_ow_3_3_humidity': {'key': 'OW_3_3_Humidity', 'value': 'OW_3_3_Humidity_Max'},
    'converted_ow_3_4_humidity': {'key': 'OW_3_4_Humidity', 'value': 'OW_3_4_Humidity_Max'},
    'converted_ow_4_1_humidity': {'key': 'OW_4_1_Humidity', 'value': 'OW_4_1_Humidity_Max'},
    'converted_ow_4_2_humidity': {'key': 'OW_4_2_Humidity', 'value': 'OW_4_2_Humidity_Max'},
    'converted_ow_4_3_humidity': {'key': 'OW_4_3_Humidity', 'value': 'OW_4_3_Humidity_Max'},
    'converted_ow_4_4_humidity': {'key': 'OW_4_4_Humidity', 'value': 'OW_4_4_Humidity_Max'},
    'converted_ow_1_1_lux': {'key': 'OW_1_1_Lux', 'value': 'OW_1_1_Lux_Max'},
    'converted_ow_1_2_lux': {'key': 'OW_1_2_Lux', 'value': 'OW_1_2_Lux_Max'},
    'converted_ow_1_3_lux': {'key': 'OW_1_3_Lux', 'value': 'OW_1_3_Lux_Max'},
    'converted_ow_1_4_lux': {'key': 'OW_1_4_Lux', 'value': 'OW_1_4_Lux_Max'},
    'converted_ow_2_1_lux': {'key': 'OW_2_1_Lux', 'value': 'OW_2_1_Lux_Max'},
    'converted_ow_2_2_lux': {'key': 'OW_2_2_Lux', 'value': 'OW_2_2_Lux_Max'},
    'converted_ow_2_3_lux': {'key': 'OW_2_3_Lux', 'value': 'OW_2_3_Lux_Max'},
    'converted_ow_2_4_lux': {'key': 'OW_2_4_Lux', 'value': 'OW_2_4_Lux_Max'},
    'converted_ow_3_1_lux': {'key': 'OW_3_1_Lux', 'value': 'OW_3_1_Lux_Max'},
    'converted_ow_3_2_lux': {'key': 'OW_3_2_Lux', 'value': 'OW_3_2_Lux_Max'},
    'converted_ow_3_3_lux': {'key': 'OW_3_3_Lux', 'value': 'OW_3_3_Lux_Max'},
    'converted_ow_3_4_lux': {'key': 'OW_3_4_Lux', 'value': 'OW_3_4_Lux_Max'},
    'converted_ow_4_1_lux': {'key': 'OW_4_1_Lux', 'value': 'OW_4_1_Lux_Max'},
    'converted_ow_4_2_lux': {'key': 'OW_4_2_Lux', 'value': 'OW_4_2_Lux_Max'},
    'converted_ow_4_3_lux': {'key': 'OW_4_3_Lux', 'value': 'OW_4_3_Lux_Max'},
    'converted_ow_4_4_lux': {'key': 'OW_4_4_Lux', 'value': 'OW_4_4_Lux_Max'},
}

_ALL_SUMMARY_FIELDS_IOSTACK = {
    'analog_in_1' : {'key': 'Analog_In_1', 'value': 'Analog_In_1'},
    'analog_in_2': {'key': 'Analog_In_2', 'value': 'Analog_In_2'},
    'analog_in_3': {'key': 'Analog_In_3', 'value': 'Analog_In_3'},
    'analog_in_4': {'key': 'Analog_In_4', 'value': 'Analog_In_4'},
    'converted_analog_in_1': {'key': 'converted_analog_in_1', 'value': 'converted_analog_in_1'},
    'converted_analog_in_2': {'key': 'converted_analog_in_2', 'value': 'converted_analog_in_2'},
    'converted_analog_in_3': {'key': 'converted_analog_in_3', 'value': 'converted_analog_in_3'},
    'converted_analog_in_4': {'key': 'converted_analog_in_4', 'value': 'converted_analog_in_4'},
    'state_inputs_1': {'key': 'State_Inputs', 'value': 'State_Inputs'},
    'state_inputs_2': {'key': 'State_Inputs', 'value': 'State_Inputs'},
    'state_inputs_3': {'key': 'State_Inputs', 'value': 'State_Inputs'},
    'state_inputs_4': {'key': 'State_Inputs', 'value': 'State_Inputs'},
    'state_out_1': {'key': 'State_Out', 'value': 'State_Out'},
    'state_out_2': {'key': 'State_Out', 'value': 'State_Out'},
    'state_out_3': {'key': 'State_Out', 'value': 'State_Out'},
    'state_out_4': {'key': 'State_Out', 'value': 'State_Out'},
    'pulse_cnt_1': {'key': 'Pulse_Cnt_1', 'value': 'Pulse_Cnt_1'},
    'pulse_cnt_2': {'key': 'Pulse_Cnt_2', 'value': 'Pulse_Cnt_2'},
    'pulse_cnt_3': {'key': 'Pulse_Cnt_3', 'value': 'Pulse_Cnt_3'},
    'pulse_cnt_4': {'key': 'Pulse_Cnt_4', 'value': 'Pulse_Cnt_4'},
    'converted_io_pulse_cnt_1': {'key': 'converted_io_pulse_cnt_1', 'value': 'converted_io_pulse_cnt_1'},
    'converted_io_pulse_cnt_2': {'key': 'converted_io_pulse_cnt_2', 'value': 'converted_io_pulse_cnt_2'},
    'converted_io_pulse_cnt_3': {'key': 'converted_io_pulse_cnt_3', 'value': 'converted_io_pulse_cnt_3'},
    'converted_io_pulse_cnt_4': {'key': 'converted_io_pulse_cnt_4', 'value': 'converted_io_pulse_cnt_4'},
    'pulse_cnt_rst_1': {'key': 'Pulse_Cnt_Rst_1', 'value': 'Pulse_Cnt_Rst_1'},
    'pulse_cnt_rst_2': {'key': 'Pulse_Cnt_Rst_2', 'value': 'Pulse_Cnt_Rst_2'},
    'pulse_cnt_rst_3': {'key': 'Pulse_Cnt_Rst_3', 'value': 'Pulse_Cnt_Rst_3'},
    'pulse_cnt_rst_4': {'key': 'Pulse_Cnt_Rst_4', 'value': 'Pulse_Cnt_Rst_4'},
    'pulse_hold_ms_1': {'key': 'Pulse_Hold_ms_1', 'value': 'Pulse_Hold_ms_1'},
    'pulse_hold_ms_2': {'key': 'Pulse_Hold_ms_2', 'value': 'Pulse_Hold_ms_2'},
    'pulse_hold_ms_3': {'key': 'Pulse_Hold_ms_3', 'value': 'Pulse_Hold_ms_3'},
    'pulse_hold_ms_4': {'key': 'Pulse_Hold_ms_4', 'value': 'Pulse_Hold_ms_4'},
    'pulse_hi_prev_ms_1': {'key': 'Pulse_Hi_Prev_ms_1', 'value': 'Pulse_Hi_Prev_ms_1'},
    'pulse_hi_prev_ms_2': {'key': 'Pulse_Hi_Prev_ms_2', 'value': 'Pulse_Hi_Prev_ms_2'},
    'pulse_hi_prev_ms_3': {'key': 'Pulse_Hi_Prev_ms_3', 'value': 'Pulse_Hi_Prev_ms_3'},
    'pulse_hi_prev_ms_4': {'key': 'Pulse_Hi_Prev_ms_4', 'value': 'Pulse_Hi_Prev_ms_4'},
    'pulse_lo_prev_ms_1': {'key': 'Pulse_Lo_Prev_ms_1', 'value': 'Pulse_Lo_Prev_ms_1'},
    'pulse_lo_prev_ms_2': {'key': 'Pulse_Lo_Prev_ms_2', 'value': 'Pulse_Lo_Prev_ms_2'},
    'pulse_lo_prev_ms_3': {'key': 'Pulse_Lo_Prev_ms_3', 'value': 'Pulse_Lo_Prev_ms_3'},
    'pulse_lo_prev_ms_4': {'key': 'Pulse_Lo_Prev_ms_4', 'value': 'Pulse_Lo_Prev_ms_4'},
    'pulse_hi_total_sec_1': {'key': 'Pulse_Hi_Total_sec_1', 'value': 'Pulse_Hi_Total_sec_1'},
    'pulse_hi_total_sec_2': {'key': 'Pulse_Hi_Total_sec_2', 'value': 'Pulse_Hi_Total_sec_2'},
    'pulse_hi_total_sec_3': {'key': 'Pulse_Hi_Total_sec_3', 'value': 'Pulse_Hi_Total_sec_3'},
    'pulse_hi_total_sec_4': {'key': 'Pulse_Hi_Total_sec_4', 'value': 'Pulse_Hi_Total_sec_4'},
    'pulse_lo_total_sec_1': {'key': 'Pulse_Lo_Total_sec_1', 'value': 'Pulse_Lo_Total_sec_1'},
    'pulse_lo_total_sec_2': {'key': 'Pulse_Lo_Total_sec_2', 'value': 'Pulse_Lo_Total_sec_2'},
    'pulse_lo_total_sec_3': {'key': 'Pulse_Lo_Total_sec_3', 'value': 'Pulse_Lo_Total_sec_3'},
    'pulse_lo_total_sec_4': {'key': 'Pulse_Lo_Total_sec_4', 'value': 'Pulse_Lo_Total_sec_4'},
    'ow_1_1_degc': {'key': 'OW_1_1_degC', 'value': 'OW_1_1_degC'},
    'ow_1_2_degc': {'key': 'OW_1_2_degC', 'value': 'OW_1_2_degC'},
    'ow_1_3_degc': {'key': 'OW_1_3_degC', 'value': 'OW_1_3_degC'},
    'ow_1_4_degc': {'key': 'OW_1_4_degC', 'value': 'OW_1_4_degC'},
    'ow_2_1_degc': {'key': 'OW_2_1_degC', 'value': 'OW_2_1_degC'},
    'ow_2_2_degc': {'key': 'OW_2_2_degC', 'value': 'OW_2_2_degC'},
    'ow_2_3_degc': {'key': 'OW_2_3_degC', 'value': 'OW_2_3_degC'},
    'ow_2_4_degc': {'key': 'OW_2_4_degC', 'value': 'OW_2_4_degC'},
    'ow_3_1_degc': {'key': 'OW_3_1_degC', 'value': 'OW_3_1_degC'},
    'ow_3_2_degc': {'key': 'OW_3_2_degC', 'value': 'OW_3_2_degC'},
    'ow_3_3_degc': {'key': 'OW_3_3_degC', 'value': 'OW_3_3_degC'},
    'ow_3_4_degc': {'key': 'OW_3_4_degC', 'value': 'OW_3_4_degC'},
    'ow_4_1_degc': {'key': 'OW_4_1_degC', 'value': 'OW_4_1_degC'},
    'ow_4_2_degc': {'key': 'OW_4_2_degC', 'value': 'OW_4_2_degC'},
    'ow_4_3_degc': {'key': 'OW_4_3_degC', 'value': 'OW_4_3_degC'},
    'ow_4_4_degc': {'key': 'OW_4_4_degC', 'value': 'OW_4_4_degC'},
    'ow_1_1_humidity': {'key': 'OW_1_1_Humidity', 'value': 'OW_1_1_Humidity'},
    'ow_1_2_humidity': {'key': 'OW_1_2_Humidity', 'value': 'OW_1_2_Humidity'},
    'ow_1_3_humidity': {'key': 'OW_1_3_Humidity', 'value': 'OW_1_3_Humidity'},
    'ow_1_4_humidity': {'key': 'OW_1_4_Humidity', 'value': 'OW_1_4_Humidity'},
    'ow_2_1_humidity': {'key': 'OW_2_1_Humidity', 'value': 'OW_2_1_Humidity'},
    'ow_2_2_humidity': {'key': 'OW_2_2_Humidity', 'value': 'OW_2_2_Humidity'},
    'ow_2_3_humidity': {'key': 'OW_2_3_Humidity', 'value': 'OW_2_3_Humidity'},
    'ow_2_4_humidity': {'key': 'OW_2_4_Humidity', 'value': 'OW_2_4_Humidity'},
    'ow_3_1_humidity': {'key': 'OW_3_1_Humidity', 'value': 'OW_3_1_Humidity'},
    'ow_3_2_humidity': {'key': 'OW_3_2_Humidity', 'value': 'OW_3_2_Humidity'},
    'ow_3_3_humidity': {'key': 'OW_3_3_Humidity', 'value': 'OW_3_3_Humidity'},
    'ow_3_4_humidity': {'key': 'OW_3_4_Humidity', 'value': 'OW_3_4_Humidity'},
    'ow_4_1_humidity': {'key': 'OW_4_1_Humidity', 'value': 'OW_4_1_Humidity'},
    'ow_4_2_humidity': {'key': 'OW_4_2_Humidity', 'value': 'OW_4_2_Humidity'},
    'ow_4_3_humidity': {'key': 'OW_4_3_Humidity', 'value': 'OW_4_3_Humidity'},
    'ow_4_4_humidity': {'key': 'OW_4_4_Humidity', 'value': 'OW_4_4_Humidity'},
    'ow_1_1_lux': {'key': 'OW_1_1_Lux', 'value': 'OW_1_1_Lux'},
    'ow_1_2_lux': {'key': 'OW_1_2_Lux', 'value': 'OW_1_2_Lux'},
    'ow_1_3_lux': {'key': 'OW_1_3_Lux', 'value': 'OW_1_3_Lux'},
    'ow_1_4_lux': {'key': 'OW_1_4_Lux', 'value': 'OW_1_4_Lux'},
    'ow_2_1_lux': {'key': 'OW_2_1_Lux', 'value': 'OW_2_1_Lux'},
    'ow_2_2_lux': {'key': 'OW_2_2_Lux', 'value': 'OW_2_2_Lux'},
    'ow_2_3_lux': {'key': 'OW_2_3_Lux', 'value': 'OW_2_3_Lux'},
    'ow_2_4_lux': {'key': 'OW_2_4_Lux', 'value': 'OW_2_4_Lux'},
    'ow_3_1_lux': {'key': 'OW_3_1_Lux', 'value': 'OW_3_1_Lux'},
    'ow_3_2_lux': {'key': 'OW_3_2_Lux', 'value': 'OW_3_2_Lux'},
    'ow_3_3_lux': {'key': 'OW_3_3_Lux', 'value': 'OW_3_3_Lux'},
    'ow_3_4_lux': {'key': 'OW_3_4_Lux', 'value': 'OW_3_4_Lux'},
    'ow_4_1_lux': {'key': 'OW_4_1_Lux', 'value': 'OW_4_1_Lux'},
    'ow_4_2_lux': {'key': 'OW_4_2_Lux', 'value': 'OW_4_2_Lux'},
    'ow_4_3_lux': {'key': 'OW_4_3_Lux', 'value': 'OW_4_3_Lux'},
    'ow_4_4_lux': {'key': 'OW_4_4_Lux', 'value': 'OW_4_4_Lux'},

    'converted_ow_1_1_degc': {'key': 'converted_ow_1_1_degc', 'value': 'converted_ow_1_1_degc'},
    'converted_ow_1_2_degc': {'key': 'converted_ow_1_2_degc', 'value': 'converted_ow_1_2_degc'},
    'converted_ow_1_3_degc': {'key': 'converted_ow_1_3_degc', 'value': 'converted_ow_1_3_degc'},
    'converted_ow_1_4_degc': {'key': 'converted_ow_1_4_degc', 'value': 'converted_ow_1_4_degc'},
    'converted_ow_2_1_degc': {'key': 'converted_ow_2_1_degc', 'value': 'converted_ow_2_1_degc'},
    'converted_ow_2_2_degc': {'key': 'converted_ow_2_2_degc', 'value': 'converted_ow_2_2_degc'},
    'converted_ow_2_3_degc': {'key': 'converted_ow_2_3_degc', 'value': 'converted_ow_2_3_degc'},
    'converted_ow_2_4_degc': {'key': 'converted_ow_2_4_degc', 'value': 'converted_ow_2_4_degc'},
    'converted_ow_3_1_degc': {'key': 'converted_ow_3_1_degc', 'value': 'converted_ow_3_1_degc'},
    'converted_ow_3_2_degc': {'key': 'converted_ow_3_2_degc', 'value': 'converted_ow_3_2_degc'},
    'converted_ow_3_3_degc': {'key': 'converted_ow_3_3_degc', 'value': 'converted_ow_3_3_degc'},
    'converted_ow_3_4_degc': {'key': 'converted_ow_3_4_degc', 'value': 'converted_ow_3_4_degc'},
    'converted_ow_4_1_degc': {'key': 'converted_ow_4_1_degc', 'value': 'converted_ow_4_1_degc'},
    'converted_ow_4_2_degc': {'key': 'converted_ow_4_2_degc', 'value': 'converted_ow_4_2_degc'},
    'converted_ow_4_3_degc': {'key': 'converted_ow_4_3_degc', 'value': 'converted_ow_4_3_degc'},
    'converted_ow_4_4_degc': {'key': 'converted_ow_4_4_degc', 'value': 'converted_ow_4_4_degc'},
    'converted_ow_1_1_humidity': {'key': 'converted_ow_1_1_humidity', 'value': 'converted_ow_1_1_humidity'},
    'converted_ow_1_2_humidity': {'key': 'converted_ow_1_2_humidity', 'value': 'converted_ow_1_2_humidity'},
    'converted_ow_1_3_humidity': {'key': 'converted_ow_1_3_humidity', 'value': 'converted_ow_1_3_humidity'},
    'converted_ow_1_4_humidity': {'key': 'converted_ow_1_4_humidity', 'value': 'converted_ow_1_4_humidity'},
    'converted_ow_2_1_humidity': {'key': 'converted_ow_2_1_humidity', 'value': 'converted_ow_2_1_humidity'},
    'converted_ow_2_2_humidity': {'key': 'converted_ow_2_2_humidity', 'value': 'converted_ow_2_2_humidity'},
    'converted_ow_2_3_humidity': {'key': 'converted_ow_2_3_humidity', 'value': 'converted_ow_2_3_humidity'},
    'converted_ow_2_4_humidity': {'key': 'converted_ow_2_4_humidity', 'value': 'converted_ow_2_4_humidity'},
    'converted_ow_3_1_humidity': {'key': 'converted_ow_3_1_humidity', 'value': 'converted_ow_3_1_humidity'},
    'converted_ow_3_2_humidity': {'key': 'converted_ow_3_2_humidity', 'value': 'converted_ow_3_2_humidity'},
    'converted_ow_3_3_humidity': {'key': 'converted_ow_3_3_humidity', 'value': 'converted_ow_3_3_humidity'},
    'converted_ow_3_4_humidity': {'key': 'converted_ow_3_4_humidity', 'value': 'converted_ow_3_4_humidity'},
    'converted_ow_4_1_humidity': {'key': 'converted_ow_4_1_humidity', 'value': 'converted_ow_4_1_humidity'},
    'converted_ow_4_2_humidity': {'key': 'converted_ow_4_2_humidity', 'value': 'converted_ow_4_2_humidity'},
    'converted_ow_4_3_humidity': {'key': 'converted_ow_4_3_humidity', 'value': 'converted_ow_4_3_humidity'},
    'converted_ow_4_4_humidity': {'key': 'converted_ow_4_4_humidity', 'value': 'converted_ow_4_4_humidity'},
    'converted_ow_1_1_lux': {'key': 'converted_ow_1_1_lux', 'value': 'converted_ow_1_1_lux'},
    'converted_ow_1_2_lux': {'key': 'converted_ow_1_2_lux', 'value': 'converted_ow_1_2_lux'},
    'converted_ow_1_3_lux': {'key': 'converted_ow_1_3_lux', 'value': 'converted_ow_1_3_lux'},
    'converted_ow_1_4_lux': {'key': 'converted_ow_1_4_lux', 'value': 'converted_ow_1_4_lux'},
    'converted_ow_2_1_lux': {'key': 'converted_ow_2_1_lux', 'value': 'converted_ow_2_1_lux'},
    'converted_ow_2_2_lux': {'key': 'converted_ow_2_2_lux', 'value': 'converted_ow_2_2_lux'},
    'converted_ow_2_3_lux': {'key': 'converted_ow_2_3_lux', 'value': 'converted_ow_2_3_lux'},
    'converted_ow_2_4_lux': {'key': 'converted_ow_2_4_lux', 'value': 'converted_ow_2_4_lux'},
    'converted_ow_3_1_lux': {'key': 'converted_ow_3_1_lux', 'value': 'converted_ow_3_1_lux'},
    'converted_ow_3_2_lux': {'key': 'converted_ow_3_2_lux', 'value': 'converted_ow_3_2_lux'},
    'converted_ow_3_3_lux': {'key': 'converted_ow_3_3_lux', 'value': 'converted_ow_3_3_lux'},
    'converted_ow_3_4_lux': {'key': 'converted_ow_3_4_lux', 'value': 'converted_ow_3_4_lux'},
    'converted_ow_4_1_lux': {'key': 'converted_ow_4_1_lux', 'value': 'converted_ow_4_1_lux'},
    'converted_ow_4_2_lux': {'key': 'converted_ow_4_2_lux', 'value': 'converted_ow_4_2_lux'},
    'converted_ow_4_3_lux': {'key': 'converted_ow_4_3_lux', 'value': 'converted_ow_4_3_lux'},
    'converted_ow_4_4_lux': {'key': 'converted_ow_4_4_lux', 'value': 'converted_ow_4_4_lux'},
}

var _BAR_GRAPH_FIELDS = {
    'rms_volts_ln_1_average': {'name': 'RMS Volts Line 1 Average', 'value': 'RMS_Volts_Ln_1_Average', 'type': 'v3'},
    'rms_volts_ln_1_min': { 'name': 'RMS Volts Line 1 Min', 'value': 'RMS_Volts_Ln_1_Min', 'type': 'v3' },
    'rms_volts_ln_1_max': { 'name': 'RMS Volts Line 1 Max', 'value': 'RMS_Volts_Ln_1_Max', 'type': 'v3' },
    'rms_volts_ln_1_first': { 'name': 'RMS Volts Line 1 First', 'value': 'RMS_Volts_Ln_1_First', 'type': 'v3' },
    'rms_volts_ln_1_last': { 'name': 'RMS Volts Line 1 Last', 'value': 'RMS_Volts_Ln_1_Last', 'type': 'v3' },
    'rms_volts_ln_2_average': {'name': 'RMS Volts Line 2 Average', 'value': 'RMS_Volts_Ln_2_Average', 'type':'v3'},
    'rms_volts_ln_2_min': { 'name': 'RMS Volts Line 2 Min', 'value': 'RMS_Volts_Ln_2_Min', 'type': 'v3' },
    'rms_volts_ln_2_max': { 'name': 'RMS Volts Line 2 Max', 'value': 'RMS_Volts_Ln_2_Max', 'type': 'v3' },
    'rms_volts_ln_2_first': { 'name': 'RMS Volts Line 2 First', 'value': 'RMS_Volts_Ln_2_First', 'type': 'v3' },
    'rms_volts_ln_2_last': { 'name': 'RMS Volts Line 2 Last', 'value': 'RMS_Volts_Ln_2_Last', 'type': 'v3' },
    'rms_volts_ln_3_average': {'name': 'RMS Volts Line 3 Average', 'value': 'RMS_Volts_Ln_3_Average', 'type':'v3'},
    'rms_volts_ln_3_min': { 'name': 'RMS Volts Line 3 Min', 'value': 'RMS_Volts_Ln_3_Min', 'type': 'v3' },
    'rms_volts_ln_3_max': { 'name': 'RMS Volts Line 3 Max', 'value': 'RMS_Volts_Ln_3_Max', 'type': 'v3' },
    'rms_volts_ln_3_first': { 'name': 'RMS Volts Line 3 First', 'value': 'RMS_Volts_Ln_3_First', 'type': 'v3' },
    'rms_volts_ln_3_last': { 'name': 'RMS Volts Line 3 Last', 'value': 'RMS_Volts_Ln_3_Last', 'type': 'v3' },
    'amps_ln_1_average': { 'name': 'Amps Line 1 Average', 'value': 'Amps_Ln_1_Average', 'type': 'v3' },
    'amps_ln_1_min': { 'name': 'Amps Line 1 Min', 'value': 'Amps_Ln_1_Min', 'type': 'v3' },
    'amps_ln_1_max': { 'name': 'Amps Line 1 Max', 'value': 'Amps_Ln_1_Max', 'type': 'v3' },
    'amps_ln_1_first': { 'name': 'Amps Line 1 First', 'value': 'Amps_Ln_1_First', 'type': 'v3' },
    'amps_ln_1_last': { 'name': 'Amps Line 1 Last', 'value': 'Amps_Ln_1_Last', 'type': 'v3' },
    'amps_ln_2_average': { 'name': 'Amps Line 2 Average', 'value': 'Amps_Ln_2_Average', 'type': 'v3' },
    'amps_ln_2_min': { 'name': 'Amps Line 2 Min', 'value': 'Amps_Ln_2_Min', 'type': 'v3' },
    'amps_ln_2_max': { 'name': 'Amps Line 2 Max', 'value': 'Amps_Ln_2_Max', 'type': 'v3' },
    'amps_ln_2_first': { 'name': 'Amps Line 2 First', 'value': 'Amps_Ln_2_First', 'type': 'v3' },
    'amps_ln_2_last': { 'name': 'Amps Line 2 Last', 'value': 'Amps_Ln_2_Last', 'type': 'v3' },
    'amps_ln_3_average': { 'name': 'Amps Line 3 Average', 'value': 'Amps_Ln_3_Average', 'type': 'v3' },
    'amps_ln_3_min': { 'name': 'Amps Line 3 Min', 'value': 'Amps_Ln_3_Min', 'type': 'v3' },
    'amps_ln_3_max': { 'name': 'Amps Line 3 Max', 'value': 'Amps_Ln_3_Max', 'type': 'v3' },
    'amps_ln_3_first': { 'name': 'Amps Line 3 First', 'value': 'Amps_Ln_3_First', 'type': 'v3' },
    'amps_ln_3_last': { 'name': 'Amps Line 3 Last', 'value': 'Amps_Ln_3_Last', 'type': 'v3' },
    'rms_watts_ln_1_average': {'name': 'RMS Watts Line 1 Average', 'value': 'RMS_Watts_Ln_1_Average', 'type':'v3'},
    'rms_watts_ln_1_min': { 'name': 'RMS Watts Line 1 Min', 'value': 'RMS_Watts_Ln_1_Min', 'type': 'v3' },
    'rms_watts_ln_1_max': { 'name': 'RMS Watts Line 1 Max', 'value': 'RMS_Watts_Ln_1_Max', 'type': 'v3' },
    'rms_watts_ln_1_first': { 'name': 'RMS Watts Line 1 First', 'value': 'RMS_Watts_Ln_1_First', 'type': 'v3' },
    'rms_watts_ln_1_last': { 'name': 'RMS Watts Line 1 Last', 'value': 'RMS_Watts_Ln_1_Last', 'type': 'v3' },
    'rms_watts_ln_2_average': {'name': 'RMS Watts Line 2 Average', 'value': 'RMS_Watts_Ln_2_Average', 'type':'v3'},
    'rms_watts_ln_2_min': { 'name': 'RMS Watts Line 2 Min', 'value': 'RMS_Watts_Ln_2_Min', 'type': 'v3' },
    'rms_watts_ln_2_max': { 'name': 'RMS Watts Line 2 Max', 'value': 'RMS_Watts_Ln_2_Max', 'type': 'v3' },
    'rms_watts_ln_2_first': { 'name': 'RMS Watts Line 2 First', 'value': 'RMS_Watts_Ln_2_First', 'type': 'v3' },
    'rms_watts_ln_2_last': { 'name': 'RMS Watts Line 2 Last', 'value': 'RMS_Watts_Ln_2_Last', 'type': 'v3' },
    'rms_watts_ln_3_average': {'name': 'RMS Watts Line 3 Average', 'value': 'RMS_Watts_Ln_3_Average', 'type':'v3'},
    'rms_watts_ln_3_min': { 'name': 'RMS Watts Line 3 Min', 'value': 'RMS_Watts_Ln_3_Min', 'type': 'v3' },
    'rms_watts_ln_3_max': { 'name': 'RMS Watts Line 3 Max', 'value': 'RMS_Watts_Ln_3_Max', 'type': 'v3' },
    'rms_watts_ln_3_first': { 'name': 'RMS Watts Line 3 First', 'value': 'RMS_Watts_Ln_3_First', 'type': 'v3' },
    'rms_watts_ln_3_last': { 'name': 'RMS Watts Line 3 Last', 'value': 'RMS_Watts_Ln_3_Last', 'type': 'v3' },
    'rms_watts_tot_average': {'name': 'RMS Watts Total Average', 'value': 'RMS_Watts_Tot_Average', 'type':'v3'},
    'rms_watts_tot_min': { 'name': 'RMS Watts Total Min', 'value': 'RMS_Watts_Tot_Min', 'type': 'v3' },
    'rms_watts_tot_max': { 'name': 'RMS Watts Total Max', 'value': 'RMS_Watts_Tot_Max', 'type': 'v3' },
    'rms_watts_tot_first': { 'name': 'RMS Watts Total First', 'value': 'RMS_Watts_Tot_First', 'type': 'v3' },
    'rms_watts_tot_last': { 'name': 'RMS Watts Total Last', 'value': 'RMS_Watts_Tot_Last', 'type': 'v3' },
    'rms_watts_max_demand_average': {'name': 'RMS Watts Max Demand Average', 'value': 'RMS_Watts_Max_Demand_Average', 'type': 'v3'},
    'rms_watts_max_demand_min': {'name': 'RMS Watts Max Demand Min', 'value': 'RMS_Watts_Max_Demand_Min', 'type': 'v3'},
    'rms_watts_max_demand_max': {'name': 'RMS Watts Max Demand Max', 'value': 'RMS_Watts_Max_Demand_Max', 'type': 'v3'},
    'rms_watts_max_demand_first': {'name': 'RMS Watts Max Demand First', 'value': 'RMS_Watts_Max_Demand_First','type': 'v3'},
    'rms_watts_max_demand_last': {'name': 'RMS Watts Max Demand Last', 'value': 'RMS_Watts_Max_Demand_Last','type': 'v3'},
    'power_factor_ln_1_average': {'name': 'Power Factor Line 1 Average', 'value': 'Power_Factor_Ln_1_Average','type': 'v3'},
    'power_factor_ln_2_average': {'name': 'Power Factor Line 2 Average', 'value': 'Power_Factor_Ln_2_Average','type': 'v3'},
    'power_factor_ln_3_average': {'name': 'Power Factor Line 3 Average', 'value': 'Power_Factor_Ln_3_Average','type': 'v3'},
    'reactive_energy_tot_diff': {'name': 'Reactive Energy Total Diff', 'value': 'Reactive_Energy_Tot_Diff', 'type': 'v4'},
    'reactive_energy_tot_first': {'name': 'Reactive Energy Total First', 'value': 'Reactive_Energy_Tot_First', 'type': 'v4'},
    'reactive_energy_tot_last': {'name': 'Reactive Energy Total Last', 'value': 'Reactive_Energy_Tot_Last', 'type': 'v4'},
    'kwh_rst_diff': { 'name': 'kWh Rst Diff', 'value': 'kWh_Rst_Diff', 'type': 'v4' },
    'kwh_rst_first': { 'name': 'kWh Rst First', 'value': 'kWh_Rst_First', 'type': 'v4' },
    'kwh_rst_last': { 'name': 'kWh Rst Last', 'value': 'kWh_Rst_Last', 'type': 'v4' },
    'rev_kwh_rst_diff': { 'name': 'Rev kWh Rst', 'value': 'Rev_kWh_Rst_Diff', 'type': 'v4' },
    'rev_kwh_rst_first': { 'name': 'Rev kWh Rst First', 'value': 'Rev_kWh_Rst_First', 'type': 'v4' },
    'rev_kwh_rst_last': { 'name': 'Rev kWh Rst Last', 'value': 'Rev_kWh_Rst_Last', 'type': 'v4' },
    'reactive_pwr_ln_1_average': {'name': 'Reactive Power Line 1 Average', 'value': 'Reactive_Pwr_Ln_1_Average','type': 'v4'},
    'reactive_pwr_ln_1_max': {'name': 'Reactive Power Line 1 Max', 'value': 'Reactive_Pwr_Ln_1_Max', 'type': 'v4'},
    'reactive_pwr_ln_1_first': {'name': 'Reactive Power Line 1 First', 'value': 'Reactive_Pwr_Ln_1_First', 'type': 'v4'},
    'reactive_pwr_ln_1_last': {'name': 'Reactive Power Line 1 Last', 'value': 'Reactive_Pwr_Ln_1_Last', 'type': 'v4'},
    'reactive_pwr_ln_2_average': {'name': 'Reactive Power Line 2 Average', 'value': 'Reactive_Pwr_Ln_2_Average','type': 'v4'},
    'reactive_pwr_ln_2_max': {'name': 'Reactive Power Line 2 Max', 'value': 'Reactive_Pwr_Ln_2_Max', 'type':'v4'},
    'reactive_pwr_ln_2_first': {'name': 'Reactive Power Line 2 First', 'value': 'Reactive_Pwr_Ln_2_First', 'type':'v4'},
    'reactive_pwr_ln_2_last': {'name': 'Reactive Power Line 2 Last', 'value': 'Reactive_Pwr_Ln_2_Last', 'type':'v4'},
    'reactive_pwr_ln_3_average': {'name': 'Reactive Power Line 3 Average', 'value': 'Reactive_Pwr_Ln_3_Average','type': 'v4'},
    'reactive_pwr_ln_3_max': {'name': 'Reactive Power Line 3 Max', 'value': 'Reactive_Pwr_Ln_3_Max', 'type':'v4'},
    'reactive_pwr_ln_3_first': {'name': 'Reactive Power Line 3 First', 'value': 'Reactive_Pwr_Ln_3_First', 'type':'v4'},
    'reactive_pwr_ln_3_last': {'name': 'Reactive Power Line 3 Last', 'value': 'Reactive_Pwr_Ln_3_Last', 'type':'v4'},
    'reactive_pwr_tot_average': {'name': 'Reactive Power Total Average', 'value': 'Reactive_Pwr_Tot_Average','type': 'v4'},
    'reactive_pwr_tot_max': {'name': 'Reactive Power Total Max', 'value': 'Reactive_Pwr_Tot_Max', 'type':'v4'},
    'line_freq_average': { 'name': 'Line Freq Average', 'value': 'Line_Freq_Average', 'type': 'v4' },
    'line_freq_max': { 'name': 'Line Freq Max', 'value': 'Line_Freq_Max', 'type': 'v4' },
    'line_freq_first': { 'name': 'Line Freq First', 'value': 'Line_Freq_First', 'type': 'v4' },
    'line_freq_last': { 'name': 'Line Freq Last', 'value': 'Line_Freq_Last', 'type': 'v4' },
    'kwh_ln_1_diff': { 'name': 'kWh Line 1 Diff', 'value': 'kWh_Ln_1_Diff', 'type': 'v4' },
    'kwh_ln_1_first': { 'name': 'kWh Line 1 First', 'value': 'kWh_Ln_1_First', 'type': 'v4' },
    'kwh_ln_1_last': { 'name': 'kWh Line 1 Last', 'value': 'kWh_Ln_1_Last', 'type': 'v4' },
    'kwh_ln_2_diff': { 'name': 'kWh Line 2 Diff', 'value': 'kWh_Ln_2_Diff', 'type': 'v4' },
    'kwh_ln_2_first': { 'name': 'kWh Line 2 First', 'value': 'kWh_Ln_2_First', 'type': 'v4' },
    'kwh_ln_2_last': { 'name': 'kWh Line 2 Last', 'value': 'kWh_Ln_2_Last', 'type': 'v4' },
    'kwh_ln_3_diff': { 'name': 'kWh Line 3 Diff', 'value': 'kWh_Ln_3_Diff', 'type': 'v4' },
    'kwh_ln_3_first': { 'name': 'kWh Line 3 First', 'value': 'kWh_Ln_3_First', 'type': 'v4' },
    'kwh_ln_3_last': { 'name': 'kWh Line 3 Last', 'value': 'kWh_Ln_3_Last', 'type': 'v4' },
    'rev_kwh_ln_1_diff': { 'name': 'Rev kWh Line 1 Diff', 'value': 'Rev_kWh_Ln_1_Diff', 'type': 'v4' },
    'rev_kwh_ln_1_first': { 'name': 'Rev kWh Line 1 First', 'value': 'Rev_kWh_Ln_1_First', 'type': 'v4' },
    'rev_kwh_ln_1_last': { 'name': 'Rev kWh Line 1 Last', 'value': 'Rev_kWh_Ln_1_Last', 'type': 'v4' },
    'rev_kwh_ln_2_diff': { 'name': 'Rev kWh Line 2 Diff', 'value': 'Rev_kWh_Ln_2_Diff', 'type': 'v4' },
    'rev_kwh_ln_2_first': { 'name': 'Rev kWh Line 2 First', 'value': 'Rev_kWh_Ln_2_First', 'type': 'v4' },
    'rev_kwh_ln_2_last': { 'name': 'Rev kWh Line 2 Last', 'value': 'Rev_kWh_Ln_2_Last', 'type': 'v4' },
    'rev_kwh_ln_3_diff': { 'name': 'Rev kWh Line 3 Diff', 'value': 'Rev_kWh_Ln_3_Diff', 'type': 'v4' },
    'rev_kwh_ln_3_first': { 'name': 'Rev kWh Line 3 First', 'value': 'Rev_kWh_Ln_3_First', 'type': 'v4' },
    'rev_kwh_ln_3_last': { 'name': 'Rev kWh Line 3 Last', 'value': 'Rev_kWh_Ln_3_Last', 'type': 'v4' },
}
var _BAR_GRAPH_FIELDS_IOSTACK = {
    'analog_in_1_average': {'name': 'Analog Input 1 Avg', 'value': 'Analog_In_1_Average', 'type': '1'},
    'analog_in_1_min': {'name': 'Analog Input 1 Min', 'value': 'Analog_In_1_Max', 'type': '1'},
    'analog_in_1_max': {'name': 'Analog Input 1 Max', 'value': 'Analog_In_1_Min', 'type': '1'},

    'analog_in_2_average': {'name': 'Analog Input 2 Avg', 'value': 'Analog_In_2_Average', 'type': '1'},
    'analog_in_2_min': {'name': 'Analog Input 2 Min', 'value': 'Analog_In_2_Min', 'type': '1'},
    'analog_in_2_max': {'name': 'Analog Input 2 Max', 'value': 'Analog_In_2_Max', 'type': '1'},

    'analog_in_3_average': {'name': 'Analog Input 3 Avg', 'value': 'Analog_In_3_Average', 'type': '1'},
    'analog_in_3_min': {'name': 'Analog Input 3 Min', 'value': 'Analog_In_3_Min', 'type': '1'},
    'analog_in_3_max': {'name': 'Analog Input 3 Max', 'value': 'Analog_In_3_Max', 'type': '1'},
    
    'analog_in_4_average': {'name': 'Analog Input 4 Avg', 'value': 'Analog_In_4_Average', 'type': '1'},
    'analog_in_4_min': {'name': 'Analog Input 4 Min', 'value': 'Analog_In_4_Min', 'type': '1'},
    'analog_in_4_max': {'name': 'Analog Input 4 Max', 'value': 'Analog_In_4_Max', 'type': '1'},

    'converted_analog_in_1_average' : {'name': 'Converted AI 1 Avg', 'value': 'Analog_In_1_Average', 'type': '1'},
    'converted_analog_in_1_min' : {'name': 'Converted AI 1 Min', 'value': 'Analog_In_1_Min', 'type': '1'},
    'converted_analog_in_1_max' : {'name': 'Converted AI 1 Max', 'value': 'Analog_In_1_Max', 'type': '1'},

    'converted_analog_in_2_average' : {'name': 'Converted AI 2 Avg', 'value': 'Analog_In_2_Average', 'type': '1'},
    'converted_analog_in_2_min' : {'name': 'Converted AI 2 Min', 'value': 'Analog_In_2_Min', 'type': '1'},
    'converted_analog_in_2_max' : {'name': 'Converted AI 2 Max', 'value': 'Analog_In_2_Max', 'type': '1'},

    'converted_analog_in_3_average' : {'name': 'Converted AI 3 Avg', 'value': 'Analog_In_3_Average', 'type': '1'},
    'converted_analog_in_3_min' : {'name': 'Converted AI 3 Min', 'value': 'Analog_In_3_Min', 'type': '1'},
    'converted_analog_in_3_max' : {'name': 'Converted AI 3 Max', 'value': 'Analog_In_3_Max', 'type': '1'},

    'converted_analog_in_4_average' : {'name': 'Converted AI 4 Avg', 'value': 'Analog_In_4_Average', 'type': '1'},
    'converted_analog_in_4_min' : {'name': 'Converted AI 4 Min', 'value': 'Analog_In_4_Min', 'type': '1'},
    'converted_analog_in_4_max' : {'name': 'Converted AI 4 Max', 'value': 'Analog_In_4_Max', 'type': '1'},

    'pulse_hold_ms_1_average': {'name': 'Pulse Hold (ms) 1 Avg', 'value': 'Pulse_Hold_ms_1_Average', 'type': '1'},
    'pulse_hold_ms_1_min': {'name': 'Pulse Hold (ms) 1 Min', 'value': 'Pulse_Hold_ms_1_Min', 'type': '1'},
    'pulse_hold_ms_1_max': {'name': 'Pulse Hold (ms) 1 Max', 'value': 'Pulse_Hold_ms_1_Max', 'type': '1'},
    'pulse_hold_ms_2_average': {'name': 'Pulse Hold (ms) 2 Avg', 'value': 'Pulse_Hold_ms_2_Average', 'type': '1'},
    'pulse_hold_ms_2_min': {'name': 'Pulse Hold (ms) 2 Min', 'value': 'Pulse_Hold_ms_2_Min', 'type': '1'},
    'pulse_hold_ms_2_max': {'name': 'Pulse Hold (ms) 2 Max', 'value': 'Pulse_Hold_ms_2_Max', 'type': '1'},
    'pulse_hold_ms_3_average': {'name': 'Pulse Hold (ms) 3 Avg', 'value': 'Pulse_Hold_ms_3_Average', 'type': '1'},
    'pulse_hold_ms_3_min': {'name': 'Pulse Hold (ms) 3 Min', 'value': 'Pulse_Hold_ms_3_Min', 'type': '1'},
    'pulse_hold_ms_3_max': {'name': 'Pulse Hold (ms) 3 Max', 'value': 'Pulse_Hold_ms_3_Max', 'type': '1'},
    'pulse_hold_ms_4_average': {'name': 'Pulse Hold (ms) 4 Avg', 'value': 'Pulse_Hold_ms_4_Average', 'type': '1'},
    'pulse_hold_ms_4_min': {'name': 'Pulse Hold (ms) 4 Min', 'value': 'Pulse_Hold_ms_4_Min', 'type': '1'},
    'pulse_hold_ms_4_max': {'name': 'Pulse Hold (ms) 4 Max', 'value': 'Pulse_Hold_ms_4_Max', 'type': '1'},
    
    'pulse_hi_prev_ms_1_average': {'name': 'Pulse High Previous (ms) 1 Avg', 'value': 'Pulse_Hi_Prev_ms_1_Average', 'type': '1'},
    'pulse_hi_prev_ms_1_min': {'name': 'Pulse High Previous (ms) 1 Min', 'value': 'Pulse_Hi_Prev_ms_1_Min', 'type': '1'},
    'pulse_hi_prev_ms_1_max': {'name': 'Pulse High Previous (ms) 1 Max', 'value': 'Pulse_Hi_Prev_ms_1_Max', 'type': '1'},
    'pulse_hi_prev_ms_2_average': {'name': 'Pulse High Previous (ms) 2 Avg', 'value': 'Pulse_Hi_Prev_ms_2_Average', 'type': '1'},
    'pulse_hi_prev_ms_2_min': {'name': 'Pulse High Previous (ms) 2 Min', 'value': 'Pulse_Hi_Prev_ms_2_Min', 'type': '1'},
    'pulse_hi_prev_ms_2_max': {'name': 'Pulse High Previous (ms) 2 Max', 'value': 'Pulse_Hi_Prev_ms_2_Max', 'type': '1'},
    'pulse_hi_prev_ms_3_average': {'name': 'Pulse High Previous (ms) 3 Avg', 'value': 'Pulse_Hi_Prev_ms_3_Average', 'type': '1'},
    'pulse_hi_prev_ms_3_min': {'name': 'Pulse High Previous (ms) 3 Min', 'value': 'Pulse_Hi_Prev_ms_3_Min', 'type': '1'},
    'pulse_hi_prev_ms_3_max': {'name': 'Pulse High Previous (ms) 3 Max', 'value': 'Pulse_Hi_Prev_ms_3_Max', 'type': '1'},
    'pulse_hi_prev_ms_4_average': {'name': 'Pulse High Previous (ms) 4 Avg', 'value': 'Pulse_Hi_Prev_ms_4_Average', 'type': '1'},
    'pulse_hi_prev_ms_4_min': {'name': 'Pulse High Previous (ms) 4 Min', 'value': 'Pulse_Hi_Prev_ms_4_Min', 'type': '1'},
    'pulse_hi_prev_ms_4_max': {'name': 'Pulse High Previous (ms) 4 Max', 'value': 'Pulse_Hi_Prev_ms_4_Max', 'type': '1'},

    'pulse_lo_prev_ms_1_average': {'name': 'Pulse Low Previous (ms) 1 Avg', 'value': 'Pulse_Lo_Prev_ms_1_Average', 'type': '1'},
    'pulse_lo_prev_ms_1_min': {'name': 'Pulse Low Previous (ms) 1 Min', 'value': 'Pulse_Lo_Prev_ms_1_Min', 'type': '1'},
    'pulse_lo_prev_ms_1_max': {'name': 'Pulse Low Previous (ms) 1 Max', 'value': 'Pulse_Lo_Prev_ms_1_Max', 'type': '1'},
    'pulse_lo_prev_ms_2_average': {'name': 'Pulse Low Previous (ms) 2 Avg', 'value': 'Pulse_Lo_Prev_ms_2_Average', 'type': '1'},
    'pulse_lo_prev_ms_2_min': {'name': 'Pulse Low Previous (ms) 2 Min', 'value': 'Pulse_Lo_Prev_ms_2_Min', 'type': '1'},
    'pulse_lo_prev_ms_2_max': {'name': 'Pulse Low Previous (ms) 2 Max', 'value': 'Pulse_Lo_Prev_ms_2_Max', 'type': '1'},
    'pulse_lo_prev_ms_3_average': {'name': 'Pulse Low Previous (ms) 3 Avg', 'value': 'Pulse_Lo_Prev_ms_3_Average', 'type': '1'},
    'pulse_lo_prev_ms_3_min': {'name': 'Pulse Low Previous (ms) 3 Min', 'value': 'Pulse_Lo_Prev_ms_3_Min', 'type': '1'},
    'pulse_lo_prev_ms_3_max': {'name': 'Pulse Low Previous (ms) 3 Max', 'value': 'Pulse_Lo_Prev_ms_3_Max', 'type': '1'},
    'pulse_lo_prev_ms_4_average': {'name': 'Pulse Low Previous (ms) 4 Avg', 'value': 'Pulse_Lo_Prev_ms_4_Average', 'type': '1'},
    'pulse_lo_prev_ms_4_min': {'name': 'Pulse Low Previous (ms) 4 Min', 'value': 'Pulse_Lo_Prev_ms_4_Min', 'type': '1'},
    'pulse_lo_prev_ms_4_max': {'name': 'Pulse Low Previous (ms) 4 Max', 'value': 'Pulse_Lo_Prev_ms_4_Max', 'type': '1'},

    'pulse_hi_total_sec_1_average': {'name': 'Pulse High Total (sec) 1 Avg', 'value': 'Pulse_Hi_Total_sec_1_Average', 'type': '1'},
    'pulse_hi_total_sec_1_min': {'name': 'Pulse High Total (sec) 1 Min', 'value': 'Pulse_Hi_Total_sec_1_Min', 'type': '1'},
    'pulse_hi_total_sec_1_max': {'name': 'Pulse High Total (sec) 1 Max', 'value': 'Pulse_Hi_Total_sec_1_Max', 'type': '1'},
    'pulse_hi_total_sec_2_average': {'name': 'Pulse High Total (sec) 2 Avg', 'value': 'Pulse_Hi_Total_sec_2_Average', 'type': '1'},
    'pulse_hi_total_sec_2_min': {'name': 'Pulse High Total (sec) 2 Min', 'value': 'Pulse_Hi_Total_sec_2_Min', 'type': '1'},
    'pulse_hi_total_sec_2_max': {'name': 'Pulse High Total (sec) 2 Max', 'value': 'Pulse_Hi_Total_sec_2_Max', 'type': '1'},
    'pulse_hi_total_sec_3_average': {'name': 'Pulse High Total (sec) 3 Avg', 'value': 'Pulse_Hi_Total_sec_3_Average', 'type': '1'},
    'pulse_hi_total_sec_3_min': {'name': 'Pulse High Total (sec) 3 Min', 'value': 'Pulse_Hi_Total_sec_3_Min', 'type': '1'},
    'pulse_hi_total_sec_3_max': {'name': 'Pulse High Total (sec) 3 Max', 'value': 'Pulse_Hi_Total_sec_3_Max', 'type': '1'},
    'pulse_hi_total_sec_4_average': {'name': 'Pulse High Total (sec) 4 Avg', 'value': 'Pulse_Hi_Total_sec_4_Average', 'type': '1'},
    'pulse_hi_total_sec_4_min': {'name': 'Pulse High Total (sec) 4 Min', 'value': 'Pulse_Hi_Total_sec_4_Min', 'type': '1'},
    'pulse_hi_total_sec_4_max': {'name': 'Pulse High Total (sec) 4 Max', 'value': 'Pulse_Hi_Total_sec_4_Max', 'type': '1'},

    'pulse_lo_total_sec_1_average': {'name': 'Pulse Low Total (sec) 1 Avg', 'value': 'Pulse_Lo_Total_sec_1_Average', 'type': '1'},
    'pulse_lo_total_sec_1_min': {'name': 'Pulse Low Total (sec) 1 Min', 'value': 'Pulse_Lo_Total_sec_1_Min', 'type': '1'},
    'pulse_lo_total_sec_1_max': {'name': 'Pulse Low Total (sec) 1 Max', 'value': 'Pulse_Lo_Total_sec_1_Max', 'type': '1'},
    'pulse_lo_total_sec_2_average': {'name': 'Pulse Low Total (sec) 2 Avg', 'value': 'Pulse_Lo_Total_sec_2_Average', 'type': '1'},
    'pulse_lo_total_sec_2_min': {'name': 'Pulse Low Total (sec) 2 Min', 'value': 'Pulse_Lo_Total_sec_2_Min', 'type': '1'},
    'pulse_lo_total_sec_2_max': {'name': 'Pulse Low Total (sec) 2 Max', 'value': 'Pulse_Lo_Total_sec_2_Max', 'type': '1'},
    'pulse_lo_total_sec_3_average': {'name': 'Pulse Low Total (sec) 3 Avg', 'value': 'Pulse_Lo_Total_sec_3_Average', 'type': '1'},
    'pulse_lo_total_sec_3_min': {'name': 'Pulse Low Total (sec) 3 Min', 'value': 'Pulse_Lo_Total_sec_3_Min', 'type': '1'},
    'pulse_lo_total_sec_3_max': {'name': 'Pulse Low Total (sec) 3 Max', 'value': 'Pulse_Lo_Total_sec_3_Max', 'type': '1'},
    'pulse_lo_total_sec_4_average': {'name': 'Pulse Low Total (sec) 4 Avg', 'value': 'Pulse_Lo_Total_sec_4_Average', 'type': '1'},
    'pulse_lo_total_sec_4_min': {'name': 'Pulse Low Total (sec) 4 Min', 'value': 'Pulse_Lo_Total_sec_4_Min', 'type': '1'},
    'pulse_lo_total_sec_4_max': {'name': 'Pulse Low Total (sec) 4 Max', 'value': 'Pulse_Lo_Total_sec_4_Max', 'type': '1'},

    'ow_1_1_degc_average': {'name': '1-Wire 1_1 Degree C Avg', 'value': 'OW_1_1_degC_Average', 'type': '1'},
    'ow_1_1_degc_min': {'name': '1-Wire 1_1 Degree C Min', 'value': 'OW_1_1_degC_Min', 'type': '1'},
    'ow_1_1_degc_max': {'name': '1-Wire 1_1 Degree C Max', 'value': 'OW_1_1_degC_Max', 'type': '1'},
    'ow_1_2_degc_average': {'name': '1-Wire 1_2 Degree C Avg', 'value': 'OW_1_2_degC_Average', 'type': '1'},
    'ow_1_2_degc_min': {'name': '1-Wire 1_2 Degree C Min', 'value': 'OW_1_2_degC_Min', 'type': '1'},
    'ow_1_2_degc_max': {'name': '1-Wire 1_2 Degree C Max', 'value': 'OW_1_2_degC_Max', 'type': '1'},
    'ow_1_3_degc_average': {'name': '1-Wire 1_3 Degree C Avg', 'value': 'OW_1_3_degC_Average', 'type': '1'},
    'ow_1_3_degc_min': {'name': '1-Wire 1_3 Degree C Min', 'value': 'OW_1_3_degC_Min', 'type': '1'},
    'ow_1_3_degc_max': {'name': '1-Wire 1_3 Degree C Max', 'value': 'OW_1_3_degC_Max', 'type': '1'},
    'ow_1_4_degc_average': {'name': '1-Wire 1_4 Degree C Avg', 'value': 'OW_1_4_degC_Average', 'type': '1'},
    'ow_1_4_degc_min': {'name': '1-Wire 1_4 Degree C Min', 'value': 'OW_1_4_degC_Min', 'type': '1'},
    'ow_1_4_degc_max': {'name': '1-Wire 1_4 Degree C Max', 'value': 'OW_1_4_degC_Max', 'type': '1'},
    'ow_2_1_degc_average': {'name': '1-Wire 2_1 Degree C Avg', 'value': 'OW_2_1_degC_Average', 'type': '1'},
    'ow_2_1_degc_min': {'name': '1-Wire 2_1 Degree C Min', 'value': 'OW_2_1_degC_Min', 'type': '1'},
    'ow_2_1_degc_max': {'name': '1-Wire 2_1 Degree C Max', 'value': 'OW_2_1_degC_Max', 'type': '1'},
    'ow_2_2_degc_average': {'name': '1-Wire 2_2 Degree C Avg', 'value': 'OW_2_2_degC_Average', 'type': '1'},
    'ow_2_2_degc_min': {'name': '1-Wire 2_2 Degree C Min', 'value': 'OW_2_2_degC_Min', 'type': '1'},
    'ow_2_2_degc_max': {'name': '1-Wire 2_2 Degree C Max', 'value': 'OW_2_2_degC_Max', 'type': '1'},
    'ow_2_3_degc_average': {'name': '1-Wire 2_3 Degree C Avg', 'value': 'OW_2_3_degC_Average', 'type': '1'},
    'ow_2_3_degc_min': {'name': '1-Wire 2_3 Degree C Min', 'value': 'OW_2_3_degC_Min', 'type': '1'},
    'ow_2_3_degc_max': {'name': '1-Wire 2_3 Degree C Max', 'value': 'OW_2_3_degC_Max', 'type': '1'},
    'ow_2_4_degc_average': {'name': '1-Wire 2_4 Degree C Avg', 'value': 'OW_2_4_degC_Average', 'type': '1'},
    'ow_2_4_degc_min': {'name': '1-Wire 2_4 Degree C Min', 'value': 'OW_2_4_degC_Min', 'type': '1'},
    'ow_2_4_degc_max': {'name': '1-Wire 2_4 Degree C Max', 'value': 'OW_2_4_degC_Max', 'type': '1'},
    'ow_3_1_degc_average': {'name': '1-Wire 3_1 Degree C Avg', 'value': 'OW_3_1_degC_Average', 'type': '1'},
    'ow_3_1_degc_min': {'name': '1-Wire 3_1 Degree C Min', 'value': 'OW_3_1_degC_Min', 'type': '1'},
    'ow_3_1_degc_max': {'name': '1-Wire 3_1 Degree C Max', 'value': 'OW_3_1_degC_Max', 'type': '1'},
    'ow_3_2_degc_average': {'name': '1-Wire 3_2 Degree C Avg', 'value': 'OW_3_2_degC_Average', 'type': '1'},
    'ow_3_2_degc_min': {'name': '1-Wire 3_2 Degree C Min', 'value': 'OW_3_2_degC_Min', 'type': '1'},
    'ow_3_2_degc_max': {'name': '1-Wire 3_2 Degree C Max', 'value': 'OW_3_2_degC_Max', 'type': '1'},
    'ow_3_3_degc_average': {'name': '1-Wire 3_3 Degree C Avg', 'value': 'OW_3_3_degC_Average', 'type': '1'},
    'ow_3_3_degc_min': {'name': '1-Wire 3_3 Degree C Min', 'value': 'OW_3_3_degC_Min', 'type': '1'},
    'ow_3_3_degc_max': {'name': '1-Wire 3_3 Degree C Max', 'value': 'OW_3_3_degC_Max', 'type': '1'},
    'ow_3_4_degc_average': {'name': '1-Wire 3_4 Degree C Avg', 'value': 'OW_3_4_degC_Average', 'type': '1'},
    'ow_3_4_degc_min': {'name': '1-Wire 3_4 Degree C Min', 'value': 'OW_3_4_degC_Min', 'type': '1'},
    'ow_3_4_degc_max': {'name': '1-Wire 3_4 Degree C Max', 'value': 'OW_3_4_degC_Max', 'type': '1'},
    'ow_4_1_degc_average': {'name': '1-Wire 4_1 Degree C Avg', 'value': 'OW_4_1_degC_Average', 'type': '1'},
    'ow_4_1_degc_min': {'name': '1-Wire 4_1 Degree C Min', 'value': 'OW_4_1_degC_Min', 'type': '1'},
    'ow_4_1_degc_max': {'name': '1-Wire 4_1 Degree C Max', 'value': 'OW_4_1_degC_Max', 'type': '1'},
    'ow_4_2_degc_average': {'name': '1-Wire 4_2 Degree C Avg', 'value': 'OW_4_2_degC_Average', 'type': '1'},
    'ow_4_2_degc_min': {'name': '1-Wire 4_2 Degree C Min', 'value': 'OW_4_2_degC_Min', 'type': '1'},
    'ow_4_2_degc_max': {'name': '1-Wire 4_2 Degree C Max', 'value': 'OW_4_2_degC_Max', 'type': '1'},
    'ow_4_3_degc_average': {'name': '1-Wire 4_3 Degree C Avg', 'value': 'OW_4_3_degC_Average', 'type': '1'},
    'ow_4_3_degc_min': {'name': '1-Wire 4_3 Degree C Min', 'value': 'OW_4_3_degC_Min', 'type': '1'},
    'ow_4_3_degc_max': {'name': '1-Wire 4_3 Degree C Max', 'value': 'OW_4_3_degC_Max', 'type': '1'},
    'ow_4_4_degc_average': {'name': '1-Wire 4_4 Degree C Avg', 'value': 'OW_4_4_degC_Average', 'type': '1'},
    'ow_4_4_degc_min': {'name': '1-Wire 4_4 Degree C Min', 'value': 'OW_4_4_degC_Min', 'type': '1'},
    'ow_4_4_degc_max': {'name': '1-Wire 4_4 Degree C Max', 'value': 'OW_4_4_degC_Max', 'type': '1'},

    'ow_1_1_humidity_average': {'name': '1-Wire 1_1 Humidity Avg', 'value': 'OW_1_1_Humidity_Average', 'type': '1'},
    'ow_1_1_humidity_min': {'name': '1-Wire 1_1 Humidity Min', 'value': 'OW_1_1_Humidity_Min', 'type': '1'},
    'ow_1_1_humidity_max': {'name': '1-Wire 1_1 Humidity Max', 'value': 'OW_1_1_Humidity_Max', 'type': '1'},
    'ow_1_2_humidity_average': {'name': '1-Wire 1_2 Humidity Avg', 'value': 'OW_1_2_Humidity_Average', 'type': '1'},
    'ow_1_2_humidity_min': {'name': '1-Wire 1_2 Humidity Min', 'value': 'OW_1_2_Humidity_Min', 'type': '1'},
    'ow_1_2_humidity_max': {'name': '1-Wire 1_2 Humidity Max', 'value': 'OW_1_2_Humidity_Max', 'type': '1'},
    'ow_1_3_humidity_average': {'name': '1-Wire 1_3 Humidity Avg', 'value': 'OW_1_3_Humidity_Average', 'type': '1'},
    'ow_1_3_humidity_min': {'name': '1-Wire 1_3 Humidity Min', 'value': 'OW_1_3_Humidity_Min', 'type': '1'},
    'ow_1_3_humidity_max': {'name': '1-Wire 1_3 Humidity Max', 'value': 'OW_1_3_Humidity_Max', 'type': '1'},
    'ow_1_4_humidity_average': {'name': '1-Wire 1_4 Humidity Avg', 'value': 'OW_1_4_Humidity_Average', 'type': '1'},
    'ow_1_4_humidity_min': {'name': '1-Wire 1_4 Humidity Min', 'value': 'OW_1_4_Humidity_Min', 'type': '1'},
    'ow_1_4_humidity_max': {'name': '1-Wire 1_4 Humidity Max', 'value': 'OW_1_4_Humidity_Max', 'type': '1'},
	'ow_2_1_humidity_average': {'name': '1-Wire 2_1 Humidity Avg', 'value': 'OW_2_1_Humidity_Average', 'type': '1'},
    'ow_2_1_humidity_min': {'name': '1-Wire 2_1 Humidity Min', 'value': 'OW_2_1_Humidity_Min', 'type': '1'},
    'ow_2_1_humidity_max': {'name': '1-Wire 2_1 Humidity Max', 'value': 'OW_2_1_Humidity_Max', 'type': '1'},
    'ow_2_2_humidity_average': {'name': '1-Wire 2_2 Humidity Avg', 'value': 'OW_2_2_Humidity_Average', 'type': '1'},
    'ow_2_2_humidity_min': {'name': '1-Wire 2_2 Humidity Min', 'value': 'OW_2_2_Humidity_Min', 'type': '1'},
    'ow_2_2_humidity_max': {'name': '1-Wire 2_2 Humidity Max', 'value': 'OW_2_2_Humidity_Max', 'type': '1'},
    'ow_2_3_humidity_average': {'name': '1-Wire 2_3 Humidity Avg', 'value': 'OW_2_3_Humidity_Average', 'type': '1'},
    'ow_2_3_humidity_min': {'name': '1-Wire 2_3 Humidity Min', 'value': 'OW_2_3_Humidity_Min', 'type': '1'},
    'ow_2_3_humidity_max': {'name': '1-Wire 2_3 Humidity Max', 'value': 'OW_2_3_Humidity_Max', 'type': '1'},
    'ow_2_4_humidity_average': {'name': '1-Wire 2_4 Humidity Avg', 'value': 'OW_2_4_Humidity_Average', 'type': '1'},
    'ow_2_4_humidity_min': {'name': '1-Wire 2_4 Humidity Min', 'value': 'OW_2_4_Humidity_Min', 'type': '1'},
    'ow_2_4_humidity_max': {'name': '1-Wire 2_4 Humidity Max', 'value': 'OW_2_4_Humidity_Max', 'type': '1'},
    'ow_3_1_humidity_average': {'name': '1-Wire 3_1 Humidity Avg', 'value': 'OW_3_1_Humidity_Average', 'type': '1'},
    'ow_3_1_humidity_min': {'name': '1-Wire 3_1 Humidity Min', 'value': 'OW_3_1_Humidity_Min', 'type': '1'},
    'ow_3_1_humidity_max': {'name': '1-Wire 3_1 Humidity Max', 'value': 'OW_3_1_Humidity_Max', 'type': '1'},
    'ow_3_2_humidity_average': {'name': '1-Wire 3_2 Humidity Avg', 'value': 'OW_3_2_Humidity_Average', 'type': '1'},
    'ow_3_2_humidity_min': {'name': '1-Wire 3_2 Humidity Min', 'value': 'OW_3_2_Humidity_Min', 'type': '1'},
    'ow_3_2_humidity_max': {'name': '1-Wire 3_2 Humidity Max', 'value': 'OW_3_2_Humidity_Max', 'type': '1'},
    'ow_3_3_humidity_average': {'name': '1-Wire 3_3 Humidity Avg', 'value': 'OW_3_3_Humidity_Average', 'type': '1'},
    'ow_3_3_humidity_min': {'name': '1-Wire 3_3 Humidity Min', 'value': 'OW_3_3_Humidity_Min', 'type': '1'},
    'ow_3_3_humidity_max': {'name': '1-Wire 3_3 Humidity Max', 'value': 'OW_3_3_Humidity_Max', 'type': '1'},
    'ow_3_4_humidity_average': {'name': '1-Wire 3_4 Humidity Avg', 'value': 'OW_3_4_Humidity_Average', 'type': '1'},
    'ow_3_4_humidity_min': {'name': '1-Wire 3_4 Humidity Min', 'value': 'OW_3_4_Humidity_Min', 'type': '1'},
    'ow_3_4_humidity_max': {'name': '1-Wire 3_4 Humidity Max', 'value': 'OW_3_4_Humidity_Max', 'type': '1'},
    'ow_4_1_humidity_average': {'name': '1-Wire 4_1 Humidity Avg', 'value': 'OW_4_1_Humidity_Average', 'type': '1'},
    'ow_4_1_humidity_min': {'name': '1-Wire 4_1 Humidity Min', 'value': 'OW_4_1_Humidity_Min', 'type': '1'},
    'ow_4_1_humidity_max': {'name': '1-Wire 4_1 Humidity Max', 'value': 'OW_4_1_Humidity_Max', 'type': '1'},
    'ow_4_2_humidity_average': {'name': '1-Wire 4_2 Humidity Avg', 'value': 'OW_4_2_Humidity_Average', 'type': '1'},
    'ow_4_2_humidity_min': {'name': '1-Wire 4_2 Humidity Min', 'value': 'OW_4_2_Humidity_Min', 'type': '1'},
    'ow_4_2_humidity_max': {'name': '1-Wire 4_2 Humidity Max', 'value': 'OW_4_2_Humidity_Max', 'type': '1'},
    'ow_4_3_humidity_average': {'name': '1-Wire 4_3 Humidity Avg', 'value': 'OW_4_3_Humidity_Average', 'type': '1'},
    'ow_4_3_humidity_min': {'name': '1-Wire 4_3 Humidity Min', 'value': 'OW_4_3_Humidity_Min', 'type': '1'},
    'ow_4_3_humidity_max': {'name': '1-Wire 4_3 Humidity Max', 'value': 'OW_4_3_Humidity_Max', 'type': '1'},
    'ow_4_4_humidity_average': {'name': '1-Wire 4_4 Humidity Avg', 'value': 'OW_4_4_Humidity_Average', 'type': '1'},
    'ow_4_4_humidity_min': {'name': '1-Wire 4_4 Humidity Min', 'value': 'OW_4_4_Humidity_Min', 'type': '1'},
    'ow_4_4_humidity_max': {'name': '1-Wire 4_4 Humidity Max', 'value': 'OW_4_4_Humidity_Max', 'type': '1'},
    
    'ow_1_1_lux_average': {'name': '1-Wire 1_1 Lux Avg', 'value': 'OW_1_1_Lux_Average', 'type': '1'},
    'ow_1_1_lux_min': {'name': '1-Wire 1_1 Lux Min', 'value': 'OW_1_1_Lux_Min', 'type': '1'},
    'ow_1_1_lux_max': {'name': '1-Wire 1_1 Lux Max', 'value': 'OW_1_1_Lux_Max', 'type': '1'},
    'ow_1_2_lux_average': {'name': '1-Wire 1_2 Lux Avg', 'value': 'OW_1_2_Lux_Average', 'type': '1'},
    'ow_1_2_lux_min': {'name': '1-Wire 1_2 Lux Min', 'value': 'OW_1_2_Lux_Min', 'type': '1'},
    'ow_1_2_lux_max': {'name': '1-Wire 1_2 Lux Max', 'value': 'OW_1_2_Lux_Max', 'type': '1'},
    'ow_1_3_lux_average': {'name': '1-Wire 1_3 Lux Avg', 'value': 'OW_1_3_Lux_Average', 'type': '1'},
    'ow_1_3_lux_min': {'name': '1-Wire 1_3 Lux Min', 'value': 'OW_1_3_Lux_Min', 'type': '1'},
    'ow_1_3_lux_max': {'name': '1-Wire 1_3 Lux Max', 'value': 'OW_1_3_Lux_Max', 'type': '1'},
    'ow_1_4_lux_average': {'name': '1-Wire 1_4 Lux Avg', 'value': 'OW_1_4_Lux_Average', 'type': '1'},
    'ow_1_4_lux_min': {'name': '1-Wire 1_4 Lux Min', 'value': 'OW_1_4_Lux_Min', 'type': '1'},
    'ow_1_4_lux_max': {'name': '1-Wire 1_4 Lux Max', 'value': 'OW_1_4_Lux_Max', 'type': '1'},
    'ow_2_1_lux_average': {'name': '1-Wire 2_1 Lux Avg', 'value': 'OW_2_1_Lux_Average', 'type': '1'},
    'ow_2_1_lux_min': {'name': '1-Wire 2_1 Lux Min', 'value': 'OW_2_1_Lux_Min', 'type': '1'},
    'ow_2_1_lux_max': {'name': '1-Wire 2_1 Lux Max', 'value': 'OW_2_1_Lux_Max', 'type': '1'},
    'ow_2_2_lux_average': {'name': '1-Wire 2_2 Lux Avg', 'value': 'OW_2_2_Lux_Average', 'type': '1'},
    'ow_2_2_lux_min': {'name': '1-Wire 2_2 Lux Min', 'value': 'OW_2_2_Lux_Min', 'type': '1'},
    'ow_2_2_lux_max': {'name': '1-Wire 2_2 Lux Max', 'value': 'OW_2_2_Lux_Max', 'type': '1'},
    'ow_2_3_lux_average': {'name': '1-Wire 2_3 Lux Avg', 'value': 'OW_2_3_Lux_Average', 'type': '1'},
    'ow_2_3_lux_min': {'name': '1-Wire 2_3 Lux Min', 'value': 'OW_2_3_Lux_Min', 'type': '1'},
    'ow_2_3_lux_max': {'name': '1-Wire 2_3 Lux Max', 'value': 'OW_2_3_Lux_Max', 'type': '1'},
    'ow_2_4_lux_average': {'name': '1-Wire 2_4 Lux Avg', 'value': 'OW_2_4_Lux_Average', 'type': '1'},
    'ow_2_4_lux_min': {'name': '1-Wire 2_4 Lux Min', 'value': 'OW_2_4_Lux_Min', 'type': '1'},
    'ow_2_4_lux_max': {'name': '1-Wire 2_4 Lux Max', 'value': 'OW_2_4_Lux_Max', 'type': '1'},
    'ow_3_1_lux_average': {'name': '1-Wire 3_1 Lux Avg', 'value': 'OW_3_1_Lux_Average', 'type': '1'},
    'ow_3_1_lux_min': {'name': '1-Wire 3_1 Lux Min', 'value': 'OW_3_1_Lux_Min', 'type': '1'},
    'ow_3_1_lux_max': {'name': '1-Wire 3_1 Lux Max', 'value': 'OW_3_1_Lux_Max', 'type': '1'},
    'ow_3_2_lux_average': {'name': '1-Wire 3_2 Lux Avg', 'value': 'OW_3_2_Lux_Average', 'type': '1'},
    'ow_3_2_lux_min': {'name': '1-Wire 3_2 Lux Min', 'value': 'OW_3_2_Lux_Min', 'type': '1'},
    'ow_3_2_lux_max': {'name': '1-Wire 3_2 Lux Max', 'value': 'OW_3_2_Lux_Max', 'type': '1'},
    'ow_3_3_lux_average': {'name': '1-Wire 3_3 Lux Avg', 'value': 'OW_3_3_Lux_Average', 'type': '1'},
    'ow_3_3_lux_min': {'name': '1-Wire 3_3 Lux Min', 'value': 'OW_3_3_Lux_Min', 'type': '1'},
    'ow_3_3_lux_max': {'name': '1-Wire 3_3 Lux Max', 'value': 'OW_3_3_Lux_Max', 'type': '1'},
    'ow_3_4_lux_average': {'name': '1-Wire 3_4 Lux Avg', 'value': 'OW_3_4_Lux_Average', 'type': '1'},
    'ow_3_4_lux_min': {'name': '1-Wire 3_4 Lux Min', 'value': 'OW_3_4_Lux_Min', 'type': '1'},
    'ow_3_4_lux_max': {'name': '1-Wire 3_4 Lux Max', 'value': 'OW_3_4_Lux_Max', 'type': '1'},
    'ow_4_1_lux_average': {'name': '1-Wire 4_1 Lux Avg', 'value': 'OW_4_1_Lux_Average', 'type': '1'},
    'ow_4_1_lux_min': {'name': '1-Wire 4_1 Lux Min', 'value': 'OW_4_1_Lux_Min', 'type': '1'},
    'ow_4_1_lux_max': {'name': '1-Wire 4_1 Lux Max', 'value': 'OW_4_1_Lux_Max', 'type': '1'},
    'ow_4_2_lux_average': {'name': '1-Wire 4_2 Lux Avg', 'value': 'OW_4_2_Lux_Average', 'type': '1'},
    'ow_4_2_lux_min': {'name': '1-Wire 4_2 Lux Min', 'value': 'OW_4_2_Lux_Min', 'type': '1'},
    'ow_4_2_lux_max': {'name': '1-Wire 4_2 Lux Max', 'value': 'OW_4_2_Lux_Max', 'type': '1'},
    'ow_4_3_lux_average': {'name': '1-Wire 4_3 Lux Avg', 'value': 'OW_4_3_Lux_Average', 'type': '1'},
    'ow_4_3_lux_min': {'name': '1-Wire 4_3 Lux Min', 'value': 'OW_4_3_Lux_Min', 'type': '1'},
    'ow_4_3_lux_max': {'name': '1-Wire 4_3 Lux Max', 'value': 'OW_4_3_Lux_Max', 'type': '1'},
    'ow_4_4_lux_average': {'name': '1-Wire 4_4 Lux Avg', 'value': 'OW_4_4_Lux_Average', 'type': '1'},
    'ow_4_4_lux_min': {'name': '1-Wire 4_4 Lux Min', 'value': 'OW_4_4_Lux_Min', 'type': '1'},
    'ow_4_4_lux_max': {'name': '1-Wire 4_4 Lux Max', 'value': 'OW_4_4_Lux_Max', 'type': '1'},

    'converted_ow_1_1_degc_average': {'name': 'Converted 1-Wire 1_1 Degree C Avg', 'value': 'OW_1_1_degC_Average', 'type': '1'},
    'converted_ow_1_1_degc_min': {'name': 'Converted 1-Wire 1_1 Degree C Min', 'value': 'OW_1_1_degC_Min', 'type': '1'},
    'converted_ow_1_1_degc_max': {'name': 'Converted 1-Wire 1_1 Degree C Max', 'value': 'OW_1_1_degC_Max', 'type': '1'},
    'converted_ow_1_2_degc_average': {'name': 'Converted 1-Wire 1_2 Degree C Avg', 'value': 'OW_1_2_degC_Average', 'type': '1'},
    'converted_ow_1_2_degc_min': {'name': 'Converted 1-Wire 1_2 Degree C Min', 'value': 'OW_1_2_degC_Min', 'type': '1'},
    'converted_ow_1_2_degc_max': {'name': 'Converted 1-Wire 1_2 Degree C Max', 'value': 'OW_1_2_degC_Max', 'type': '1'},
    'converted_ow_1_3_degc_average': {'name': 'Converted 1-Wire 1_3 Degree C Avg', 'value': 'OW_1_3_degC_Average', 'type': '1'},
    'converted_ow_1_3_degc_min': {'name': 'Converted 1-Wire 1_3 Degree C Min', 'value': 'OW_1_3_degC_Min', 'type': '1'},
    'converted_ow_1_3_degc_max': {'name': 'Converted 1-Wire 1_3 Degree C Max', 'value': 'OW_1_3_degC_Max', 'type': '1'},
    'converted_ow_1_4_degc_average': {'name': 'Converted 1-Wire 1_4 Degree C Avg', 'value': 'OW_1_4_degC_Average', 'type': '1'},
    'converted_ow_1_4_degc_min': {'name': 'Converted 1-Wire 1_4 Degree C Min', 'value': 'OW_1_4_degC_Min', 'type': '1'},
    'converted_ow_1_4_degc_max': {'name': 'Converted 1-Wire 1_4 Degree C Max', 'value': 'OW_1_4_degC_Max', 'type': '1'},
    'converted_ow_2_1_degc_average': {'name': 'Converted 1-Wire 2_1 Degree C Avg', 'value': 'OW_2_1_degC_Average', 'type': '1'},
    'converted_ow_2_1_degc_min': {'name': 'Converted 1-Wire 2_1 Degree C Min', 'value': 'OW_2_1_degC_Min', 'type': '1'},
    'converted_ow_2_1_degc_max': {'name': 'Converted 1-Wire 2_1 Degree C Max', 'value': 'OW_2_1_degC_Max', 'type': '1'},
    'converted_ow_2_2_degc_average': {'name': 'Converted 1-Wire 2_2 Degree C Avg', 'value': 'OW_2_2_degC_Average', 'type': '1'},
    'converted_ow_2_2_degc_min': {'name': 'Converted 1-Wire 2_2 Degree C Min', 'value': 'OW_2_2_degC_Min', 'type': '1'},
    'converted_ow_2_2_degc_max': {'name': 'Converted 1-Wire 2_2 Degree C Max', 'value': 'OW_2_2_degC_Max', 'type': '1'},
    'converted_ow_2_3_degc_average': {'name': 'Converted 1-Wire 2_3 Degree C Avg', 'value': 'OW_2_3_degC_Average', 'type': '1'},
    'converted_ow_2_3_degc_min': {'name': 'Converted 1-Wire 2_3 Degree C Min', 'value': 'OW_2_3_degC_Min', 'type': '1'},
    'converted_ow_2_3_degc_max': {'name': 'Converted 1-Wire 2_3 Degree C Max', 'value': 'OW_2_3_degC_Max', 'type': '1'},
    'converted_ow_2_4_degc_average': {'name': 'Converted 1-Wire 2_4 Degree C Avg', 'value': 'OW_2_4_degC_Average', 'type': '1'},
    'converted_ow_2_4_degc_min': {'name': 'Converted 1-Wire 2_4 Degree C Min', 'value': 'OW_2_4_degC_Min', 'type': '1'},
    'converted_ow_2_4_degc_max': {'name': 'Converted 1-Wire 2_4 Degree C Max', 'value': 'OW_2_4_degC_Max', 'type': '1'},
    'converted_ow_3_1_degc_average': {'name': 'Converted 1-Wire 3_1 Degree C Avg', 'value': 'OW_3_1_degC_Average', 'type': '1'},
    'converted_ow_3_1_degc_min': {'name': 'Converted 1-Wire 3_1 Degree C Min', 'value': 'OW_3_1_degC_Min', 'type': '1'},
    'converted_ow_3_1_degc_max': {'name': 'Converted 1-Wire 3_1 Degree C Max', 'value': 'OW_3_1_degC_Max', 'type': '1'},
    'converted_ow_3_2_degc_average': {'name': 'Converted 1-Wire 3_2 Degree C Avg', 'value': 'OW_3_2_degC_Average', 'type': '1'},
    'converted_ow_3_2_degc_min': {'name': 'Converted 1-Wire 3_2 Degree C Min', 'value': 'OW_3_2_degC_Min', 'type': '1'},
    'converted_ow_3_2_degc_max': {'name': 'Converted 1-Wire 3_2 Degree C Max', 'value': 'OW_3_2_degC_Max', 'type': '1'},
    'converted_ow_3_3_degc_average': {'name': 'Converted 1-Wire 3_3 Degree C Avg', 'value': 'OW_3_3_degC_Average', 'type': '1'},
    'converted_ow_3_3_degc_min': {'name': 'Converted 1-Wire 3_3 Degree C Min', 'value': 'OW_3_3_degC_Min', 'type': '1'},
    'converted_ow_3_3_degc_max': {'name': 'Converted 1-Wire 3_3 Degree C Max', 'value': 'OW_3_3_degC_Max', 'type': '1'},
    'converted_ow_3_4_degc_average': {'name': 'Converted 1-Wire 3_4 Degree C Avg', 'value': 'OW_3_4_degC_Average', 'type': '1'},
    'converted_ow_3_4_degc_min': {'name': 'Converted 1-Wire 3_4 Degree C Min', 'value': 'OW_3_4_degC_Min', 'type': '1'},
    'converted_ow_3_4_degc_max': {'name': 'Converted 1-Wire 3_4 Degree C Max', 'value': 'OW_3_4_degC_Max', 'type': '1'},
    'converted_ow_4_1_degc_average': {'name': 'Converted 1-Wire 4_1 Degree C Avg', 'value': 'OW_4_1_degC_Average', 'type': '1'},
    'converted_ow_4_1_degc_min': {'name': 'Converted 1-Wire 4_1 Degree C Min', 'value': 'OW_4_1_degC_Min', 'type': '1'},
    'converted_ow_4_1_degc_max': {'name': 'Converted 1-Wire 4_1 Degree C Max', 'value': 'OW_4_1_degC_Max', 'type': '1'},
    'converted_ow_4_2_degc_average': {'name': 'Converted 1-Wire 4_2 Degree C Avg', 'value': 'OW_4_2_degC_Average', 'type': '1'},
    'converted_ow_4_2_degc_min': {'name': 'Converted 1-Wire 4_2 Degree C Min', 'value': 'OW_4_2_degC_Min', 'type': '1'},
    'converted_ow_4_2_degc_max': {'name': 'Converted 1-Wire 4_2 Degree C Max', 'value': 'OW_4_2_degC_Max', 'type': '1'},
    'converted_ow_4_3_degc_average': {'name': 'Converted 1-Wire 4_3 Degree C Avg', 'value': 'OW_4_3_degC_Average', 'type': '1'},
    'converted_ow_4_3_degc_min': {'name': 'Converted 1-Wire 4_3 Degree C Min', 'value': 'OW_4_3_degC_Min', 'type': '1'},
    'converted_ow_4_3_degc_max': {'name': 'Converted 1-Wire 4_3 Degree C Max', 'value': 'OW_4_3_degC_Max', 'type': '1'},
    'converted_ow_4_4_degc_average': {'name': 'Converted 1-Wire 4_4 Degree C Avg', 'value': 'OW_4_4_degC_Average', 'type': '1'},
    'converted_ow_4_4_degc_min': {'name': 'Converted 1-Wire 4_4 Degree C Min', 'value': 'OW_4_4_degC_Min', 'type': '1'},
    'converted_ow_4_4_degc_max': {'name': 'Converted 1-Wire 4_4 Degree C Max', 'value': 'OW_4_4_degC_Max', 'type': '1'},

    'converted_ow_1_1_humidity_average': {'name': 'Converted 1-Wire 1_1 Humidity Avg', 'value': 'OW_1_1_Humidity_Average', 'type': '1'},
    'converted_ow_1_1_humidity_min': {'name': 'Converted 1-Wire 1_1 Humidity Min', 'value': 'OW_1_1_Humidity_Min', 'type': '1'},
    'converted_ow_1_1_humidity_max': {'name': 'Converted 1-Wire 1_1 Humidity Max', 'value': 'OW_1_1_Humidity_Max', 'type': '1'},
    'converted_ow_1_2_humidity_average': {'name': 'Converted 1-Wire 1_2 Humidity Avg', 'value': 'OW_1_2_Humidity_Average', 'type': '1'},
    'converted_ow_1_2_humidity_min': {'name': 'Converted 1-Wire 1_2 Humidity Min', 'value': 'OW_1_2_Humidity_Min', 'type': '1'},
    'converted_ow_1_2_humidity_max': {'name': 'Converted 1-Wire 1_2 Humidity Max', 'value': 'OW_1_2_Humidity_Max', 'type': '1'},
    'converted_ow_1_3_humidity_average': {'name': 'Converted 1-Wire 1_3 Humidity Avg', 'value': 'OW_1_3_Humidity_Average', 'type': '1'},
    'converted_ow_1_3_humidity_min': {'name': 'Converted 1-Wire 1_3 Humidity Min', 'value': 'OW_1_3_Humidity_Min', 'type': '1'},
    'converted_ow_1_3_humidity_max': {'name': 'Converted 1-Wire 1_3 Humidity Max', 'value': 'OW_1_3_Humidity_Max', 'type': '1'},
    'converted_ow_1_4_humidity_average': {'name': 'Converted 1-Wire 1_4 Humidity Avg', 'value': 'OW_1_4_Humidity_Average', 'type': '1'},
    'converted_ow_1_4_humidity_min': {'name': 'Converted 1-Wire 1_4 Humidity Min', 'value': 'OW_1_4_Humidity_Min', 'type': '1'},
    'converted_ow_1_4_humidity_max': {'name': 'Converted 1-Wire 1_4 Humidity Max', 'value': 'OW_1_4_Humidity_Max', 'type': '1'},
	'converted_ow_2_1_humidity_average': {'name': 'Converted 1-Wire 2_1 Humidity Avg', 'value': 'OW_2_1_Humidity_Average', 'type': '1'},
    'converted_ow_2_1_humidity_min': {'name': 'Converted 1-Wire 2_1 Humidity Min', 'value': 'OW_2_1_Humidity_Min', 'type': '1'},
    'converted_ow_2_1_humidity_max': {'name': 'Converted 1-Wire 2_1 Humidity Max', 'value': 'OW_2_1_Humidity_Max', 'type': '1'},
    'converted_ow_2_2_humidity_average': {'name': 'Converted 1-Wire 2_2 Humidity Avg', 'value': 'OW_2_2_Humidity_Average', 'type': '1'},
    'converted_ow_2_2_humidity_min': {'name': 'Converted 1-Wire 2_2 Humidity Min', 'value': 'OW_2_2_Humidity_Min', 'type': '1'},
    'converted_ow_2_2_humidity_max': {'name': 'Converted 1-Wire 2_2 Humidity Max', 'value': 'OW_2_2_Humidity_Max', 'type': '1'},
    'converted_ow_2_3_humidity_average': {'name': 'Converted 1-Wire 2_3 Humidity Avg', 'value': 'OW_2_3_Humidity_Average', 'type': '1'},
    'converted_ow_2_3_humidity_min': {'name': 'Converted 1-Wire 2_3 Humidity Min', 'value': 'OW_2_3_Humidity_Min', 'type': '1'},
    'converted_ow_2_3_humidity_max': {'name': 'Converted 1-Wire 2_3 Humidity Max', 'value': 'OW_2_3_Humidity_Max', 'type': '1'},
    'converted_ow_2_4_humidity_average': {'name': 'Converted 1-Wire 2_4 Humidity Avg', 'value': 'OW_2_4_Humidity_Average', 'type': '1'},
    'converted_ow_2_4_humidity_min': {'name': 'Converted 1-Wire 2_4 Humidity Min', 'value': 'OW_2_4_Humidity_Min', 'type': '1'},
    'converted_ow_2_4_humidity_max': {'name': 'Converted 1-Wire 2_4 Humidity Max', 'value': 'OW_2_4_Humidity_Max', 'type': '1'},
    'converted_ow_3_1_humidity_average': {'name': 'Converted 1-Wire 3_1 humidity Avg', 'value': 'OW_3_1_Humidity_Average', 'type': '1'},
    'converted_ow_3_1_humidity_min': {'name': 'Converted 1-Wire 3_1 humidity Min', 'value': 'OW_3_1_Humidity_Min', 'type': '1'},
    'converted_ow_3_1_humidity_max': {'name': 'Converted 1-Wire 3_1 humidity Max', 'value': 'OW_3_1_Humidity_Max', 'type': '1'},
    'converted_ow_3_2_humidity_average': {'name': 'Converted 1-Wire 3_2 Humidity Avg', 'value': 'OW_3_2_Humidity_Average', 'type': '1'},
    'converted_ow_3_2_humidity_min': {'name': 'Converted 1-Wire 3_2 Humidity Min', 'value': 'OW_3_2_Humidity_Min', 'type': '1'},
    'converted_ow_3_2_humidity_max': {'name': 'Converted 1-Wire 3_2 Humidity Max', 'value': 'OW_3_2_Humidity_Max', 'type': '1'},
    'converted_ow_3_3_humidity_average': {'name': 'Converted 1-Wire 3_3 Humidity Avg', 'value': 'OW_3_3_Humidity_Average', 'type': '1'},
    'converted_ow_3_3_humidity_min': {'name': 'Converted 1-Wire 3_3 Humidity Min', 'value': 'OW_3_3_Humidity_Min', 'type': '1'},
    'converted_ow_3_3_humidity_max': {'name': 'Converted 1-Wire 3_3 Humidity Max', 'value': 'OW_3_3_Humidity_Max', 'type': '1'},
    'converted_ow_3_4_humidity_average': {'name': 'Converted 1-Wire 3_4 Humidity Avg', 'value': 'OW_3_4_Humidity_Average', 'type': '1'},
    'converted_ow_3_4_humidity_min': {'name': 'Converted 1-Wire 3_4 Humidity Min', 'value': 'OW_3_4_Humidity_Min', 'type': '1'},
    'converted_ow_3_4_humidity_max': {'name': 'Converted 1-Wire 3_4 Humidity Max', 'value': 'OW_3_4_Humidity_Max', 'type': '1'},
    'converted_ow_4_1_humidity_average': {'name': 'Converted 1-Wire 4_1 Humidity Avg', 'value': 'OW_4_1_Humidity_Average', 'type': '1'},
    'converted_ow_4_1_humidity_min': {'name': 'Converted 1-Wire 4_1 Humidity Min', 'value': 'OW_4_1_Humidity_Min', 'type': '1'},
    'converted_ow_4_1_humidity_max': {'name': 'Converted 1-Wire 4_1 Humidity Max', 'value': 'OW_4_1_Humidity_Max', 'type': '1'},
    'converted_ow_4_2_humidity_average': {'name': 'Converted 1-Wire 4_2 Humidity Avg', 'value': 'OW_4_2_Humidity_Average', 'type': '1'},
    'converted_ow_4_2_humidity_min': {'name': 'Converted 1-Wire 4_2 Humidity Min', 'value': 'OW_4_2_Humidity_Min', 'type': '1'},
    'converted_ow_4_2_humidity_max': {'name': 'Converted 1-Wire 4_2 Humidity Max', 'value': 'OW_4_2_Humidity_Max', 'type': '1'},
    'converted_ow_4_3_humidity_average': {'name': 'Converted 1-Wire 4_3 Humidity Avg', 'value': 'OW_4_3_Humidity_Average', 'type': '1'},
    'converted_ow_4_3_humidity_min': {'name': 'Converted 1-Wire 4_3 Humidity Min', 'value': 'OW_4_3_Humidity_Min', 'type': '1'},
    'converted_ow_4_3_humidity_max': {'name': 'Converted 1-Wire 4_3 Humidity Max', 'value': 'OW_4_3_Humidity_Max', 'type': '1'},
    'converted_ow_4_4_humidity_average': {'name': 'Converted 1-Wire 4_4 Humidity Avg', 'value': 'OW_4_4_Humidity_Average', 'type': '1'},
    'converted_ow_4_4_humidity_min': {'name': 'Converted 1-Wire 4_4 Humidity Min', 'value': 'OW_4_4_Humidity_Min', 'type': '1'},
    'converted_ow_4_4_humidity_max': {'name': 'Converted 1-Wire 4_4 Humidity Max', 'value': 'OW_4_4_Humidity_Max', 'type': '1'},
    
    'converted_ow_1_1_lux_average': {'name': 'Converted 1-Wire 1_1 Lux Avg', 'value': 'OW_1_1_Lux_Average', 'type': '1'},
    'converted_ow_1_1_lux_min': {'name': 'Converted 1-Wire 1_1 Lux Min', 'value': 'OW_1_1_Lux_Min', 'type': '1'},
    'converted_ow_1_1_lux_max': {'name': 'Converted 1-Wire 1_1 Lux Max', 'value': 'OW_1_1_Lux_Max', 'type': '1'},
    'converted_ow_1_2_lux_average': {'name': 'Converted 1-Wire 1_2 Lux Avg', 'value': 'OW_1_2_Lux_Average', 'type': '1'},
    'converted_ow_1_2_lux_min': {'name': 'Converted 1-Wire 1_2 Lux Min', 'value': 'OW_1_2_Lux_Min', 'type': '1'},
    'converted_ow_1_2_lux_max': {'name': 'Converted 1-Wire 1_2 Lux Max', 'value': 'OW_1_2_Lux_Max', 'type': '1'},
    'converted_ow_1_3_lux_average': {'name': 'Converted 1-Wire 1_3 Lux Avg', 'value': 'OW_1_3_Lux_Average', 'type': '1'},
    'converted_ow_1_3_lux_min': {'name': 'Converted 1-Wire 1_3 Lux Min', 'value': 'OW_1_3_Lux_Min', 'type': '1'},
    'converted_ow_1_3_lux_max': {'name': 'Converted 1-Wire 1_3 Lux Max', 'value': 'OW_1_3_Lux_Max', 'type': '1'},
    'converted_ow_1_4_lux_average': {'name': 'Converted 1-Wire 1_4 Lux Avg', 'value': 'OW_1_4_Lux_Average', 'type': '1'},
    'converted_ow_1_4_lux_min': {'name': 'Converted 1-Wire 1_4 Lux Min', 'value': 'OW_1_4_Lux_Min', 'type': '1'},
    'converted_ow_1_4_lux_max': {'name': 'Converted 1-Wire 1_4 Lux Max', 'value': 'OW_1_4_Lux_Max', 'type': '1'},
    'converted_ow_2_1_lux_average': {'name': 'Converted 1-Wire 2_1 Lux Avg', 'value': 'OW_2_1_Lux_Average', 'type': '1'},
    'converted_ow_2_1_lux_min': {'name': 'Converted 1-Wire 2_1 Lux Min', 'value': 'OW_2_1_Lux_Min', 'type': '1'},
    'converted_ow_2_1_lux_max': {'name': 'Converted 1-Wire 2_1 Lux Max', 'value': 'OW_2_1_Lux_Max', 'type': '1'},
    'converted_ow_2_2_lux_average': {'name': 'Converted 1-Wire 2_2 Lux Avg', 'value': 'OW_2_2_Lux_Average', 'type': '1'},
    'converted_ow_2_2_lux_min': {'name': 'Converted 1-Wire 2_2 Lux Min', 'value': 'OW_2_2_Lux_Min', 'type': '1'},
    'converted_ow_2_2_lux_max': {'name': 'Converted 1-Wire 2_2 Lux Max', 'value': 'OW_2_2_Lux_Max', 'type': '1'},
    'converted_ow_2_3_lux_average': {'name': 'Converted 1-Wire 2_3 Lux Avg', 'value': 'OW_2_3_Lux_Average', 'type': '1'},
    'converted_ow_2_3_lux_min': {'name': 'Converted 1-Wire 2_3 Lux Min', 'value': 'OW_2_3_Lux_Min', 'type': '1'},
    'converted_ow_2_3_lux_max': {'name': 'Converted 1-Wire 2_3 Lux Max', 'value': 'OW_2_3_Lux_Max', 'type': '1'},
    'converted_ow_2_4_lux_average': {'name': 'Converted 1-Wire 2_4 Lux Avg', 'value': 'OW_2_4_Lux_Average', 'type': '1'},
    'converted_ow_2_4_lux_min': {'name': 'Converted 1-Wire 2_4 Lux Min', 'value': 'OW_2_4_Lux_Min', 'type': '1'},
    'converted_ow_2_4_lux_max': {'name': 'Converted 1-Wire 2_4 Lux Max', 'value': 'OW_2_4_Lux_Max', 'type': '1'},
    'converted_ow_3_1_lux_average': {'name': 'Converted 1-Wire 3_1 Lux Avg', 'value': 'OW_3_1_Lux_Average', 'type': '1'},
    'converted_ow_3_1_lux_min': {'name': 'Converted 1-Wire 3_1 Lux Min', 'value': 'OW_3_1_Lux_Min', 'type': '1'},
    'converted_ow_3_1_lux_max': {'name': 'Converted 1-Wire 3_1 Lux Max', 'value': 'OW_3_1_Lux_Max', 'type': '1'},
    'converted_ow_3_2_lux_average': {'name': 'Converted 1-Wire 3_2 Lux Avg', 'value': 'OW_3_2_Lux_Average', 'type': '1'},
    'converted_ow_3_2_lux_min': {'name': 'Converted 1-Wire 3_2 Lux Min', 'value': 'OW_3_2_Lux_Min', 'type': '1'},
    'converted_ow_3_2_lux_max': {'name': 'Converted 1-Wire 3_2 Lux Max', 'value': 'OW_3_2_Lux_Max', 'type': '1'},
    'converted_ow_3_3_lux_average': {'name': 'Converted 1-Wire 3_3 Lux Avg', 'value': 'OW_3_3_Lux_Average', 'type': '1'},
    'converted_ow_3_3_lux_min': {'name': 'Converted 1-Wire 3_3 Lux Min', 'value': 'OW_3_3_Lux_Min', 'type': '1'},
    'converted_ow_3_3_lux_max': {'name': 'Converted 1-Wire 3_3 Lux Max', 'value': 'OW_3_3_Lux_Max', 'type': '1'},
    'converted_ow_3_4_lux_average': {'name': 'Converted 1-Wire 3_4 Lux Avg', 'value': 'OW_3_4_Lux_Average', 'type': '1'},
    'converted_ow_3_4_lux_min': {'name': 'Converted 1-Wire 3_4 Lux Min', 'value': 'OW_3_4_Lux_Min', 'type': '1'},
    'converted_ow_3_4_lux_max': {'name': 'Converted 1-Wire 3_4 Lux Max', 'value': 'OW_3_4_Lux_Max', 'type': '1'},
    'converted_ow_4_1_lux_average': {'name': 'Converted 1-Wire 4_1 Lux Avg', 'value': 'OW_4_1_Lux_Average', 'type': '1'},
    'converted_ow_4_1_lux_min': {'name': 'Converted 1-Wire 4_1 Lux Min', 'value': 'OW_4_1_Lux_Min', 'type': '1'},
    'converted_ow_4_1_lux_max': {'name': 'Converted 1-Wire 4_1 Lux Max', 'value': 'OW_4_1_Lux_Max', 'type': '1'},
    'converted_ow_4_2_lux_average': {'name': 'Converted 1-Wire 4_2 Lux Avg', 'value': 'OW_4_2_Lux_Average', 'type': '1'},
    'converted_ow_4_2_lux_min': {'name': 'Converted 1-Wire 4_2 Lux Min', 'value': 'OW_4_2_Lux_Min', 'type': '1'},
    'converted_ow_4_2_lux_max': {'name': 'Converted 1-Wire 4_2 Lux Max', 'value': 'OW_4_2_Lux_Max', 'type': '1'},
    'converted_ow_4_3_lux_average': {'name': 'Converted 1-Wire 4_3 Lux Avg', 'value': 'OW_4_3_Lux_Average', 'type': '1'},
    'converted_ow_4_3_lux_min': {'name': 'Converted 1-Wire 4_3 Lux Min', 'value': 'OW_4_3_Lux_Min', 'type': '1'},
    'converted_ow_4_3_lux_max': {'name': 'Converted 1-Wire 4_3 Lux Max', 'value': 'OW_4_3_Lux_Max', 'type': '1'},
    'converted_ow_4_4_lux_average': {'name': 'Converted 1-Wire 4_4 Lux Avg', 'value': 'OW_4_4_Lux_Average', 'type': '1'},
    'converted_ow_4_4_lux_min': {'name': 'Converted 1-Wire 4_4 Lux Min', 'value': 'OW_4_4_Lux_Min', 'type': '1'},
    'converted_ow_4_4_lux_max': {'name': 'Converted 1-Wire 4_4 Lux Max', 'value': 'OW_4_4_Lux_Max', 'type': '1'},
}

var _GRAPH_TYPE = -1;
var meter_version;
var _timezone='';
var _offset = 0;
var _country='';
var historycolor1;
var historycolor2;
var totFieldValueAry = [];
var _CURRENCY_OPTIONS = {
    'USD': { 'country_name': 'United States Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'ALL': { 'country_name': 'Albania Lek', 'decimal-code': '76,101,107', 'hex-code': '4c,65,6b' },
    'AFN': { 'country_name': 'Afghanistan Afghani', 'decimal-code': '1547', 'hex-code': '60b' },
    'ARS': { 'country_name': 'Argentina Peso', 'decimal-code': '36', 'hex-code': '24' },
    'AWG': { 'country_name': 'Aruba Guilder', 'decimal-code': '402', 'hex-code': '192' },
    'AUD': { 'country_name': 'Australia Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'AZN': { 'country_name': 'Azerbaijan New Manat','decimal-code': '1084,1072,1085','hex-code': '43c,430,43d'},
    'BSD': { 'country_name': 'Bahamas Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'BBD': { 'country_name': 'Barbados Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'BYR': { 'country_name': 'Belarus Ruble', 'decimal-code': '112,46', 'hex-code': '70,2e' },
    'BZD': { 'country_name': 'Belize Dollar', 'decimal-code': '66,90,36', 'hex-code': '42,5a,24' },
    'BMD': { 'country_name': 'Bermuda Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'BOB': { 'country_name': 'Bolivia Boliviano', 'decimal-code': '36,98', 'hex-code': '24,62' },
    'BAM': { 'country_name': 'Bosnia and Herzegovina Convertible Marka','decimal-code': '75,77', 'hex-code': '4b,4d'},
    'BWP': { 'country_name': 'Botswana Pula', 'decimal-code': '80', 'hex-code': '50' },
    'BGN': { 'country_name': 'Bulgaria Lev', 'decimal-code': '1083,1074', 'hex-code': '43b,432' },
    'BRL': { 'country_name': 'Brazil Real', 'decimal-code': '82,36', 'hex-code': '52,24' },
    'BND': { 'country_name': 'Brunei Darussalam Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'KHR': { 'country_name': 'Cambodia Riel', 'decimal-code': '6107', 'hex-code': '17db' },
    'CAD': { 'country_name': 'Canada Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'KYD': { 'country_name': 'Cayman Islands Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'CLP': { 'country_name': 'Chile Peso', 'decimal-code': '36', 'hex-code': '24' },
    'CNY': { 'country_name': 'China Yuan Renminbi', 'decimal-code': '165', 'hex-code': 'a5' },
    'COP': { 'country_name': 'Colombia Peso', 'decimal-code': '36', 'hex-code': '24' },
    'CRC': { 'country_name': 'Costa Rica Colon', 'decimal-code': '8353', 'hex-code': '20a1' },
    'HRK': { 'country_name': 'Croatia Kuna', 'decimal-code': '107,110', 'hex-code': '6b,6e' },
    'CUP': { 'country_name': 'Cuba Peso', 'decimal-code': '8369', 'hex-code': '20b1' },
    'CZK': { 'country_name': 'Czech Republic Koruna', 'decimal-code': '75,269', 'hex-code': '4b,10d' },
    'DKK': { 'country_name': 'Denmark Krone', 'decimal-code': '107,114', 'hex-code': '6b,72' },
    'DOP': { 'country_name': 'Dominican Republic Peso', 'decimal-code': '82,68,36', 'hex-code': '52,44,24' },
    'XCD': { 'country_name': 'East Caribbean Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'EGP': { 'country_name': 'Egypt Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'SVC': { 'country_name': 'El Salvador Colon', 'decimal-code': '36', 'hex-code': '24' },
    'EEK': { 'country_name': 'Estonia Kroon', 'decimal-code': '107,114', 'hex-code': '6b,72' },
    'EUR': { 'country_name': 'Euro Member Countries', 'decimal-code': '8364', 'hex-code': '20ac' },
    'FKP': { 'country_name': 'Falkland Islands (Malvinas) Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'FJD': { 'country_name': 'Fiji Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'GHC': { 'country_name': 'Ghana Cedi', 'decimal-code': '162', 'hex-code': 'a2' },
    'GIP': { 'country_name': 'Gibraltar Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'GTQ': { 'country_name': 'Guatemala Quetzal', 'decimal-code': '81', 'hex-code': '51' },
    'GGP': { 'country_name': 'Guernsey Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'GYD': { 'country_name': 'Guyana Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'HNL': { 'country_name': 'Honduras Lempira', 'decimal-code': '76', 'hex-code': '4c' },
    'HKD': { 'country_name': 'Hong Kong Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'HUF': { 'country_name': 'Hungary Forint', 'decimal-code': '70,116', 'hex-code': '46,74' },
    'ISK': { 'country_name': 'Iceland Krona', 'decimal-code': '107,114', 'hex-code': '6b,72' },
    'INR': { 'country_name': 'India Rupee', 'decimal-code': '8360', 'hex-code': '20A8' },
    'IDR': { 'country_name': 'Indonesia Rupiah', 'decimal-code': '82,112', 'hex-code': '52,70' },
    'IRR': { 'country_name': 'Iran Rial', 'decimal-code': '65020', 'hex-code': 'fdfc' },
    'IMP': { 'country_name': 'Isle of Man Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'ILS': { 'country_name': 'Israel Shekel', 'decimal-code': '8362', 'hex-code': '20aa' },
    'JMD': { 'country_name': 'Jamaica Dollar', 'decimal-code': '74,36', 'hex-code': '4a,24' },
    'JPY': { 'country_name': 'Japan Yen', 'decimal-code': '165', 'hex-code': 'a5' },
    'JEP': { 'country_name': 'Jersey Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'KZT': { 'country_name': 'Kazakhstan Tenge', 'decimal-code': '1083,1074', 'hex-code': '43b,432' },
    'KPW': { 'country_name': 'Korea (North) Won', 'decimal-code': '8361', 'hex-code': '20a9' },
    'KRW': { 'country_name': 'Korea (South) Won', 'decimal-code': '8361', 'hex-code': '20a9' },
    'KGS': { 'country_name': 'Kyrgyzstan Som', 'decimal-code': '1083,1074', 'hex-code': '43b,432' },
    'LAK': { 'country_name': 'Laos Kip', 'decimal-code': '8365', 'hex-code': '20ad' },
    'LVL': { 'country_name': 'Latvia Lat', 'decimal-code': '76,115', 'hex-code': '4c,73' },
    'LBP': { 'country_name': 'Lebanon Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'LRD': { 'country_name': 'Liberia Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'LTL': { 'country_name': 'Lithuania Litas', 'decimal-code': '76,116', 'hex-code': '4c,74' },
    'MKD': { 'country_name': 'Macedonia Denar', 'decimal-code': '1076,1077,1085', 'hex-code': '434,435,43d' },
    'MYR': { 'country_name': 'Malaysia Ringgit', 'decimal-code': '82,77', 'hex-code': '52,4d' },
    'MUR': { 'country_name': 'Mauritius Rupee', 'decimal-code': '8360', 'hex-code': '20a8' },
    'MXN': { 'country_name': 'Mexico Peso', 'decimal-code': '36', 'hex-code': '24' },
    'MNT': { 'country_name': 'Mongolia Tughrik', 'decimal-code': '8366', 'hex-code': '20ae' },
    'MZN': { 'country_name': 'Mozambique Metical', 'decimal-code': '77,84', 'hex-code': '4d,54' },
    'NAD': { 'country_name': 'Namibia Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'NPR': { 'country_name': 'Nepal Rupee', 'decimal-code': '8360', 'hex-code': '20a8' },
    'ANG': { 'country_name': 'Netherlands Antilles Guilder', 'decimal-code': '402', 'hex-code': '192' },
    'NZD': { 'country_name': 'New Zealand Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'NIO': { 'country_name': 'Nicaragua Cordoba', 'decimal-code': '67,36', 'hex-code': '43,24' },
    'NGN': { 'country_name': 'Nigeria Naira', 'decimal-code': '8358', 'hex-code': '20a6' },
    'KPW': { 'country_name': 'Korea (North) Won', 'decimal-code': '8361', 'hex-code': '20a9' },
    'NOK': { 'country_name': 'Norway Krone', 'decimal-code': '107,114', 'hex-code': '6b,72' },
    'OMR': { 'country_name': 'Oman Rial', 'decimal-code': '65020', 'hex-code': 'fdfc' },
    'PKR': { 'country_name': 'Pakistan Rupee', 'decimal-code': '8360', 'hex-code': '20a8' },
    'PAB': { 'country_name': 'Panama Balboa', 'decimal-code': '66,47,46', 'hex-code': '42,2f,2e' },
    'PYG': { 'country_name': 'Paraguay Guarani', 'decimal-code': '71,115', 'hex-code': '47,73' },
    'PEN': { 'country_name': 'Peru Nuevo Sol', 'decimal-code': '83,47,46', 'hex-code': '53,2f,2e' },
    'PHP': { 'country_name': 'Philippines Peso', 'decimal-code': '8369', 'hex-code': '20b1' },
    'PLN': { 'country_name': 'Poland Zloty', 'decimal-code': '122,322', 'hex-code': '7a,142' },
    'QAR': { 'country_name': 'Qatar Riyal', 'decimal-code': '65020', 'hex-code': 'fdfc' },
    'RON': { 'country_name': 'Romania New Leu', 'decimal-code': '108,101,105', 'hex-code': '6c,65,69' },
    'RUB': { 'country_name': 'Russia Ruble', 'decimal-code': '1088,1091,1073', 'hex-code': '440,443,431' },
    'SHP': { 'country_name': 'Saint Helena Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'SAR': { 'country_name': 'Saudi Arabia Riyal', 'decimal-code': '65020', 'hex-code': 'fdfc' },
    'RSD': { 'country_name': 'Serbia Dinar', 'decimal-code': '1044,1080,1085,46', 'hex-code': '414,438,43d,2e' },
    'SCR': { 'country_name': 'Seychelles Rupee', 'decimal-code': '8360', 'hex-code': '20a8' },
    'SGD': { 'country_name': 'Singapore Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'SBD': { 'country_name': 'Solomon Islands Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'SOS': { 'country_name': 'Somalia Shilling', 'decimal-code': '83', 'hex-code': '53' },
    'ZAR': { 'country_name': 'South Africa Rand', 'decimal-code': '82', 'hex-code': '52' },
    'KRW': { 'country_name': 'Korea (South) Won', 'decimal-code': '8361', 'hex-code': '20a9' },
    'LKR': { 'country_name': 'Sri Lanka Rupee', 'decimal-code': '8360', 'hex-code': '20a8' },
    'SEK': { 'country_name': 'Sweden Krona', 'decimal-code': '107,114', 'hex-code': '6b,72' },
    'CHF': { 'country_name': 'Switzerland Franc', 'decimal-code': '67,72,70', 'hex-code': '43,48,46' },
    'SRD': { 'country_name': 'Suriname Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'SYP': { 'country_name': 'Syria Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'TWD': { 'country_name': 'Taiwan New Dollar', 'decimal-code': '78,84,36', 'hex-code': '4e,54,24' },
    'THB': { 'country_name': 'Thailand Baht', 'decimal-code': '3647', 'hex-code': 'e3f' },
    'TTD': { 'country_name': 'Trinidad and Tobago Dollar', 'decimal-code': '84,84,36', 'hex-code': '54,54,24' },
    'TRL': { 'country_name': 'Turkey Lira', 'decimal-code': '8356', 'hex-code': '20a4' },
    'TVD': { 'country_name': 'Tuvalu Dollar', 'decimal-code': '36', 'hex-code': '24' },
    'UAH': { 'country_name': 'Ukraine Hryvnia', 'decimal-code': '8372', 'hex-code': '20b4' },
    'GBP': { 'country_name': 'United Kingdom Pound', 'decimal-code': '163', 'hex-code': 'a3' },
    'UYU': { 'country_name': 'Uruguay Peso', 'decimal-code': '36,85', 'hex-code': '24,55' },
    'UZS': { 'country_name': 'Uzbekistan Som', 'decimal-code': '1083,1074', 'hex-code': '43b,432' },
    'VEF': { 'country_name': 'Venezuela Bolivar', 'decimal-code': '66,115', 'hex-code': '42,73' },
    'VND': { 'country_name': 'Viet Nam Dong', 'decimal-code': '8363', 'hex-code': '20ab' },
    'YER': { 'country_name': 'Yemen Rial', 'decimal-code': '65020', 'hex-code': 'fdfc' },
    'ZWD': { 'country_name': 'Zimbabwe Dollar', 'decimal-code': '90,36', 'hex-code': '5a,24' }
}

var _DEFAULT_PC_VALUE  
            = {
                'gl'  : 1,
                'qt'  : 4,
                'pt'  : 8,
                'l'   : 3.785412534257983,
                'ml'  : 3785.412534257983,
                'm3'  : 0.00378541,
                'ft3' : 0.133681,
                'in3' : 231,
                'CCF' : 0.0013369,
                'kWh' : 1
            }
var _PC_VALUE_MULTIPLIER  
            = {
                'gl'  : 1,
                'qt'  : 0.25,
                'pt'  : 0.125,
                'l'   : 0.264172,
                'ml'  : 0.000264172,
                'm3'  : 264.172,
                'ft3' : 7.48052,
                'in3' : 0.004329,
                'CCF' : 748,
                'kWh' : 1
            }

var _ALL_COUNTRY = {
    'GMT': 'GMT',
    'US': "United States",
    'CA': "Canada",
    'AU': "Australia",
    'AF': "Afghanistan",
    'AX': "Aland Islands",
    'AL': "Albania",
    'DZ': "Algeria",
    'AS': "American Samoa",
    'AD': "Andorra",
    'AO': "Angola",
    'AI': "Anguilla",
    'AQ': "Antarctica",
    'AG': "Antigua and Barbuda",
    'AR': "Argentina",
    'AM': "Armenia",
    'AW': "Aruba",
    'AT': "Austria",
    'AZ': "Azerbaijan",
    'BS': "Bahamas",
    'BH': "Bahrain",
    'BD': "Bangladesh",
    'BB': "Barbados",
    'BY': "Belarus",
    'BE': "Belgium",
    'BZ': "Belize",
    'BJ': "Benin",
    'BM': "Bermuda",
    'BT': "Bhutan",
    'BO': "Bolivia, Plurinational State of",
    'BQ': "Bonaire, Sint Eustatius and Saba",
    'BA': "Bosnia and Herzegovina",
    'BW': "Botswana",
    'BR': "Brazil",
    'IO': "British Indian Ocean Territory",
    'BN': "Brunei Darussalam",
    'BG': "Bulgaria",
    'BF': "Burkina Faso",
    'BI': "Burundi",
    'KH': "Cambodia",
    'CM': "Cameroon",
    'CV': "Cape Verde",
    'KY': "Cayman Islands",
    'CF': "Central African Republic",
    'TD': "Chad",
    'CL': "Chile",
    'CN': "China",
    'CX': "Christmas Island",
    'CC': "Cocos (Keeling) Islands",
    'CO': "Colombia",
    'KM': "Comoros",
    'CG': "Congo",
    'CD': "Congo, the Democratic Republic of the",
    'CK': "Cook Islands",
    'CR': "Costa Rica",
    'CI': "Côte d'Ivoire",
    'HR': "Croatia",
    'CU': "Cuba",
    'CW': "Curaçao",
    'CY': "Cyprus",
    'CZ': "Czech Republic",
    'DK': "Denmark",
    'DJ': "Djibouti",
    'DM': "Dominica",
    'DO': "Dominican Republic",
    'EC': "Ecuador",
    'EG': "Egypt",
    'SV': "El Salvador",
    'GQ': "Equatorial Guinea",
    'ER': "Eritrea",
    'EE': "Estonia",
    'ET': "Ethiopia",
    'FK': "Falkland Islands (Malvinas)",
    'FO': "Faroe Islands",
    'FJ': "Fiji",
    'FI': "Finland",
    'FR': "France",
    'GF': "French Guiana",
    'PF': "French Polynesia",
    'TF': "French Southern Territories",
    'GA': "Gabon",
    'GM': "Gambia",
    'GE': "Georgia",
    'DE': "Germany",
    'GH': "Ghana",
    'GI': "Gibraltar",
    'GR': "Greece",
    'GL': "Greenland",
    'GD': "Grenada",
    'GP': "Guadeloupe",
    'GU': "Guam",
    'GT': "Guatemala",
    'GG': "Guernsey",
    'GN': "Guinea",
    'GY': "Guyana",
    'HT': "Haiti",
    'VA': "Holy See (Vatican City State)",
    'HN': "Honduras",
    'HK': "Hong Kong",
    'HU': "Hungary",
    'IS': "Iceland",
    'IN': "India",
    'ID': "Indonesia",
    'IR': "Iran, Islamic Republic of",
    'IQ': "Iraq",
    'IE': "Ireland",
    'IM': "Isle of Man",
    'IL': "Israel",
    'IT': "Italy",
    'JM': "Jamaica",
    'JP': "Japan",
    'JE': "Jersey",
    'JO': "Jordan",
    'KZ': "Kazakhstan",
    'KE': "Kenya",
    'KI': "Kiribati",
    'KP': "Korea, Democratic Peoples Republic of",
    'KR': "Korea, Republic of",
    'KW': "Kuwait",
    'KG': "Kyrgyzstan",
    'LA': "Lao People's Democratic Republic",
    'LV': "Latvia",
    'LB': "Lebanon",
    'LS': "Lesotho",
    'LR': "Liberia",
    'LY': "Libya",
    'LI': "Liechtenstein",
    'LT': "Lithuania",
    'LU': "Luxembourg",
    'MO': "Macao",
    'MK': "Macedonia, the former Yugoslav Republic of",
    'MG': "Madagascar",
    'MW': "Malawi",
    'MY': "Malaysia",
    'MV': "Maldives",
    'ML': "Mali",
    'MT': "Malta",
    'MH': "Marshall Islands",
    'MQ': "Martinique",
    'MR': "Mauritania",
    'MU': "Mauritius",
    'YT': "Mayotte",
    'MX': "Mexico",
    'FM': "Micronesia, Federated States of",
    'MD': "Moldova, Republic of",
    'MC': "Monaco",
    'MN': "Mongolia",
    'ME': "Montenegro",
    'MS': "Montserrat",
    'MA': "Morocco",
    'MZ': "Mozambique",
    'MM': "Myanmar",
    'NA': "Namibia",
    'NR': "Nauru",
    'NP': "Nepal",
    'NL': "Netherlands",
    'NC': "New Caledonia",
    'NZ': "New Zealand",
    'NI': "Nicaragua",
    'NE': "Niger",
    'NG': "Nigeria",
    'NU': "Niue",
    'NF': "Norfolk Island",
    'MP': "Northern Mariana Islands",
    'NO': "Norway",
    'OM': "Oman",
    'PK': "Pakistan",
    'PW': "Palau",
    'PS': "Palestinian Territory, Occupied",
    'PA': "Panama",
    'PG': "Papua New Guinea",
    'PY': "Paraguay",
    'PE': "Peru",
    'PH': "Philippines",
    'PN': "Pitcairn",
    'PL': "Poland",
    'PT': "Portugal",
    'PR': "Puerto Rico",
    'QA': "Qatar",
    'RE': "Réunion",
    'RO': "Romania",
    'RU': "Russian Federation",
    'RW': "Rwanda",
    'BL': "Saint Barthélemy",
    'SH': "Saint Helena, Ascension and Tristan da Cunha",
    'KN': "Saint Kitts and Nevis",
    'LC': "Saint Lucia",
    'MF': "Saint Martin (French part), ",
    'PM': "Saint Pierre and Miquelon",
    'VC': "Saint Vincent and the Grenadines",
    'WS': "Samoa",
    'SM': "San Marino",
    'ST': "Sao Tome and Principe",
    'SA': "Saudi Arabia",
    'SN': "Senegal",
    'RS': "Serbia",
    'SC': "Seychelles",
    'SL': "Sierra Leone",
    'SG': "Singapore",
    'SX': "Sint Maarten (Dutch part), ",
    'SK': "Slovakia",
    'SI': "Slovenia",
    'SB': "Solomon Islands",
    'SO': "Somalia",
    'ZA': "South Africa",
    'GS': "South Georgia and the South Sandwich Islands",
    'SS': "South Sudan",
    'ES': "Spain",
    'LK': "Sri Lanka",
    'SD': "Sudan",
    'SR': "Suriname",
    'SJ': "Svalbard and Jan Mayen",
    'SZ': "Swaziland",
    'SE': "Sweden",
    'CH': "Switzerland",
    'SY': "Syrian Arab Republic",
    'TW': "Taiwan, Province of China",
    'TJ': "Tajikistan",
    'TZ': "Tanzania, United Republic of",
    'TH': "Thailand",
    'TL': "Timor-Leste",
    'TG': "Togo",
    'TK': "Tokelau",
    'TO': "Tonga",
    'TT': "Trinidad and Tobago",
    'TN': "Tunisia",
    'TR': "Turkey",
    'TM': "Turkmenistan",
    'TC': "Turks and Caicos Islands",
    'TV': "Tuvalu",
    'UG': "Uganda",
    'UA': "Ukraine",
    'AE': "United Arab Emirates",
    'GB': "United Kingdom",
    'UM': "United States Minor Outlying Islands",
    'UY': "Uruguay",
    'UZ': "Uzbekistan",
    'VU': "Vanuatu",
    'VE': "Venezuela, Bolivarian Republic of",
    'VN': "Viet Nam",
    'VG': "Virgin Islands, British",
    'VI': "Virgin Islands, U.S.",
    'WF': "Wallis and Futuna",
    'EH': "Western Sahara",
    'YE': "Yemen",
    'ZM': "Zambia",
    'ZW': "Zimbabwe"
}

var _GMT_OFFSET = {
    'GMT-12': -43200,
    'GMT-11': -39600,
    'GMT-10': -36000,
    'GMT-9': -32400,
    'GMT-8': -28800,
    'GMT-7': -25200,
    'GMT-6': -21600,
    'GMT-5': -18000,
    'GMT-4': -14400,
    'GMT-3': -10800,
    'GMT-2': -7200,
    'GMT-1': -3600,
    'GMT+1': 3600,
    'GMT+2': 7200,
    'GMT+3': 10800,
    'GMT+4': 14400,
    'GMT+5': 18000,
    'GMT+6': 21600,
    'GMT+7': 25200,
    'GMT+8': 28800,
    'GMT+9': 32400,
    'GMT+10': 36000,
    'GMT+11': 39600,
    'GMT+12': 43200,
    'GMT+13': 46800,
    'GMT+14': 50400
}

var _ALL_TIMEZONES = {
    'GMT': {
        "GMT-12": "GMT-12",
        "GMT-11": "GMT-11",
        "GMT-10": "GMT-10",
        "GMT-9": "GMT-9",
        "GMT-8": "GMT-8",
        "GMT-7": "GMT-7",
        "GMT-6": "GMT-6",
        "GMT-5": "GMT-5",
        "GMT-4": "GMT-4",
        "GMT-3": "GMT-3",
        "GMT-2": "GMT-2",
        "GMT-1": "GMT-1",
        'GMT+1': "GMT+1",
        "GMT+2": "GMT+2",
        "GMT+3": "GMT+3",
        "GMT+4": "GMT+4",
        "GMT+5": "GMT+5",
        "GMT+6": "GMT+6",
        "GMT+7": "GMT+7",
        "GMT+8": "GMT+8",
        "GMT+9": "GMT+9",
        "GMT+10": "GMT+10",
        "GMT+11": "GMT+11",
        "GMT+12": "GMT+12",
        "GMT+13": "GMT+13",
        "GMT+14": "GMT+14"
    },
    'US': {
        "America~Adak": "America/Adak",
        "America~Anchorage": "America/Anchorage",
        "America~Boise": "America/Boise",
        "America~Chicago": "America/Chicago",
        "America~Denver": "America/Denver",
        "America~Detroit": "America/Detroit",
        "America~Indiana~Indianapolis": "America/Indiana/Indianapolis",
        "America~Indiana~Knox": "America/Indiana/Knox",
        "America~Indiana~Marengo": "America/Indiana/Marengo",
        "America~Indiana~Petersburg": "America/Indiana/Petersburg",
        "America~Indiana~Tell_City": "America/Indiana/Tell_City",
        "America~Indiana~Valparaiso": "America/Indiana/Valparaiso",
        "America~Indiana~Vevay": "America/Indiana/Vevay",
        "America~Indiana~Vincennes": "America/Indiana/Vincennes",
        "America~Indiana~Winamac": "America/Indiana/Winamac",
        "America~Juneau": "America/Juneau",
        "America~Kentucky~Louisville": "America/Kentucky/Louisville",
        "America~Kentucky~Monticello": "America/Kentucky/Monticello",
        "America~Los_Angeles": "America/Los_Angeles",
        "America~Menominee": "America/Menominee",
        "America~Metlakatla": "America/Metlakatla",
        "America~New_York": "America/New_York",
        "America~Nome": "America/Nome",
        "America~North_Dakota~Beulah": "America/North_Dakota/Beulah",
        "America~North_Dakota~Center": "America/North_Dakota/Center",
        "America~North_Dakota~New_Salem": "America/North_Dakota/New_Salem",
        "America~Phoenix": "America/Phoenix",
        "America~Shiprock": "America/Shiprock",
        "America~Sitka": "America/Sitka",
        "America~Yakutat": "America/Yakutat",
        "Pacific~Honolulu": "Pacific/Honolulu",
        "US~Alaska": "US/Alaska",
        "US~Aleutian": "US/Aleutian",
        "US~Arizona": "US/Arizona",
        "US~East-Indiana": "US/East-Indiana",
        "US~Eastern": "US/Eastern",
        "US~Hawaii": "US/Hawaii",
        "US~Indiana-Starke": "US/Indiana-Starke",
        "US~Michigan": "US/Michigan",
        "US~Mountain": "US/Mountain",
        "US~Pacific": "US/Pacific",
        "US~Samoa": "US/Samoa"
    },
    'CA': {
        "America~Atikokan": "America/Atikokan",
        "America~Blanc-Sablon": "America/Blanc-Sablon",
        "America~Cambridge_Bay": "America/Cambridge_Bay",
        "America~Creston": "America/Creston",
        "America~Dawson": "America/Dawson",
        "America~Dawson_Creek": "America/Dawson_Creek",
        "America~Edmonton": "America/Edmonton",
        "America~Glace_Bay": "America/Glace_Bay",
        "America~Goose_Bay": "America/Goose_Bay",
        "America~Halifax": "America/Halifax",
        "America~Inuvik": "America/Inuvik",
        "America~Iqaluit": "America/Iqaluit",
        "America~Moncton": "America/Moncton",
        "America~Montreal": "America/Montreal",
        "America~Nipigon": "America/Nipigon",
        "America~Pangnirtung": "America/Pangnirtung",
        "America~Rainy_River": "America/Rainy_River",
        "America~Rankin_Inlet": "America/Rankin_Inlet",
        "America~Regina": "America/Regina",
        "America~Resolute": "America/Resolute",
        "America~St_Johns": "America/St_Johns",
        "America~Swift_Current": "America/Swift_Current",
        "America~Thunder_Bay": "America/Thunder_Bay",
        "America~Toronto": "America/Toronto",
        "America~Vancouver": "America/Vancouver",
        "America~Whitehorse": "America/Whitehorse",
        "America~Winnipeg": "America/Winnipeg",
        "America~Yellowknife": "America/Yellowknife"
    },
    'AU': {
        "Australia~Adelaide": "Australia/Adelaide",
        "Australia~Brisbane": "Australia/Brisbane",
        "Australia~Broken_Hill": "Australia/Broken_Hill",
        "Australia~Currie": "Australia/Currie",
        "Australia~Darwin": "Australia/Darwin",
        "Australia~Eucla": "Australia/Eucla",
        "Australia~Hobart": "Australia/Hobart",
        "Australia~Lindeman": "Australia/Lindeman",
        "Australia~Lord_Howe": "Australia/Lord_Howe",
        "Australia~Melbourne": "Australia/Melbourne",
        "Australia~Perth": "Australia/Perth",
        "Australia~Sydney": "Australia/Sydney"
    },
    'AF': {
        "Asia~Kabul": "Asia/Kabul"
    },
    'AX': {
        "Europe~Mariehamn": "Europe/Mariehamn"
    },
    'AL': {
        "Europe~Tirane": "Europe/Tirane"
    },
    'DZ': {
        "Africa~Algiers": "Africa/Algiers"
    },
    'AS': {
        "Pacific~Pago_Pago": "Pacific/Pago_Pago"
    },
    'AD': {
        "Europe~Andorra": "Europe/Andorra"
    },
    'AO': {
        "Africa~Luanda": "Africa/Luanda"
    },
    'AI': {
        "America~Anguilla": "America/Anguilla"
    },
    'AQ': {
        "Antarctica~Casey": "Antarctica/Casey",
        "Antarctica~Davis": "Antarctica/Davis",
        "Antarctica~DumontDUrville": "Antarctica/DumontDUrville",
        "Antarctica~Macquarie": "Antarctica/Macquarie",
        "Antarctica~Mawson": "Antarctica/Mawson",
        "Antarctica~McMurdo": "Antarctica/McMurdo",
        "Antarctica~Palmer": "Antarctica/Palmer",
        "Antarctica~Rothera": "Antarctica/Rothera",
        "Antarctica~South_Pole": "Antarctica/South_Pole",
        "Antarctica~Syowa": "Antarctica/Syowa",
        "Antarctica~Troll": "Antarctica/Troll",
        "Antarctica~Vostok": "Antarctica/Vostok"
    },
    'AG': {
        "America~Antigua": "America/Antigua"
    },
    'AR': {
        "America~Argentina~Buenos_Aires": "America/Argentina/Buenos_Aires",
        "America~Argentina~Catamarca": "America/Argentina/Catamarca",
        "America~Argentina~Cordoba": "America/Argentina/Cordoba",
        "America~Argentina~Jujuy": "America/Argentina/Jujuy",
        "America~Argentina~La_Rioja": "America/Argentina/La_Rioja",
        "America~Argentina~Mendoza": "America/Argentina/Mendoza",
        "America~Argentina~Rio_Gallegos": "America/Argentina/Rio_Gallegos",
        "America~Argentina~Salta": "America/Argentina/Salta",
        "America~Argentina~San_Juan": "America/Argentina/San_Juan",
        "America~Argentina~San_Luis": "America/Argentina/San_Luis",
        "America~Argentina~Tucuman": "America/Argentina/Tucuman",
        "America~Argentina~Ushuaia": "America/Argentina/Ushuaia"
    },
    'AM': {
        "Asia~Yerevan": "Asia/Yerevan"
    },
    'AW': {
        "America~Aruba": "America/Aruba"
    },
    'AT': {
        "Europe~Vienna": "Europe/Vienna"
    },
    'AZ': {
        "Asia~Baku": "Asia/Baku"
    },
    'BS': {
        "America~Nassau": "America/Nassau"
    },
    'BH': {
        "Asia~Bahrain": "Asia/Bahrain"
    },
    'BD': {
        "Asia~Dhaka": "Asia/Dhaka"
    },
    'BB': {
        "America~Barbados": "America/Barbados"
    },
    'BY': {
        "Europe~Minsk": "Europe/Minsk"
    },
    'BE': {
        "Europe~Brussels": "Europe/Brussels"
    },
    'BZ': {
        "America~Belize": "America/Belize"
    },
    'BJ': {
        "Africa~Porto-Novo": "Africa/Porto-Novo"
    },
    'BM': {
        "Atlantic~Bermuda": "Atlantic/Bermuda"
    },
    'BT': {
        "Asia~Thimphu": "Asia/Thimphu"
    },
    'BO': {
        "America~La_Paz": "America/La_Paz"
    },
    'BQ': {
        "America~Kralendijk": "America/Kralendijk"
    },
    'BA': {
        "Europe~Sarajevo": "Europe/Sarajevo"
    },
    'BW': {
        "Africa~Gaborone": "Africa/Gaborone"
    },
    'BR': {
        "America~Araguaina": "America/Araguaina",
        "America~Bahia": "America/Bahia",
        "America~Belem": "America/Belem",
        "America~Boa_Vista": "America/Boa_Vista",
        "America~Campo_Grande": "America/Campo_Grande",
        "America~Cuiaba": "America/Cuiaba",
        "America~Eirunepe": "America/Eirunepe",
        "America~Fortaleza": "America/Fortaleza",
        "America~Maceio": "America/Maceio",
        "America~Manaus": "America/Manaus",
        "America~Noronha": "America/Noronha",
        "America~Porto_Velho": "America/Porto_Velho",
        "America~Recife": "America/Recife",
        "America~Rio_Branco": "America/Rio_Branco",
        "America~Santarem": "America/Santarem",
        "America~Sao_Paulo": "America/Sao_Paulo"
    },
    'IO': {
        "Indian~Chagos": "Indian/Chagos"
    },
    'BN': {
        "Asia~Brunei": "Asia/Brunei"
    },
    'BG': {
        "Europe~Sofia": "Europe/Sofia"
    },
    'BF': {
        "Africa~Ouagadougou": "Africa/Ouagadougou"
    },
    'BI': {
        "Africa~Bujumbura": "Africa/Bujumbura"
    },
    'KH': {
        "Asia~Phnom_Penh": "Asia/Phnom_Penh"
    },
    'CM': {
        "Africa~Douala": "Africa/Douala"
    },
    'CV': {
        "Atlantic~Cape_Verde": "Atlantic/Cape_Verde"
    },
    'KY': {
        "America~Cayman": "America/Cayman"
    },
    'CF': {
        "Africa~Bangui": "Africa/Bangui"
    },
    'TD': {
        "Africa~Ndjamena": "Africa/Ndjamena"
    },
    'CL': {
        "America~Santiago": "America/Santiago",
        "Pacific~Easter": "Pacific/Easter"
    },
    'CN': {
        "Asia~Chongqing": "Asia/Chongqing",
        "Asia~Harbin": "Asia/Harbin",
        "Asia~Kashgar": "Asia/Kashgar",
        "Asia~Shanghai": "Asia/Shanghai",
        "Asia~Urumqi": "Asia/Urumqi"
    },
    'CX': {
        "Indian~Christmas": "Indian/Christmas"
    },
    'CC': {
        "Indian~Cocos": "Indian/Cocos"
    },
    'CO': {
        "America~Bogota": "America/Bogota"
    },
    'KM': {
        "Indian~Comoro": "Indian/Comoro"
    },
    'CG': {
        "Africa~Brazzaville": "Africa/Brazzaville"
    },
    'CD': {
        "Africa~Kinshasa": "Africa/Kinshasa",
        "Africa~Lubumbashi": "Africa/Lubumbashi"
    },
    'CK': {
        "Pacific~Rarotonga": "Pacific/Rarotonga"
    },
    'CR': {
        "America~Costa_Rica": "America/Costa_Rica"
    },
    'CI': {

        "Africa~Abidjan": "Africa/Abidjan"
    },
    'HR': {
        "Europe~Zagreb": "Europe/Zagreb"
    },
    'CU': {
        "America~Havana": "America/Havana"
    },
    'CW': {
        "America~Curacao": "America/Curacao"
    },
    'CY': {
        "Asia~Nicosia": "Asia/Nicosia"
    },
    'CZ': {
        "Europe~Prague": "Europe/Prague"
    },
    'DK': {
        "Europe~Copenhagen": "Europe/Copenhagen"
    },
    'DJ': {
        "Africa~Djibouti": "Africa/Djibouti"
    },
    'DM': {
        "America~Dominica": "America/Dominica"
    },
    'DO': {
        "America~Santo_Domingo": "America/Santo_Domingo"
    },
    'EC': {
        "America~Guayaquil": "America/Guayaquil",
        "Pacific~Galapagos": "Pacific/Galapagos"
    },
    'EG': {
        "Africa~Cairo": "Africa/Cairo"
    },
    'SV': {
        "America~El_Salvador": "America/El_Salvador"
    },
    'GQ': {
        "Africa~Malabo": "Africa/Malabo"
    },
    'ER': {
        "Africa~Asmara": "Africa/Asmara"
    },
    'EE': {
        "Europe~Tallinn": "Europe/Tallinn"
    },
    'ET': {
        "Africa~Addis_Ababa": "Africa/Addis_Ababa"
    },
    'FK': {
        "Atlantic~Stanley": "Atlantic/Stanley"
    },
    'FO': {
        "Atlantic~Faroe": "Atlantic/Faroe"
    },
    'FJ': {
        "Pacific~Fiji": "Pacific/Fiji"
    },
    'FI': {
        "Europe~Helsinki": "Europe/Helsinki"
    },
    'FR': {
        "Europe~Paris": "Europe/Paris"
    },
    'GF': {
        "America~Cayenne": "America/Cayenne"
    },
    'PF': {
        "Pacific~Gambier": "Pacific/Gambier",
        "Pacific~Marquesas": "Pacific/Marquesas",
        "Pacific~Tahiti": "Pacific/Tahiti"
    },
    'TF': {
        "Indian~Kerguelen": "Indian/Kerguelen"
    },
    'GA': {
        "Africa~Libreville": "Africa/Libreville"
    },
    'GM': {
        "Africa~Banjul": "Africa/Banjul"
    },
    'GE': {
        "Asia~Tbilisi": "Asia/Tbilisi"
    },
    'DE': {
        "Europe~Berlin": "Europe/Berlin",
        "Europe~Busingen": "Europe/Busingen"
    },
    'GH': {
        "Africa~Accra": "Africa/Accra"
    },
    'GI': {
        "Europe~Gibraltar": "Europe/Gibraltar"
    },
    'GR': {
        "Europe~Athens": "Europe/Athens"
    },
    'GL': {
        "America~Danmarkshavn": "America/Danmarkshavn",
        "America~Godthab": "America/Godthab",
        "America~Scoresbysund": "America/Scoresbysund",
        "America~Thule": "America/Thule"
    },
    'GD': {
        "America~Grenada": "America/Grenada"
    },
    'GP': {
        "America~Guadeloupe": "America/Guadeloupe"
    },
    'GU': {
        "Pacific~Guam": "Pacific/Guam"
    },
    'GT': {
        "America~Guatemala": "America/Guatemala"
    },
    'GG': {
        "Europe~Guernsey": "Europe/Guernsey"
    },
    'GN': {
        "Africa~Conakry": "Africa/Conakry"
    },
    'GY': {
        "America~Guyana": "America/Guyana"
    },
    'HT': {
        "America~Port-au-Prince": "America/Port-au-Prince"
    },
    'VA': {
        "Europe~Vatican": "Europe/Vatican"
    },
    'HN': {
        "America~Tegucigalpa": "America/Tegucigalpa"
    },
    'HK': {
        "Asia~Hong_Kong": "Asia/Hong_Kong"
    },
    'HU': {
        "Europe~Budapest": "Europe/Budapest"
    },
    'IS': {
        "Atlantic~Reykjavik": "Atlantic/Reykjavik"
    },
    'IN': {
        "Asia~Kolkata": "Asia/Kolkata"
    },
    'ID': {
        "Asia~Jakarta": "Asia/Jakarta",
        "Asia~Jayapura": "Asia/Jayapura",
        "Asia~Makassar": "Asia/Makassar",
        "Asia~Pontianak": "Asia/Pontianak"
    },
    'IR': {
        "Asia~Tehran": "Asia/Tehran"
    },
    'IQ': {
        "Asia~Baghdad": "Asia/Baghdad"
    },
    'IE': {
        "Europe~Dublin": "Europe/Dublin"
    },
    'IM': {
        "Europe~Isle_of_Man": "Europe/Isle_of_Man"
    },
    'IL': {
        "Asia~Jerusalem": "Asia/Jerusalem"
    },
    'IT': {
        "Europe~Rome": "Europe/Rome"
    },
    'JM': {
        "America~Jamaica": "America/Jamaica"
    },
    'JP': {
        "Asia~Tokyo": "Asia/Tokyo"
    },
    'JE': {
        "Europe~Jersey": "Europe/Jersey"
    },
    'JO': {
        "Asia~Amman": "Asia/Amman"
    },
    'KZ': {
        "Asia~Almaty": "Asia/Almaty",
        "Asia~Aqtau": "Asia/Aqtau",
        "Asia~Aqtobe": "Asia/Aqtobe",
        "Asia~Oral": "Asia/Oral",
        "Asia~Qyzylorda": "Asia/Qyzylorda"
    },
    'KE': {
        "Africa~Nairobi": "Africa/Nairobi"
    },
    'KI': {
        "Pacific~Enderbury": "Pacific/Enderbury",
        "Pacific~Kiritimati": "Pacific/Kiritimati",
        "Pacific~Tarawa": "Pacific/Tarawa"
    },
    'KP': {
        "Asia~Pyongyang": "Asia/Pyongyang"
    },
    'KR': {
        "Asia~Seoul": "Asia/Seoul"
    },
    'KW': {
        "Asia~Kuwait": "Asia/Kuwait"
    },
    'KG': {
        "Asia~Bishkek": "Asia/Bishkek"
    },
    'LA': {
        "Asia~Vientiane": "Asia/Vientiane"
    },
    'LV': {
        "Europe~Riga": "Europe/Riga"
    },
    'LB': {
        "Asia~Beirut": "Asia/Beirut"
    },
    'LS': {
        "Africa~Maseru": "Africa/Maseru"
    },
    'LR': {
        "Africa~Monrovia": "Africa/Monrovia"
    },
    'LY': {
        "Africa~Tripoli": "Africa/Tripoli"
    },
    'LI': {
        "Europe~Vaduz": "Europe/Vaduz"
    },
    'LT': {
        "Europe~Vilnius": "Europe/Vilnius"
    },
    'LU': {
        "Europe~Luxembourg": "Europe/Luxembourg"
    },
    'MO': {
        "Asia~Macau": "Asia/Macau"
    },
    'MK': {
        "Europe~Skopje": "Europe/Skopje"
    },
    'MG': {
        "Indian~Antananarivo": "Indian/Antananarivo"
    },
    'MW': {
        "Africa~Blantyre": "Africa/Blantyre"
    },
    'MY': {
        "Asia~Kuala_Lumpur": "Asia/Kuala_Lumpur",
        "Asia~Kuching": "Asia/Kuching"
    },
    'MV': {
        "Indian~Maldives": "Indian/Maldives"
    },
    'ML': {
        "Africa~Bamako": "Africa/Bamako"
    },
    'MT': {
        "Europe~Malta": "Europe/Malta"
    },
    'MH': {
        "Pacific~Kwajalein": "Pacific/Kwajalein",
        "Pacific~Majuro": "Pacific/Majuro"
    },
    'MQ': {
        "America~Martinique": "America/Martinique"
    },
    'MR': {
        "Africa~Nouakchott": "Africa/Nouakchott"
    },
    'MU': {
        "Indian~Mauritius": "Indian/Mauritius"
    },
    'YT': {
        "Indian~Mayotte": "Indian/Mayotte"
    },
    'MX': {
        "America~Bahia_Banderas": "America/Bahia_Banderas",
        "America~Cancun": "America/Cancun",
        "America~Chihuahua": "America/Chihuahua",
        "America~Hermosillo": "America/Hermosillo",
        "America~Matamoros": "America/Matamoros",
        "America~Mazatlan": "America/Mazatlan",
        "America~Merida": "America/Merida",
        "America~Mexico_City": "America/Mexico_City",
        "America~Monterrey": "America/Monterrey",
        "America~Ojinaga": "America/Ojinaga",
        "America~Santa_Isabel": "America/Santa_Isabel",
        "America~Tijuana": "America/Tijuana"
    },
    'FM': {
        "Pacific~Chuuk": "Pacific/Chuuk",
        "Pacific~Kosrae": "Pacific/Kosrae",
        "Pacific~Pohnpei": "Pacific/Pohnpei"
    },
    'MD': {
        "Europe~Chisinau": "Europe/Chisinau"
    },
    'MC': {
        "Europe~Monaco": "Europe/Monaco"
    },
    'MN': {
        "Asia~Choibalsan": "Asia/Choibalsan",
        "Asia~Hovd": "Asia/Hovd",
        "Asia~Ulaanbaatar": "Asia/Ulaanbaatar"
    },
    'ME': {
        "Europe~Podgorica": "Europe/Podgorica"
    },
    'MS': {
        "America~Montserrat": "America/Montserrat"
    },
    'MA': {
        "Africa~Casablanca": "Africa/Casablanca"
    },
    'MZ': {
        "Africa~Maputo": "Africa/Maputo"
    },
    'MM': {
        "Asia~Rangoon": "Asia/Rangoon"
    },
    'NA': {
        "Africa~Windhoek": "Africa/Windhoek"
    },
    'NR': {
        "Pacific~Nauru": "Pacific/Nauru"
    },
    'NP': {
        "Asia~Kathmandu": "Asia/Kathmandu"
    },
    'NL': {
        "Europe~Amsterdam": "Europe/Amsterdam"
    },
    'NC': {
        "Pacific~Noumea": "Pacific/Noumea"
    },
    'NZ': {
        "Pacific~Auckland": "Pacific/Auckland",
        "Pacific~Chatham": "Pacific/Chatham"
    },
    'NI': {
        "America~Managua": "America/Managua"
    },
    'NE': {
        "Africa~Niamey": "Africa/Niamey"
    },
    'NG': {
        "Africa~Lagos": "Africa/Lagos"
    },
    'NU': {
        "Pacific~Niue": "Pacific/Niue"
    },
    'NF': {
        "Pacific~Norfolk": "Pacific/Norfolk"
    },
    'MP': {
        "Pacific~Saipan": "Pacific/Saipan"
    },
    'NO': {
        "Europe~Oslo": "Europe/Oslo"
    },
    'OM': {
        "Asia~Muscat": "Asia/Muscat"
    },
    'PK': {
        "Asia~Karachi": "Asia/Karachi"
    },
    'PW': {
        "Pacific~Palau": "Pacific/Palau"
    },
    'PS': {
        "Asia~Gaza": "Asia/Gaza",
        "Asia~Hebron": "Asia/Hebron"
    },
    'PA': {
        "America~Panama": "America/Panama"
    },
    'PG': {
        "Pacific~Port_Moresby": "Pacific/Port_Moresby"
    },
    'PY': {
        "America~Asuncion": "America/Asuncion"
    },
    'PE': {
        "America~Lima": "America/Lima"
    },
    'PH': {
        "Asia~Manila": "Asia/Manila"
    },
    'PN': {
        "Pacific~Pitcairn": "Pacific/Pitcairn"
    },
    'PL': {
        "Europe~Warsaw": "Europe/Warsaw"
    },

    'PT': {
        "Atlantic~Azoresw": "Atlantic/Azores",
        "Atlantic~Madeira": "Atlantic/Madeira",
        "Europe~Lisbon": "Europe/Lisbon"
    },
    'PR': {
        "America~Puerto_Rico": "America/Puerto_Rico"
    },
    'QA': {
        "Asia~Qatar": "Asia/Qatar"
    },
    'RE': {
        "Indian~Reunion": "Indian/Reunion"
    },
    'RO': {
        "Europe~Bucharest": "Europe/Bucharest"
    },
    'RU': {
        "Asia~Anadyr": "Asia/Anadyr",
        "Asia~Irkutsk": "Asia/Irkutsk",
        "Asia~Kamchatka": "Asia/Kamchatka",
        "Asia~Khandyga": "Asia/Khandyga",
        "Asia~Krasnoyarsk": "Asia/Krasnoyarsk",
        "Asia~Magadan": "Asia/Magadan",
        "Asia~Novokuznetsk": "Asia/Novokuznetsk",
        "Asia~Novosibirsk": "Asia/Novosibirsk",
        "Asia~Sakhalin": "Asia/Sakhalin",
        "Asia~Ust-Nera": "Asia/Ust-Nera",
        "Asia~Vladivostok": "Asia/Vladivostok",
        "Asia~Yakutsk": "Asia/Yakutsk",
        "Asia~Yekaterinburg": "Asia/Yekaterinburg",
        "Europe~Kaliningrad": "Europe/Kaliningrad",
        "Europe~Moscow": "Europe/Moscow",
        "Europe~Samara": "Europe/Samara",
        "Europe~Simferopol": "Europe/Simferopol",
        "Europe~Volgograd": "Europe/Volgograd"
    },
    'RW': {
        "Africa~Kigali": "Africa/Kigali"
    },
    'BL': {
        "America~St_Barthelemy": "America/St_Barthelemy"
    },
    'SH': {
        "Atlantic~St_Helena": "Atlantic/St_Helena"
    },
    'KN': {
        "America~St_Kitts": "America/St_Kitts"
    },
    'LC': {
        "America~St_Lucia": "America/St_Lucia"
    },
    'MF': {
        "America~Marigot": "America/Marigot"
    },
    'PM': {
        "America~Miquelon": "America/Miquelon"
    },
    'VC': {
        "America~St_Vincent": "America/St_VincentAmerica"
    },
    'WS': {
        "Pacific~Apia": "Pacific/Apia"
    },
    'SM': {
        "Europe~San_Marino": "Europe/San_Marino"
    },
    'ST': {
        "Africa~Sao_Tome": "Africa/Sao_Tome"
    },
    'SA': {
        "Asia~Riyadh": "Asia/Riyadh"
    },
    'SN': {
        "Africa~DakarAsia": "Africa/Dakar"
    },
    'RS': {
        "Europe~Belgrade": "Europe/Belgrade"
    },
    'SC': {
        "Indian~Mahe": "Indian/Mahe"
    },
    'SL': {
        "Africa~Freetown": "Africa/Freetown"
    },
    'SG': {
        "Asia~Singapore": "Asia/Singapore"
    },
    'SX': {
        "America~Lower_Princes": "America/Lower_Princes"
    },
    'SK': {
        "Europe~Bratislava": "Europe/Bratislava"
    },
    'SI': {
        "Europe~Ljubljanaa": "Europe/Ljubljana"
    },
    'SB': {
        "Pacific~Guadalcanal": "Pacific/Guadalcanal"
    },
    'SO': {
        "Africa~Mogadishu": "Africa/Mogadishu"
    },
    'ZA': {
        "Africa~Johannesburg": "Africa/Johannesburg"
    },
    'GS': {
        "Atlantic~South_Georgia": "Atlantic/South_Georgia"
    },
    'SS': {
        "Africa~Juba": "Africa/Juba"
    },
    'ES': {
        "Africa~Ceuta": "Africa/Ceuta",
        "Atlantic~Canary": "Atlantic/Canary",
        "Europe~Madrid": "Europe/Madrid"
    },
    'LK': {
        "Asia~Colombo": "Asia/Colombo"
    },
    'SD': {
        "Africa~Khartoum": "Africa/Khartoum"
    },
    'SR': {
        "America~Paramaribo": "America/Paramaribo"
    },
    'SJ': {
        "Arctic~Longyearbyen": "Arctic/Longyearbyen"
    },
    'SZ': {
        "Africa~Mbabane": "Africa/Mbabane"
    },
    'SE': {
        "Europe~Stockholm": "Europe/Stockholm"
    },
    'CH': {
        "Europe~Zurich": "Europe/Zurich"
    },
    'SY': {
        "Asia~Damascus": "Asia/Damascus"
    },
    'TW': {
        "Asia~Taipei": "Asia/Taipei"
    },
    'TJ': {
        "Asia~Dushanbe": "Asia/Dushanbe"
    },
    'TZ': {
        "Africa~Dar_es_Salaam": "Africa/Dar_es_Salaam"
    },
    'TH': {
        "Asia~Bangkok": "Asia/Bangkok"
    },
    'TL': {
        "Asia~Dili": "Asia/Dili"
    },
    'TG': {
        "Africa~Lome": "Africa/Lome"
    },
    'TK': {
        "Pacific~Fakaofo": "Pacific/Fakaofo"
    },
    'TO': {
        "Pacific~Tongatapu": "Pacific/Tongatapu"
    },
    'TT': {
        "America~Port_of_Spain": "America/Port_of_Spain"
    },
    'TN': {
        "Africa~Tunis": "Africa/Tunis"
    },
    'TR': {
        "Europe~Istanbul": "Europe/Istanbul"
    },
    'TM': {
        "Asia~Ashgabat": "Asia/Ashgabat"
    },
    'TC': {
        "America~Grand_Turk": "America/Grand_Turk"
    },
    'TV': {
        "Pacific~Funafuti": "Pacific/Funafuti"
    },
    'UG': {
        "Africa~Kampala": "Africa/Kampala"
    },
    'UA': {
        "Europe~Kiev": "Europe/Kiev",
        "Europe~Uzhgorod": "Europe/Uzhgorod",
        "Europe~Zaporozhye": "Europe/Zaporozhye"
    },
    'AE': {
        "Asia~Dubai": "Asia/Dubai"
    },
    'GB': {
        "Europe~London": "Europe/London"
    },
    'UM': {
        "Pacific~Johnston": "Pacific/Johnston",
        "Pacific~Midway": "Pacific/Midway",
        "Pacific~Wake": "Pacific/Wake"
    },
    'UY': {
        "America~Montevideo": "America/Montevideo"
    },
    'UZ': {
        "Asia~Samarkand": "Asia/Samarkand",
        "Asia~Tashkent": "Asia/Tashkent"
    },
    'VU': {
        "Pacific~Efate": "Pacific/Efate"
    },
    'VE': {
        "America~Caracas": "America/Caracas"
    },
    'VN': {
        "Asia~Ho_Chi_Minh": "Asia/Ho_Chi_Minh"
    },
    'VG': {
        "America~Tortola": "America/Tortola"
    },
    'VI': {
        "America~St_Thomas": "America/St_Thomas"
    },
    'WF': {
        "Pacific~Wallis": "Pacific/Wallis"
    },
    'EH': {
        "Africa~El_Aaiun": "Africa/El_Aaiun"
    },
    'YE': {
        "Asia~Aden": "Asia/Aden"
    },
    'ZM': {
        "Africa~Lusaka": "Africa/Lusaka"
    },
    'ZW': {
        "Africa~Harare": "Africa/Harare"
    }
}

